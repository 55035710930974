/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.container {
  flex-grow: 1;
  padding-top: var(--10px);
}

.details {
  display: flex;
  align-items: center;
  padding: var(--20px);
}

.endpoints {
  width: 90%;
}

.icon {
  font-size: 1.5em;
}

.toolBar {
  --gap-row: var(--gap-row-xsmall);

  display: flex;
  z-index: 110; /* must be higher than the Grid z-indices */
  align-items: flex-start;
  padding: 0 var(--8px) var(--10px);
  box-shadow: var(--shadow-depth-2onlyDown);
}
