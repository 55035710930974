/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-radius: 50%;
  transform: translateZ(0);
  animation: rotate 1.1s infinite linear;
}

/* Positions */
.normal {
  margin: auto;
}

.before {
  margin-right: var(--5px);
}

.after {
  margin-left: var(--5px);
}

.inbetween {
  composes: before after;
}

.center {
  display: flex;
  margin: 0 auto;
}

/* Sizes */
.small {
  width: var(--13px);
  height: var(--13px);
  margin-top: var(--1px);
  vertical-align: text-top;
  border-width: var(--2px);
  animation-duration: 0.8s;
}

.medium {
  width: var(--16px);
  height: var(--16px);
  vertical-align: text-bottom;
  border-width: var(--3px);
  animation-duration: 0.8s;
}

.large {
  width: var(--17px);
  height: var(--17px);
  vertical-align: text-bottom;
  border-width: var(--3px);
  animation-duration: 0.8s;
}

.twenty {
  width: var(--20px);
  height: var(--20px);
  border-width: var(--4px);
}

.xlarge {
  width: var(--23px);
  height: var(--23px);
  margin-top: calc(-1 * var(--4px));
  border-width: var(--4px);
  animation-duration: 0.9s;
}

.xxlarge {
  width: var(--24px);
  height: var(--24px);
  margin-top: var(--3px);
  vertical-align: text-bottom;
  border-width: var(--4px);
  animation-duration: 1s;
}

.fifty {
  width: var(--50px);
  height: var(--50px);
  border-width: var(--8px);
}

.huge {
  width: var(--64px);
  height: var(--64px);
  margin-top: var(--9px);
  border-width: var(--8px);
  animation-duration: 1s;
}

/* Colors */
.dark {
  :where(html[data-theme='lightning']) & {
    border-color: rgb(var(--lightning--blue-gray-300-rgb) / 0.2) rgb(var(--lightning--blue-gray-300-rgb) / 0.2)
      rgb(var(--lightning--blue-gray-300-rgb) / 0.2) var(--lightning--blue-900);
  }
}

.light {
  :where(html[data-theme='lightning']) & {
    border-color: rgb(var(--lightning--white-rgb) / 0.2) rgb(var(--lightning--white-rgb) / 0.2)
      rgb(var(--lightning--white-rgb) / 0.2) var(--lightning--white);
  }
}
