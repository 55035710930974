/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.notifyUserLink {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-700);
  }
}

.DoNotShowMessageCheckbox {
  margin-left: var(--8px);
}
