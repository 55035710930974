/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.section {
  --typed-message-icon-padding: var(--12px);
  --typed-message-color: inherit;

  display: flex;
  align-items: flex-start;
}

.icon {
  flex: 0 0 auto;
  padding-right: var(--typed-message-icon-padding);
  font-size: var(--20px);
  color: var(--typed-message-color);
}

.offsetIconPadding {
  padding-left: calc(1em + var(--10px));
}

.message {
  flex: 1 1 auto;
  padding-block-start: var(--4px);
  font-weight: var(--fontWeightSemiBold);
  color: var(--typed-message-color);
  overflow-wrap: break-word;
}

.info {
  :where(html[data-theme='lightning']) & {
    --typed-message-color: var(--lightning--blue-700);
  }
}

.success {
  :where(html[data-theme='lightning']) & {
    --typed-message-color: var(--lightning--green-600);
  }
}

.warning {
  :where(html[data-theme='lightning']) & {
    --typed-message-color: var(--lightning--orange-500);
  }
}

.error {
  :where(html[data-theme='lightning']) & {
    --typed-message-color: var(--lightning--red-500);
  }
}

.gray {
  :where(html[data-theme='lightning']) & {
    --typed-message-color: var(--lightning--gray-600);
  }
}
