/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.layout {
  display: flex;
  flex-direction: row;
}

.menu {
  display: flex;
  flex-direction: column;
  width: 290px;
  min-width: 290px;
  margin-top: var(--5px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-300);
  }
}

.section {
  padding: var(--10px);
  font-weight: var(--fontWeightLight);
}

.apiName {
  margin-left: var(--10px);
  padding: var(--5px) var(--15px);
  font-weight: var(--fontWeightBold);

  &:hover {
    cursor: pointer;

    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--blue-gray-200);
    }
  }

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
  }
}

.selected {
  margin-left: var(--10px);
  padding: var(--5px) var(--10px);
  font-weight: var(--fontWeightBold);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-800);
    border-left: 5px solid var(--lightning--blue-800);
    background-color: var(--lightning--blue-gray-50);
  }
}

.response {
  flex-grow: 1;

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--gray-50);
  }
}

.responseHeader {
  padding: var(--10px);
  font-size: 1.2em;

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--gray-400);
  }
}

.responseBody {
  margin: var(--15px);
}

.searchBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--10px) var(--20px) 0 var(--10px);
}
