/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.online-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-900);
  }
}

.offline-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-200);
  }
}

.invited-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-900);
  }
}

.locked-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-800);
  }
}

.activityDetails-list {
  margin-bottom: var(--40px);
}

.userActivityStatus {
  padding-left: var(--7px);
}
