/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.container {
  display: grid;
  flex-grow: 1;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-width: 100%;
  min-height: 100%;

  > div {
    grid-area: 1 / 1;
  }
}

.loadingBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
