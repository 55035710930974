/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.vCenter {
  justify-content: center;
}

/* Highlight removed rows with red */
.rowToRemove .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--red-25);
  }
}

/* Highlight reverted rows with yellow */
.rowToRevert .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--orange-50);
  }
}

/* Highlight provisioned/apply policy rows with blue-gray */
.rowToProvision .areaBody,
.rowToApplyPolicy .areaBody,
.rowToEnable .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-50);

    .status-updated {
      color: var(--lightning--blue-800);
      background-color: var(--lightning--blue-100);
    }
  }
}

/* Highlight generating rows with blue-gray */
.rowToGenerate .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-100);
  }
}

/* Highlight selected rows with blue-gray */
.rowsSelected .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-200);
  }
}

/* Highlight modified rows with green */
.rowsModified .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--green-50);
  }
}

/* Highlight selected rows with blue-gray */
.rowToDownload .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-100);
  }
}

.rowBodyNotification .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--yellow-50);
  }
}

.rowBodyRemoveNotification .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--red-50);
  }
}

.addRows .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--green-25);
  }
}

.removeRows .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--red-100);
  }
}

.modifiedRows .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-50);
  }
}

.rowStatus-icon {
  padding-right: var(--2px);
  padding-left: var(--2px);
}

.status-badge {
  padding: var(--4px) var(--8px);
  line-height: 1.2;
  text-transform: none;
  white-space: nowrap;
}

.status-created {
  :where(html[data-theme='lightning']) & {
    background: var(--lightning--green-50);
  }
}

.status-updated {
  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-50);
  }
}

.status-deleted {
  :where(html[data-theme='lightning']) & {
    background: var(--lightning--red-50);
  }
}

.table-minWidth_800 {
  .status-badge {
    margin: var(--4px) 0;
  }

  .areaBody .cell.status {
    padding-top: var(--8px);
    padding-bottom: 0;
  }
}

/* Highlight disabled rows with gray */
.disabledRulesets .areaBody {
  /* keep full opacity on checkbox, status, and scope columns */
  /* stylelint-disable selector-max-combinators, selector-max-compound-selectors */
  &:not(:nth-child(2), :has(.state), :has(.scope)) .cell {
    opacity: 0.6;
  }
  /* handle scope column separately to not affect contextual menu */
  /* stylelint-disable selector-max-universal */
  .cell.scope > * > *:not(.rulesetListGrid-dropdown) {
    opacity: 0.6;
  }

  :where(html[data-theme='lightning']) & {
    /* temp solution to prevent focused state from taking over background-color */
    /* stylelint-disable-next-line declaration-no-important */
    background-color: var(--lightning--blue-gray-200) !important;
  }
}
