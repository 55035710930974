/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.container {
  composes: centerMarginBlock gapLarge from 'utils.css';
  margin-top: var(--32px);
}

.title {
  --ven-count-by-status-title-font-size: var(--14px);

  composes: bold from 'utils.css';
  font-size: var(--ven-count-by-status-title-font-size, var(--18px));
  font-weight: var(--fontWeightBold);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-900);
  }
}

.status {
  padding-top: var(--20px);
}
