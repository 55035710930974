/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_1152 .areaHead .cell {
  white-space: nowrap;
}

.table-minWidth_960 .areaHead .cell {
  &.download,
  &.regenerate {
    text-align: center;
  }
}

.dropdown {
  height: calc(26vh); /* currently 26vh matches the dropdown data sets */
}
