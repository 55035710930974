/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
:root {
  --splitview-divider-background-color: transparent;
  --splitview-divider-width: var(--1px);
  --splitview-divider-hitbox-width: var(--20px);
  --splitview-divider-shadow-left: var(--shadow-depth-3onlyLeft);
  --splitview-divider-shadow-right: var(--shadow-depth-3onlyRight);
}

.container {
  position: relative;
  display: flex;
  flex-grow: 1;
}

.splitEnabled {
  width: 100%;
  height: 100%;
}

.resizing {
  cursor: col-resize;
}

.pane {
  display: flex;
}

.fixedPane {
  flex-grow: 0;
}

.fluidPane {
  flex-grow: 1;
}

.divider {
  display: flex;
  z-index: 1;
  align-self: stretch;
  width: var(--splitview-divider-width);
  margin-right: calc(-1 * var(--splitview-divider-width));
  box-shadow: var(--splitview-divider-shadow-left);
  background-color: var(--splitview-divider-background-color);

  &.dropShadowOrientationRight {
    margin-right: 0;
    margin-left: calc(-1 * var(--splitview-divider-width));
    box-shadow: var(--splitview-divider-shadow-right);
  }
}

.dividerHitBox {
  align-self: stretch;
  width: var(--splitview-divider-hitbox-width);
  margin-left: calc(-1 * var(--splitview-divider-hitbox-width) / 2);
  cursor: col-resize;
}
