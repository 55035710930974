/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.sizeWatcher {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.rightJustifyContent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
