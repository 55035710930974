/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.test {
  color: red;
}

.policyField {
  display: flex;
  align-items: center;
}

.radioPolicyField {
  composes: policyField;

  .policyDescription {
    font-size: var(--fontSize);
  }
}

.policyValue {
  composes: bold from 'utils.css';
  min-width: var(--35px);
  margin-right: var(--10px);
  font-size: var(--16px);
  line-height: calc(var(--14px) * 1.4);
}

.policyDescription {
  padding-left: var(--10px);
}

.descriptionRequired {
  composes: policyDescription;
  font-style: italic;
}

.shortField {
  width: var(--80px);
  font-size: var(--fontSize);
}

.radioGroup {
  display: flex;
  align-items: flex-start;
}

.validHint {
  padding-top: var(--5px);
  padding-bottom: var(--10px);
  font-size: var(--fontSize);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-500);
  }
}

.errorMessage {
  padding-top: var(--5px);
  padding-bottom: var(--10px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-700);
  }
}

.complexityCategoryCheckboxGroup {
  max-width: 175px;
}
