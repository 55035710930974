/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.removedText {
  text-decoration: line-through;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-300);
  }
}

.addedText {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-500);
  }
}

.modalGap {
  composes: gapMedium from 'utils.css';
}
