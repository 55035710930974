/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.selector {
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  align-items: center;
  min-height: var(--35px);
  margin-left: var(--10px);
  padding: var(--2px);
  overflow: hidden;

  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--gray-100);
    background-color: var(--lightning--white);
  }
}

.focused {
  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--blue-600);
  }
}

.item {
  margin: var(--2px);
}

.actions {
  composes: gapSmall gapHorizontal from 'utils.css';
  justify-content: flex-end;
  padding: var(--10px);
}

.customPicker {
  width: calc(3px + var(--dt-picker-width));

  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--gray-300);
    background: var(--lightning--white);
  }

  &.noBorder {
    border: none;
  }
}

.errorMessage {
  padding-bottom: var(--10px);
  padding-left: var(--60px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-600);
  }
}

.errorText {
  padding-left: var(--10px);
}

.inputHolder {
  display: inline-flex;
  align-items: center;
  width: 300px;
  padding: var(--12px);
}

.inputLabel {
  min-width: var(--35px);
  text-align: right;
}

.input {
  max-width: 100%; /* If placeholder is longer than input's parent */
  height: var(--24px);
  margin: var(--2px) var(--2px) var(--2px) var(--5px);
  outline: none;
  border: none;
}
