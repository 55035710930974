/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.displayFlex {
  display: flex;
}

.generateButton {
  display: inline-block;
  padding-left: var(--10px);
}
