/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.text {
  padding: var(--5px) var(--6px);
  line-height: var(--20px);
  word-break: break-all;
}

.command {
  display: flex;
  justify-content: space-between;

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-50);
  }
}

.customContainer {
  display: flex;
  align-items: flex-start;
}

.time {
  width: calc(1.2 * var(--100px));
  padding-right: var(--20px);
}

.unit {
  width: calc(1.75 * var(--100px));
  padding-right: var(--20px);
}

.custom {
  width: calc(2 * var(--100px));
  padding-right: var(--20px);
}
