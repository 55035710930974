/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.container {
  composes: centerMarginBlock from 'utils.css';
}

.labels {
  composes: gap from 'utils.css';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: var(--offset-xsmall);
}

.progress {
  display: flex;
  width: 100%;
  height: var(--4px);
  overflow: hidden;
  border-radius: var(--offset-xsmall);
}

.red {
  :where(html[data-theme='lightning']) & {
    background-color: rgb(var(--lightning--red-200-rgb) / 0.5);
  }
}

.orange {
  :where(html[data-theme='lightning']) & {
    background-color: rgb(var(--lightning--orange-200-rgb) / 0.5);
  }
}

.green {
  :where(html[data-theme='lightning']) & {
    background-color: rgb(var(--lightning--green-200-rgb) / 0.5);
  }
}

.blue {
  :where(html[data-theme='lightning']) & {
    background-color: rgb(var(--lightning--blue-200-rgb) / 0.5);
  }
}

.progressValue {
  border-radius: var(--offset-xsmall);
  background-color: var(--lightning--white);
}
