/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
.customContainer {
  display: flex;
  flex-direction: column;
  min-height: calc(1.2 * var(--100px));
  padding: var(--15px);
}

.userInputContainer {
  composes: gapLarge gapHorizontal centerFlexContent from 'utils.css';
}

.customBody {
  flex-grow: 1;
  padding-top: var(--5px);
}

.smallContainer {
  min-width: calc(1.4 * var(--100px));
}
