/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.content {
  font-size: var(--13px);
  font-weight: var(--fontWeight);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}

.scopeAll {
  padding: var(--optionSelector-vertical-padding) var(--5px);
}
