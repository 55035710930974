/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.content {
  font-size: var(--13px);
  font-weight: var(--fontWeight);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}

.copyButtonWrapper {
  display: flex;
  justify-content: space-between;

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-50);
  }
}

.copyButtonText {
  padding: var(--5px) var(--6px);
  line-height: var(--20px);
  word-break: break-all;
}

.ransomwareReadiness {
  display: flex;
  align-items: center;
}

.ransomwareExposureStatus {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: var(--6px);
  border-radius: 50%;
}

.fully_protected {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--green-400);
  }
}

.critical {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--orange-500);
  }
}

.high {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-800);
  }
}

.medium {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-500);
  }
}

.low {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-100);
  }
}
