/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
/* App .content */
.content {
  padding-bottom: 0;
}

.wrapper {
  margin-bottom: var(--offset-medium);
  padding-top: var(--offset-medium);
  padding-bottom: var(--offset-medium);

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--white, inherit);
  }
}

.newWidget-card {
  --card-padding: var(--offset-small);

  cursor: grab;

  & .newWidget-header .newWidget-title {
    font-size: 0.8em;
    font-weight: var(--fontWeight);
  }

  & .newWidget-body {
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  &:hover {
    :where(html[data-theme='lightning']) & {
      border-color: var(--lightning--blue-800);
    }
  }
}

.grid {
  :where(html[data-theme='lightning']) & {
    --active-color: var(--lightning--blue-600);
  }

  :global .react-grid-item {
    &.react-grid-placeholder {
      opacity: 1;
      border: var(--2px) dashed var(--active-color);

      :where(html[data-theme='lightning']) & {
        background: rgb(var(--lightning--blue-700-rgb) 20%);
      }
    }
    &.react-draggable-dragging {
      border: var(--2px) solid var(--active-color);
    }
  }

  & .item {
    position: relative;
    & .handler {
      --padding: var(--50px);

      position: absolute;
      top: 0;
      left: var(--padding);
      width: calc(100% - 2 * var(--padding));
      height: var(--15px);
      opacity: 0;
      cursor: grab;

      &::before {
        --width: var(--20px);

        position: absolute;
        display: block;
        content: ' ';
        top: var(--4px);
        left: calc(50% - var(--width) / 2);
        width: var(--width);
        height: var(--6px);
        border-top-width: var(--2px);
        border-bottom-width: var(--2px);

        :where(html[data-theme='lightning']) & {
          border: 0 solid var(--lightning--gray-600);
        }
      }

      &:hover {
        opacity: 1;
      }
    }

    &.noPointer {
      pointer-events: none;
    }

    :global(.react-draggable-dragging) .handler {
      opacity: 1;

      &::before {
        border-color: var(--active-color);
      }
    }
  }
}

.title {
  flex-grow: 1;
  font-size: var(--18px);
  font-weight: var(--fontWeightBold);
}

.type-selectorMain {
  width: calc(2 * var(--100px));
}

.range-selectorMain {
  width: calc(var(--100px) + var(--50px));
}

.icon {
  font-size: 1.5em;

  &:hover {
    cursor: help;
  }

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-700);
  }
}

.dashboardTooltip {
  display: inline-block;
  margin-bottom: var(--10px);
}
