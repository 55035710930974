/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.usageHeader {
  composes: cell from 'components/Grid/Grid.css';
  font-size: var(--12px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-700);
  }
}
