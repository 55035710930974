/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.menu {
  /* From Header standard width to calc arrow central position */
  --triggerWidth: var(--50px); /* For big screens >= 1440px */

  /* Tablets */
  @media (768px <= width <= 960px) {
    --triggerWidth: var(--37px);
  }

  /* Small screens */
  @media (961px <= width <= 1024px) {
    --triggerWidth: var(--40px);
  }

  /* Normal screens */
  @media (1025px <= width <= 1439px) {
    --triggerWidth: var(--45px);
  }
}

.provision {
  --header-itemWidth: var(--50px);

  position: relative;
  z-index: 1; /* To make counter overflow UserMenu trigger if counter is long */
  flex: 0 0 var(--header-itemWidth);

  /* Tablets */
  @media (768px <= width <= 960px) {
    --header-itemWidth: var(--37px);
  }

  /* Small screens */
  @media (961px <= width <= 1024px) {
    --header-itemWidth: var(--40px);
  }

  /* Normal screens */
  @media (1025px <= width <= 1439px) {
    --header-itemWidth: var(--45px);
  }

  /* Big screens */
  @media (width >= 1440px) {
    --header-itemWidth: var(--50px);
  }
}

.menu {
  display: block;
  height: 100%;
}

.trigger {
  font-size: var(--17px);
}

.counter {
  top: var(--3px);
  right: auto;
  left: calc(50% + var(--2px));
  box-shadow: none;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--black);
  }
}

.provisionCountProgressItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progressBar {
  width: var(--54px);
  margin-left: var(--7px);
}

.provision-menu-activeItem:not(:focus) {
  background: linear-gradient(136.72deg, rgb(0 221 251/0.22) 7.63%, rgb(0 178 255/0.22) 92.65%);
}

.provision-large {
  --button-icon-size: var(--24px);
  --button-top-padding: var(--6px);
  --button-bottom-padding: var(--6px);
}
