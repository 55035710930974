/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.badge {
  display: inline-block;
  padding: var(--1px) var(--4px) 0;
  font-size: var(--11px);
  font-weight: var(--fontWeightSemiBold);
  line-height: var(--16px);
  text-align: center;
  text-transform: uppercase;
  border-radius: var(--4px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--white);
  }
}

.large {
  min-width: unset;
  padding: var(--6px) var(--9px);
  font-size: var(--14px);
  font-weight: var(--fontWeight);
  text-transform: none;
  border-radius: var(--6px);
}

.created {
  :where(html[data-theme='lightning']) & {
    background: var(--lightning--green-500);
  }
}

.updated {
  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-400);
  }
}

.deleted {
  :where(html[data-theme='lightning']) & {
    background: var(--lightning--red-400);
  }
}

.disabled {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
    background: var(--lightning--blue-gray-200);
  }
}

.new {
  min-width: unset;

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--green-600);
  }
}

.preview {
  min-width: unset;

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--orange-400);
  }
}

.info {
  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-500);
  }
}

.low {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-600);
    background: var(--lightning--green-50);
  }
}

.medium {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--orange-700);
    background: var(--lightning--orange-50);
  }
}

.high {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-700);
    background: var(--lightning--red-50);
  }
}

.critical {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-700);
    background: var(--lightning--red-100);
  }
}
