/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.added {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-900);
  }
}

.removed {
  text-decoration: line-through;
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-900);
  }
}

.iconItem {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--yellow-900);
  }
}

.iconItemRemove {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-700);
  }
}

.attributeContainer {
  display: grid;
}
