/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

/* DraftJS style are manipulated using the classname
 * Example: https://dev.to/tumee/how-to-style-draft-js-editor-3da2
 *
 * Draft.css needs to be imported but causing a side-effect with scrolling
 * DraftJS recommendation of importing Draft.css
 * https://draftjs.org/docs/advanced-topics-issues-and-pitfalls#missing-draftcss
 *
 * Importing Draft.css in Global.css has position: relative set for DraftEditor-root thus
 * overwrite position: static to make scrolling work
*/
div[class='DraftEditor-root'] {
  position: static;
  min-height: var(--100px);
  max-height: calc(5 * var(--100px));
  overflow-y: auto; /* Set scroll */
}

/* CSS declaration for scroll maximum height which is div[class='DraftEditor-root']
 * Importing Draft.css in Global.css has position: relative set for DraftEditor-editorContainer thus
 * overwrite position: static to make scrolling work
*/
div[class='DraftEditor-editorContainer'],
div[class='public-DraftEditor-content'] {
  position: static;
  height: 100%;
  padding: var(--inputAndtextArea-vertical-padding) var(--6px);
}

/* Style default placeholder text */
div[class='public-DraftEditorPlaceholder-inner'] {
  margin: var(--inputAndtextArea-vertical-padding) var(--6px);
  font-size: var(--form-input-text);
}

/* Move the editable text content container  */
div[class='public-DraftStyleDefault-block public-DraftStyleDefault-ltr'] {
  margin-left: var(--5px);
}

/*  By using Global.css causing position: absolute thus make it static */
div[class='public-DraftEditorPlaceholder-root'] {
  position: static;
}
