/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

.toolBar {
  composes: gapMedium gapHorizontalWrap from 'utils.css';
  align-items: center;
  border: none;
}

.toolGroup {
  composes: gapSmall gapHorizontalWrap from 'utils.css';
  align-items: center;
}

.toolGroupExpand {
  flex: 1 1 100%;
  min-width: 0; /* Allow block children to shrink and fit into 100% */

  .toolGroupItem {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.selected {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-300);
  }
}

.link {
  cursor: pointer;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-300);
  }

  &:hover {
    text-decoration: underline;
  }
}
