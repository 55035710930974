/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.listContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.connectionDirectionsContainer {
  padding: var(--10px) 0 var(--5px) var(--15px);
}
