/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.scroller {
  flex: 1 1 auto; /* Make content occupy available space on modal resize */

  &.scrollable {
    overflow: auto;
    /* Prevent bounce, right/left navigations and 'pull to refresh' once a scroller has reached its full extent */
    overscroll-behavior: none;
  }

  &:not(.scrollable) {
    overflow: unset;
  }
}

.content {
  word-break: break-word;
  overflow-wrap: break-word;
}

.contentPadding {
  padding: var(--modal-content-v-padding) var(--modal-content-h-padding);
}
