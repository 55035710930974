/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.status-badge {
  padding: var(--2px) var(--10px);
  vertical-align: text-bottom;
  line-height: 1.2;
}

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.toolBar {
  justify-content: space-between;
}

.tallyAlignment {
  margin: 0;
}

.tallies {
  margin-bottom: 0;
}
