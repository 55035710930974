/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.notifications {
  --notification-padding: var(--offset-medium);
  --notification-message-font-size: var(--14px);
  --notification-title-font-size: var(--16px);
  --side-bar-width: var(--4px);
  --notification-border-radius: var(--6px);

  :where(html[data-theme='lightning']) & {
    --instruction-title-color: var(--lightning--blue-700);
    --instruction-border-color: var(--lightning--blue-100);
    --instruction-background-color: var(--lightning--blue-25);
    --instruction-sidebar-color: var(--lightning--blue-400);
    --success-title-color: var(--lightning--green-600);
    --success-border-color: var(--lightning--green-200);
    --success-background-color: var(--lightning--green-25);
    --success-sidebar-color: var(--lightning--green-400);
    --warning-title-color: var(--lightning--orange-600);
    --warning-border-color: var(--lightning--orange-200);
    --warning-background-color: var(--lightning--orange-25);
    --warning-sidebar-color: var(--lightning--orange-400);
    --error-title-color: var(--lightning--red-600);
    --error-border-color: var(--lightning--red-100);
    --error-background-color: var(--lightning--red-25);
    --error-sidebar-color: var(--lightning--red-400);
    --notice-text-color: var(--lightning--gray-400);
  }
}

.notifications {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.shrink {
  margin-right: auto;
  margin-left: auto;

  @media screen and (768px <= width <= 1600px) {
    width: 66%;
  }

  /*
    Width should stay the same while widening up from 1600 until it exceeds 50% of width,
    to avoid width jump when 1600 changes to 1601
    2112px = 0.66 * 1600 / 0.5
  */
  @media screen and (1601px <= width <= 2112px) {
    width: calc(0.66 * 1600px); /* 1056 */
  }

  @media screen and (width >= 2113px) {
    width: 50%;
  }
}

.notification {
  composes: gapMedium gapHorizontal from 'utils.css';
  padding-inline: var(--notification-padding);
  border: 1px solid transparent;
  border-radius: var(--notification-border-radius);

  > * {
    display: flex;
    align-items: center;

    &:not(.buttonItem, .close-button) {
      padding-block: var(--notification-padding);
    }
  }

  &.sidebar {
    padding-inline-start: calc(var(--notification-padding) + var(--side-bar-width));
  }
}

.compact {
  --notification-padding: var(--notification-border-radius);
  --notification-message-font-size: var(--13px);
  --notification-title-font-size: var(--15px);
}

.sidebar {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    inset-block-end: calc(-1 * var(--1px));
    inset-inline-start: 0;
    block-size: calc(100% + var(--2px));
    inline-size: var(--side-bar-width);
    border-start-start-radius: var(--notification-border-radius);
    border-end-start-radius: var(--notification-border-radius);
  }
}

.status-icon {
  font-size: var(--17px);
  user-select: none;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--white);
  }
}

/* Notification types */
.instruction {
  composes: notification;
  border-color: var(--instruction-border-color);
  background-color: var(--instruction-background-color);

  .status-icon,
  .title {
    color: var(--instruction-title-color);
  }

  &.sidebar::before {
    background-color: var(--instruction-sidebar-color);
  }
}

.success {
  composes: notification;
  border-color: var(--success-border-color);
  background-color: var(--success-background-color);

  .status-icon,
  .title {
    color: var(--success-title-color);
  }

  &.sidebar::before {
    background-color: var(--success-sidebar-color);
  }
}

.warning {
  composes: notification;
  border-color: var(--warning-border-color);
  background-color: var(--warning-background-color);

  .status-icon,
  .title {
    color: var(--warning-title-color);
  }

  &.sidebar::before {
    background-color: var(--warning-sidebar-color);
  }
}

.error {
  composes: notification;
  border-color: var(--error-border-color);
  background-color: var(--error-background-color);

  .status-icon,
  .title {
    color: var(--error-title-color);
  }

  &.sidebar::before {
    background-color: var(--error-sidebar-color);
  }
}

.notice {
  composes: notification;
  color: var(--notice-text-color);

  .status-icon {
    color: var(--notice-text-color);
  }

  .title {
    color: var(--notice-text-color);
  }
}

.mainItem {
  flex-grow: 1;
}

.title {
  display: inline-block;
  word-break: normal;
  overflow-wrap: normal;
}

.titleWithMessage {
  composes: title;
  composes: bold from 'utils.css';
  padding-inline-end: var(--20px);
  font-size: var(--notification-title-font-size);
}

.titleWithoutMessage {
  composes: title;
  composes: semiBold from 'utils.css';
  font-size: var(--notification-title-font-size);
}

.message {
  display: inline-block;
  font-size: var(--notification-message-font-size);
}

.buttonItem {
  composes: gapSmall gapHorizontal from 'utils.css';
  user-select: none;
}

.close-button {
  align-self: center;
  margin-inline: calc(-1 * var(--notification-padding) / 2 - var(--1px))
    calc(-1 * var(--notification-padding) + var(--1px));
  font-size: var(--13px);
}
