/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

:root {
  :where(html[data-theme='lightning'])& {
    --progress-widget-bar-fill-color: linear-gradient(to right, rgba(0 198 251 /1), rgba(0 91 234 /1));
    --progress-widget-bar-color: rgb(var(--lightning--blue-300-rgb) / 0.2);
    --progress-widget-value-label-color: var(--lightning--blue-800);
    --progress-widget-total-count-color: var(--lightning--blue-100);
  }
}

.container {
  composes: centerMarginBlock from 'utils.css';
}

.progress {
  width: 100%;
  height: var(--4px);
  border-radius: var(--4px);
  background-color: var(--progress-widget-bar-color);

  > div {
    height: 100%;
    border-radius: var(--4px);
    background: var(--progress-widget-bar-fill-color);
  }
}

.green {
  :where(html[data-theme='lightning']) & {
    --progress-widget-bar-fill-color: linear-gradient(136.72deg, rgb(93 218 49) 7.63%, rgb(0 150 32) 92.65%);
    --progress-widget-bar-color: var(--lightning--green-50);
    --progress-widget-value-label-color: var(--lightning--green-700);
    --progress-widget-total-count-color: var(--lightning--green-700);
  }
}

.link {
  display: block;
  margin-bottom: var(--10px);
  &:hover {
    .progress {
      opacity: 0.8;
    }

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-800);
    }
  }
}

.no-link {
  display: block;
  margin-bottom: var(--10px);
}

.header {
  --progress-widget-header-padding-v: var(--offset-xsmall);
  --progress-widget-label-color: inherit;

  display: flex;
  justify-content: space-between;
  padding: var(--progress-widget-header-padding-v) 0;
  font-weight: var(--fontWeightBold);

  .link {
    color: var(--progress-widget-label-color);
  }

  :where(html[data-theme='lightning']) & {
    --progress-widget-label-color: var(--lightning--blue-800);
  }
}

.valueLabel {
  font-weight: var(--fontWeightBold);
  color: var(--progress-widget-value-label-color);
}

.intermediateLabel {
  display: inline-block;
  width: 100%;
  margin-top: var(--2px);
  font-size: var(--12px);
  font-weight: var(--fontWeightBold);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-700);
  }
}

.totalCountLabel {
  font-size: var(--12px);
  font-weight: var(--fontWeightBold);
  color: var(--progress-widget-total-count-color);
}
