/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
.wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.text {
  font-weight: var(--fontWeightBold);
  text-anchor: middle;

  :where(html[data-theme='lightning']) & {
    fill: var(--lightning--blue-800);
  }
}

.background {
  :where(html[data-theme='lightning']) & {
    fill: var(--lightning--gray-100);
  }
}

.foreground {
  &:focus {
    outline: none;
  }

  &:hover {
    .seg {
      opacity: 0.8;
    }

    .label {
      :where(html[data-theme='lightning']) & {
        fill: var(--lightning--blue-600);
      }
    }
  }
}

.label {
  :where(html[data-theme='lightning']) & {
    fill: var(--lightning--gray-600);
  }
}

.legend {
  margin: 0;
  padding: 0;
  list-style: none;

  & li {
    display: flex;
    align-items: center;
    margin-top: var(--8px);
    font-size: var(--12px);

    &:first-child {
      margin-top: 0;
    }
  }
}

.legendBullet {
  display: inline-block;
  width: var(--10px);
  height: var(--10px);
  margin-right: var(--5px);
  border-radius: var(--3px);
}
