/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  padding-top: var(--8px);
}

.tabContent {
  position: relative;
  display: flex;
  flex-grow: 1;
}

.tabHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: var(--10px);
  overflow: auto;
}

.icon {
  font-size: var(--10px);
  cursor: pointer;
}

.scrollable {
  position: absolute;
  display: flex;
  flex-grow: 1;
  inset: 0;
  overflow-y: auto;
}
