/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.tallyAlignment {
  margin: 0;
}
