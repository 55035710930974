/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

:root {
  --counter-badge-small: var(--10px);
  --counter-badge-large: var(--12px);
}

.counter {
  composes: semiBold from 'utils.css';
  position: absolute;
  z-index: 1;
  top: calc(-1 * var(--5px));
  right: calc(-1 * var(--4px));
  min-width: var(--counter-diameter);
  padding: 0 var(--4px);
  line-height: var(--counter-diameter);
  text-align: center;
  pointer-events: none;
  border-radius: calc(var(--counter-diameter) / 2);
}

.small {
  --counter-diameter: var(--12px);

  font-size: var(--counter-badge-small);
}

.large {
  --counter-diameter: var(--16px);

  font-size: var(--counter-badge-large);
}

.dozens {
  right: calc(-1 * var(--5px));
  padding: 0 var(--4px);
  border-radius: var(--8px);
}

.hundreds {
  top: calc(-1 * var(--6px));
  right: calc(-1 * var(--6px));
  padding: 0 var(--3px);
  font-size: var(--13px);
  line-height: var(--16px);
  border-radius: var(--10px);
}

/* Badge color types */
.default {
  &:not(.disabled) {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--white);
      box-shadow: 0 0 0 1px var(--lightning--white);
      background-color: var(--lightning--blue-gray-700);
    }
  }

  &.disabled {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--gray-25);
      background-color: var(--lightning--blue-gray-500);
    }
  }
}

.gray {
  &:not(.disabled) {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--white);
      box-shadow: 0 0 0 1px var(--lightning--white);
      background-color: var(--lightning--blue-gray-500);
    }
  }

  &.disabled {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--gray-900);
      background-color: var(--lightning--blue-gray-200);
    }
  }
}

.yellow {
  &:not(.disabled) {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--white);
      box-shadow: 0 0 0 1px var(--lightning--white);
      background-color: var(--lightning--yellow-500);
    }
  }

  &.disabled {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--yellow-25);
      background-color: var(--lightning--yellow-300);
    }
  }
}

.orange {
  &:not(.disabled) {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--white);
      box-shadow: 0 0 0 1px var(--lightning--white);
      background-color: var(--lightning--orange-400);
    }
  }

  &.disabled {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--orange-50);
      background-color: var(--lightning--orange-300);
    }
  }
}

.red {
  &:not(.disabled) {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--white);
      box-shadow: 0 0 0 1px var(--lightning--white);
      background-color: var(--lightning--red-500);
    }
  }

  &.disabled {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--red-25);
      background-color: var(--lightning--red-200);
    }
  }
}

.animate {
  animation: 0.75s cubic-bezier(0.215, 0.61, 0.355, 1) 0s 1 bounceIn;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
