/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
:root {
  :where(html[data-theme='lightning']) & {
    --map-parallel-coords-link-color: var(--lightning--blue-400);
    --map-parallel-coords-axis-color: var(--lightning--black);
    --map-parallel-coords-axis-hover-color: var(--lightning--orange-600);
    --map-parallel-coords-tick-hover-color: var(--lightning--green-600);
  }
}

.renderContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100%;
}

.svg {
  width: 100%;
  height: 100%;
}

.svg text {
  text-shadow:
    0 1px 0 var(--lightning--white),
    1px 0 0 var(--lightning--white),
    0 -1px 0 var(--lightning--white),
    -1px 0 0 var(--lightning--white);
}

.links path {
  fill: none;
  stroke: var(--lightning--blue-400);
}

.tick {
  color: var(--map-parallel-coords-axis-color);
}

.tick:hover {
  color: var(--map-parallel-coords-tick-hover-color);
}

.axis {
  color: var(--map-parallel-coords-axis-color);
}

.axisTitle {
  cursor: grab;
  fill: var(--map-parallel-coords-axis-color);
  text-anchor: middle;
}

.axis:hover {
  color: var(--map-parallel-coords-axis-hover-color);
}

.informationBar {
  composes: gapXSmall gapHorizontal from 'utils.css';
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--offset-xsmall);
}
