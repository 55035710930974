/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

:root {
  --pill-line-height: var(--14px);
  --pill-content-vertical-padding: var(--4px);
  --pill-content-horizontal-padding: var(--4px);
  --pill-content-border-width: var(--1px);
  --pill-extra-vertical-padding: var(--3px);
  --pill-extra-horizontal-padding: var(--5px);
  --pill-text-horizontal-padding: var(--4px);
  --pill-exclusion-label-padding: var(--4px);
  /* Variable for overall height of one line of normal and extra scoped pills */
  --pill-normal-total-height: calc(var(--pill-line-height) + 2 * var(--pill-content-vertical-padding));

  :where(html[data-theme='lightning'])& {
    --pill-content-color: var(--lightning--blue-gray-900);
    --pill-content-color-hover: var(--lightning--blue-gray-700);
    --pill-content-color-focus: var(--lightning--blue-gray-900);
    --pill-content-color-active: var(--lightning--blue-gray-900);
    --pill-content-background-color: var(--lightning--blue-gray-50);
    --pill-content-background-color-hover: var(--lightning--blue-50);
    --pill-content-background-color-focus: var(--lightning--blue-gray-50);
    --pill-content-background-color-active: var(--lightning--blue-100);
    --pill-content-border-color: transparent;
    --pill-content-border-color-hover: var(--lightning--blue-200);
    --pill-content-border-color-focus: var(--lightning--blue-500);
    --pill-content-border-color-active: transparent;
    --pill-content-outline: var(--2px) solid var(--lightning--blue-200);
    --pill-exclusion-color: var(--lightning--white);
    --pill-exclusion-background-color: var(--lightning--blue-gray-700);
    --pill-close-icon-color: var(--lightning--blue-gray-700);
    --pill-close-icon-color-hover: var(--lightning--red-600);
    --pill-close-icon-color-active: var(--lightning--red-700);
    --pill-close-background-color-hover: rgb(var(--lightning--red-200-rgb) / 0.6);
    --pill-close-background-color-active: var(--lightning--red-200);
  }
}

.pill {
  position: relative;
  display: inline-flex;
  max-width: 100%;
  overflow: hidden;
  user-select: none;
  border-radius: var(--12px);
}

.exclusionArea {
  --pill-symbol-group-box-shadow: 0 0 0 var(--1px) var(--pill-exclusion-background-color);

  display: flex;
  align-items: center;
  color: var(--pill-exclusion-color);
  border-radius: var(--12px) var(--0px) var(--0px) var(--12px);
  background-color: var(--pill-exclusion-background-color);
}

.content {
  display: flex;
  align-items: center;
  padding: var(--pill-content-vertical-padding) var(--pill-content-horizontal-padding);
  line-height: var(--pill-line-height);
  color: var(--pill-content-color);
  border: var(--pill-content-border-width) solid var(--pill-content-border-color);
  border-radius: var(--12px); /* Overall height is 22px (2px + 18px + 2px) */
  background: var(--pill-content-background-color);
}

.text {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  flex: 1 1 auto;
  align-self: auto;
  align-items: center;
  min-width: 0; /* Very important to allow label text to wrap in GRID, https://css-tricks.com/flexbox-truncated-text */
  padding: 0 var(--pill-text-horizontal-padding);
  font-size: var(--12px);
  text-align: initial;
  text-overflow: ellipsis;
  text-decoration: var(--pill-content-text-decoration, none);
  word-break: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.exclusionLabel {
  padding: 0 var(--pill-exclusion-label-padding);
  overflow-wrap: break-word;
}

.pillSymbol-pillSymbol {
  flex: 0 0 auto;
}

.pill {
  --pill-symbol-group-box-shadow: 0 0 0 var(--1px) var(--pill-content-background-color);
}

.created {
  :where(html[data-theme='lightning']) & {
    --pill-content-color: var(--lightning--green-600);
    --pill-content-color-hover: var(--lightning--green-700);
    --pill-content-color-active: var(--lightning--green-800);
    --pill-content-background-color: var(--lightning--green-100);
    --pill-content-background-color-hover: var(--lightning--green-50);
    --pill-content-background-color-active: var(--lightning--green-200);
    --pill-content-border-color: transparent;
    --pill-content-border-color-hover: var(--lightning--green-200);
  }
}

.warning {
  :where(html[data-theme='lightning']) & {
    --pill-content-color: var(--lightning--orange-600);
    --pill-content-color-hover: var(--lightning--orange-700);
    --pill-content-color-active: var(--lightning--orange-800);
    --pill-content-background-color: var(--lightning--orange-100);
    --pill-content-background-color-hover: var(--lightning--orange-50);
    --pill-content-background-color-active: var(--lightning--orange-200);
    --pill-content-border-color: transparent;
    --pill-content-border-color-hover: var(--lightning--orange-200);
  }
}

.error {
  :where(html[data-theme='lightning']) & {
    --pill-content-color-hover: var(--lightning--red-700);
    --pill-content-color-active: var(--lightning--red-800);
    --pill-content-background-color: var(--lightning--red-100);
    --pill-content-background-color-hover: var(--lightning--red-50);
    --pill-content-background-color-active: var(--lightning--red-200);
    --pill-content-border-color: transparent;
    --pill-content-border-color-hover: var(--lightning--red-200);
  }
}

.deleted {
  --pill-content-text-decoration: line-through;

  :where(html[data-theme='lightning']) & {
    --pill-content-color: var(--lightning--red-700);
    --pill-content-color-hover: var(--lightning--red-600);
    --pill-content-color-active: var(--lightning--red-900);
    --pill-content-background-color: var(--lightning--red-100);
    --pill-content-background-color-hover: var(--lightning--red-50);
    --pill-content-background-color-active: var(--lightning--red-200);
    --pill-content-border-color: transparent;
    --pill-content-border-color-hover: var(--lightning--red-200);
  }
}

.exclusion {
  .content {
    padding: 0;
    background-clip: content-box;

    > * {
      padding: var(--pill-content-vertical-padding) var(--pill-content-horizontal-padding);

      &:not(:last-child) {
        padding-right: 0;
      }
    }
  }

  .close {
    padding-right: calc(var(--pill-content-horizontal-padding) + var(--pill-text-horizontal-padding));
  }
}

.noFill {
  --pill-content-border-color: transparent;
  --pill-content-background-color: transparent;
  --pill-exclusion-background-color: transparent;
  --pill-exclusion-color: unset;
  --pill-symbol-group-box-shadow: unset;
}

.pill.disabled {
  --pill-content-text-decoration: line-through;

  :where(html[data-theme='lightning']) & {
    --pill-content-color: var(--lightning--blue-gray-500);
    --pill-content-background-color: var(--lightning--gradient-400);
    --pill-content-border-color: var(--lightning--blue-gray-50);
  }
}

.pill.added,
.pill.modified,
.pill.deleted,
.pill.allowed,
.pill.blocked,
.pill.unknown,
.pill.potentiallyBlocked {
  .status-icon {
    align-self: center;
    padding-left: var(--pill-text-horizontal-padding);
    font-size: var(--9px);
  }
}

.status-icon {
  color: var(--status-icon-color);
}

:where(html[data-theme='lightning']) {
  .pill.added .status-icon {
    --status-icon-color: var(--lightning--green-500);
  }

  .pill.modified .status-icon {
    --status-icon-color: var(--lightning--blue-600);
  }

  .pill.allowed .status-icon {
    --status-icon-color: var(--lightning--green-600);
  }

  .pill.blocked .status-icon {
    --status-icon-color: var(--lightning--red-500);
  }

  .pill.unknown .status-icon {
    --status-icon-color: var(--lightning--gray-400);
  }

  .pill.potentiallyBlocked .status-icon {
    --status-icon-color: var(--lightning--orange-300);
  }
}

.updateTypeIcon {
  position: absolute;
  top: calc(-1 * var(--6px));
  right: calc(-1 * var(--4px));
  font-size: var(--10px);
}

.create-svg,
.update-svg,
.delete-svg {
  width: var(--12px); /* To fit stroke */
  stroke-width: var(--3px);
  stroke-linejoin: round;

  :where(html[data-theme='lightning']) & {
    stroke: var(--lightning--white);
  }
}

.create-icon {
  composes: updateTypeIcon;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-500);
  }
}

.update-icon {
  composes: updateTypeIcon;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-500);
  }
}

.delete-icon {
  composes: updateTypeIcon;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-600);
  }
}

.close {
  display: inline-block;
  align-self: center;
  margin-block: calc(-1 * var(--pill-content-vertical-padding));
  margin-inline: calc(-1 * var(--pill-content-horizontal-padding));
  padding-block: var(--pill-content-vertical-padding);
  padding-inline: var(--pill-content-horizontal-padding);
  font-size: var(--13px);
  color: var(--pill-close-icon-color);
  cursor: pointer;

  &:hover {
    --pill-close-icon-color: var(--pill-close-icon-color-hover);
    --pill-close-background-color: var(--pill-close-background-color-hover);
  }

  &:active {
    --pill-close-icon-color: var(--pill-close-icon-color-active);
    --pill-close-background-color: var(--pill-close-background-color-active);
  }
}

.close-icon {
  display: inline-block;
  border-radius: var(--20px);
  background-color: var(--pill-close-background-color);

  .close-svg {
    transform: scale(0.6);
  }
}

.pill.pinned {
  .close-icon {
    display: none;
  }

  .pinned-icon {
    display: inline-block;
    transform: scale(0.8);
  }

  .close:hover {
    .close-icon {
      display: inline-block;
    }

    .pinned-icon {
      display: none;
    }
  }
}

.interactive {
  &:active {
    outline: none;
  }

  &:not(:active) {
    &:hover {
      --pill-content-border-color: var(--pill-content-border-color-hover);
    }

    &:focus-visible {
      --pill-content-border-color: var(--pill-content-border-color-focus);

      outline: var(--pill-content-outline);
      outline-offset: var(--1px);
    }

    &:hover,
    &:focus-visible {
      --pill-content-color: var(--pill-content-color-hover);
      --pill-content-background-color: var(--pill-content-background-color-hover);
    }
  }
}

.clickable {
  cursor: pointer;

  /* Only clickable should have a pressed state */
  &:active {
    --pill-content-color: var(--pill-content-color-active);
    --pill-content-background-color: var(--pill-content-background-color-active);
    --pill-content-border-color: var(--pill-content-border-color-active);
  }
}

.highlighted:not(:hover, .active) {
  --pill-content-border-color: var(--pill-content-border-color-focus);
}
