/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

:root {
  --grid-manager-height: var(--32px);
}

.manager {
  --dropdown-icon: var(--button-color-no-fill);

  position: sticky;
  display: flex;
  z-index: var(--shadow-depth-3z);
  justify-content: flex-end; /* In case items wrap on small screen */
  min-height: var(--grid-manager-height);
  padding: 0 var(--16px);
  border: var(--1px) solid transparent;
  border-radius: var(--grid-manager-radius);
  border-bottom: var(--1px) solid var(--lightning--blue-gray-200);
  background-color: var(--lightning--gray-50);

  :where(html[data-theme='lightning']) & {
    .paginator-standard,
    .dropdownButton-standard {
      &.paginator-styled:not(.paginator-disabled),
      &.dropdownButton-styled:not(.dropdownButton-disabled) {
        &:not(.dropdownButton-fill),
        &:not(.paginator-fill) {
          &.paginator-active,
          &.dropdownButton-active {
            --button-border-color: var(--lightning--blue-gray-500);
            --button-background-color-no-fill-active: var(--lightning--blue-gray-200);
          }

          &:focus-visible:not(:active, .paginator-active),
          &:focus-visible:not(:active, .dropdownButton-active) {
            outline: var(--2px) solid var(--lightning--blue-200);
          }

          &:hover:not(:active, .paginator-active, .paginator-insensitive),
          &:hover:not(:active, .dropdownButton-active, .dropdownButton-insensitive) {
            --button-delimiter-color: var(--lightning--blue-200);
            --button-color-no-fill-hover: var(--lightning--blue-700);
            --dropdown-icon: var(--lightning--blue-700);
          }
        }
      }
    }
  }
}

.managerSmall {
  composes: manager;
}

.item {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  align-items: center;

  /* Normal gap between adjacent sibling items */
  & + & {
    margin-left: var(--25px);
  }

  /* Small gap between adjacent sibling items */
  .managerSmall & + & {
    margin-left: var(--6px);
  }
}

.expander {
  flex: 1 1 auto;
}

.selected {
  composes: item;
}

.selectedCount {
  composes: bold monoSpace from 'utils.css';
}

/* Columns/capacity selectors */
.dropdownButton-menu-menu {
  composes: item;
}

.dropdownButton-menu-trigger {
  composes: semiBold monoSpace from 'utils.css';
}

.dropdownButton-menu-itemsContainer {
  overflow: visible;
}

.dropdownButton-menu-itemsList,
.dropdownButton-menu-itemsExtender {
  /* Max width 250px */
  max-width: calc(2.5 * var(--100px));
}

.dropdownButton-menu-item {
  --menu-item-content-padding-h: var(--3px);
  --menu-item-content-padding-v: var(--3px);
  --menu-item-padding-h: var(--3px);
}

.dropdownColumnsSelectAll-itemContent {
  padding-left: var(--offset-small);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-900);
  }
}

/* Columns Defaul/Optional sections title */
.dropdownColumnsTitle-itemContent {
  composes: bold from 'utils.css';
  padding-bottom: var(--2px);
  font-size: var(--13px);
}

/* Move Close/Reset buttons to the end in Columns selector */
.dropdownColumnsButtons-itemContent {
  justify-content: flex-end;
}

/* Remove paddings from menu item and add paddings to checkbox instead */
.dropdownColumnsItem-itemContent {
  padding: 0;
}

.dropdownColumnsItem-item {
  --menu-item-margin-h: var(--10px);
}

.dropdownColumnsItem-text {
  composes: centeredFlex gapXSmall gapHorizontal from 'utils.css';
  flex: 0 1 auto;
}

.dropdownColumnsItem-badge {
  margin-right: var(--10px);
  margin-left: calc(-1 * var(--5px));
}

.dropdownColumnsSortButton-button {
  --button-min-height: auto;
  --button-top-padding: var(--5px);
  --button-right-padding: var(--7px);
  --button-bottom-padding: var(--5px);
  --button-left-padding: var(--7px);
}

.dropdownColumnsSortButton-text {
  display: flex;
  align-items: center;
}

.dropdownColumnsCheckbox-labelText {
  width: 100%;
}

.dropdownItemCheckIcon-labelBox {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-600);
  }
}

/* Modified indicator icon (blue dot) */
.dropdownButton-icon {
  --button-icon-size: var(--8px);

  color: var(--dropdown-icon);
}

/* Paginator styles */
.paginator {
  composes: item;
}

.count {
  composes: monoSpace from 'utils.css';
}

.paginator-button {
  &:not(:last-child) {
    margin-right: var(--5px);
    margin-left: var(--3px);
  }
}

.paginator-icon {
  transform: scale(1.2);
}

.dropdownColumnsMenu-badge {
  align-self: center;
  margin-right: var(--5px);
}
