/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
.draggingArea {
  --help-popup-width: 360px;
  --help-popup-height: 600px;
  --help-draggingArea-header-offset: 2px;
  --help-draggingArea-bottom-offset: 5px;
  --help-draggingArea-horizontal-offset: 15px;

  @media (height <= 657px) {
    --help-draggingArea-header-offset: 6px;
    --help-draggingArea-bottom-offset: 3px;
    --help-popup-height: calc(
      100vh - var(--header-height) - var(--help-draggingArea-header-offset) - var(--help-draggingArea-bottom-offset)
    );
  }
}

.draggingArea {
  position: fixed;
  z-index: var(--shadow-depth-3z); /* Make it below global sticky Header, to allow header menu to cover helppopup */
  inset: calc(var(--header-height) + var(--help-draggingArea-header-offset)) var(--help-draggingArea-horizontal-offset)
    var(--help-draggingArea-bottom-offset) var(--help-draggingArea-horizontal-offset);
  pointer-events: none;
}

.draggable {
  --help-dragable-border-radius: var(--4px);
  --help-header-height: var(--34px);

  position: absolute;
  display: flex;
  right: 0; /* Help should be in right bottom corner on open */
  bottom: 0;
  width: var(--help-popup-width);
  height: var(--help-popup-height);
  overflow: hidden;
  pointer-events: auto;
  border-radius: var(--help-dragable-border-radius);
  box-shadow: var(--help-dragable-box-shadow);

  :where(html[data-theme='lightning']) & {
    --help-dragable-box-shadow: 0 var(--4px) var(--16px) rgba(var(--lightning--gray-600-rgb) / 0.2);
  }
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When helppopup is in separate poppedout window, it should take height of window to make cards scrollable but title/footer fixed */
  &.standalone {
    position: fixed;
    height: 100%;
  }

  :where(html[data-theme='lightning']) & {
    --help-card-link-color: var(--lightning--blue-600);

    background: var(--lightning--white);
  }
}

/* Title section */
.titlebar {
  display: flex;
  z-index: var(--shadow-depth-3z);
  flex: 0 0 var(--help-header-height);
  align-items: center;
  user-select: none;
  background-color: var(--help-header-background-color);
  transition: var(--shadow-depth-transition-out);

  :where(html[data-theme='lightning']) & {
    --help-header-background-color: var(--lightning--blue-800);

    color: var(--lightning--white);
  }
}

.titlebarShadow {
  box-shadow: var(--shadow-depth-3);
}

.grab {
  display: flex;
  align-items: center;
  width: var(--20px);
  color: var(--help-grab-color);

  &:hover {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-300);
    }
  }

  &:active {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-600);
    }
  }

  :where(html[data-theme='lightning']) & {
    --help-grab-color: var(--lightning--blue-gray-400);
  }
}

.grab-icon {
  transform: translateX(var(--2px)) scale(0.75);
}

.title {
  --help-title-font-size: var(--16px);

  composes: bold from 'utils.css';
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: 0 var(--10px) 0 0;
  font-size: var(--help-title-font-size);
}

.draggable .title {
  cursor: move;
}

.icon {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: flex-end;
  width: var(--20px);
  height: var(--20px);
  margin-right: var(--6px);
  padding: 0 var(--10px);
  font-size: var(--12px);
  cursor: pointer;
  border-radius: var(--4px);

  &:hover {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--blue-600);
    }
  }

  &:active {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-100);
      background-color: var(--lightning--blue-700);
    }
  }
}

.expand {
  composes: icon;
}

.collapse {
  composes: icon;
}

.close {
  composes: icon;
}

/* Content section */
.cards {
  --help-cards-padding: var(--offset-x-large);

  flex: 1 1 auto;
  padding: 0 var(--help-cards-padding);
  overflow-x: hidden; /* Cards are not scrollable horizontally, but hide it anyway to avoid placeholder if user's OS always show scroll */
  overflow-y: scroll; /* Cards should be vertically scrollable by itself, title and footer are fixed  */
  overscroll-behavior-y: none; /* Prevent page scrolling on cards scrolling */
}

.card {
  --help-card-padding: var(--offset-medium);

  padding: var(--help-card-padding) 0;

  &:not(:last-of-type) {
    border-bottom: var(--1px) solid var(--help-card-border-color);
  }

  &:first-child .cardTitle {
    padding-top: var(--help-card-padding); /* "16px" if consider line heigh */
  }

  :where(html[data-theme='lightning']) & {
    --help-card-border-color: var(--lightning--blue-gray-200);
  }
}

.cardTitle {
  --help-card-title-padding: var(--offset-small);
  --help-card-title-font-size: var(--16px);

  composes: bold from 'utils.css';
  padding: 0 0 var(--help-card-title-padding);
  font-size: var(--help-card-title-font-size);

  :where(html[data-theme='lightning']) & {
    --help-card-title-color: var(--lightning--orange-600);

    color: var(--lightning--orange-700);
  }
}

.cardSubtitle {
  padding-right: var(--10px);
  font-size: var(--13px);
  font-weight: var(--fontWeightSemiBold);
  color: var(--help-card-subtitle-color);

  :where(html[data-theme='lightning']) & {
    --help-card-subtitle-color: var(--lightning--blue-gray-900);
  }
}

.customCardSubtitle {
  composes: cardSubtitle;
  padding-bottom: var(--10px);
}

.cardLink {
  --help-card-link-padding: var(--8px) 0 0;

  padding: var(--help-card-link-padding);
  font-weight: var(--fontWeightSemiBold);
  color: var(--help-card-link-color);
  text-align: right;
}

.cardLink-link {
  color: inherit;
}

.cardLink-icon {
  margin-left: var(--5px);
  font-size: var(--11px);
}

/* Footer section */
.footer {
  display: flex;
  flex: 0 0 var(--help-header-height);
  align-items: stretch;
  padding-left: var(--15px);
  user-select: none;
  background-color: var(--help-card-footer-background-color);
  transition: var(--shadow-depth-transition-out);

  :where(html[data-theme='lightning']) & {
    --help-card-footer-background-color: var(--lightning--blue-gray-100);
  }
}

.footerShadow {
  box-shadow: var(--shadow-depth-2up);
}

.footer-link {
  composes: bold from 'utils.css';
  display: flex;
  align-items: center;
  padding: 0 var(--5px);
  font-size: var(--16px);
  color: var(--help-card-link-color);
}

.footer-icon {
  margin-left: var(--10px);
  font-size: var(--13px);
}
