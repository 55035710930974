/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.areaHead {
  .target-customCellHeader,
  .targetService-customCellHeader,
  .targetLabels-customCellHeader,
  .targetSummary-customCellHeader {
    flex: 1 1 auto;

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-900);
      background-color: var(--lightning--purple-200);

      &:hover {
        background-color: var(--lightning--purple-300);
      }
    }
  }
}
