/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
.section {
  --components-page-padding: var(--offset-medium);

  width: calc(100% + var(--components-page-padding) * 2);
  margin-left: calc(-1 * var(--components-page-padding));

  &:not(:last-child) {
    :where(html[data-theme='lightning']) & {
      border-bottom: 1px solid var(--lightning--gray-200);
    }
  }
}

.title,
.content {
  transition: background-color 0.1s linear;
}

.title {
  composes: light from 'utils.css';
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 var(--14px);
  font-size: var(--18px);
  line-height: 1.7;
  user-select: none;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--white);
    background-color: var(--lightning--blue-gray-500);
  }

  .icon-icon {
    display: flex;
    align-self: stretch;
    align-items: center;
    padding-left: var(--14px);
    font-size: 0.7em;
  }

  &:focus {
    outline: none;
  }
}

.titleText {
  flex: 1 1 auto;
}

.content {
  padding: var(--components-page-padding);
}

.closed {
  padding-bottom: 0;

  .title {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--gray-200);
    }

    &:hover,
    &:focus {
      :where(html[data-theme='lightning']) & {
        color: var(--lightning--white);
      }
    }
  }
}

.iconsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(max-content, 170px));
  grid-gap: var(--10px) var(--15px);
}

.iconMirror-svg {
  transform: scale(1, -1); /* Flip double-chevron icon */
}

.customPicker {
  width: 200px;
  height: 200px;
  padding: var(--10px);

  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--gray-300);
    background-color: var(--lightning--white);
  }
}

.graphBarRow {
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: var(--10px);
    font-weight: var(--fontWeightBold);
    white-space: nowrap;
  }

  & > :last-child {
    flex: 1 1 auto;
  }
}

.tooltipDarkContainer {
  padding: var(--20px) var(--5px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-200);
    background-color: var(--lightning--gray-900);
  }

  & h1 {
    margin: var(--15px) 0;
    padding: var(--40px) 0;
    text-align: center;

    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--gray-900);
    }
  }
}

.provider {
  flex-grow: 1;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-900);
    background-color: var(--lightning--blue-50);
  }
}

.areaHead {
  .provider-customCellHeader {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--blue-300);
    }

    &:hover {
      :where(html[data-theme='lightning']) & {
        background-color: var(--lightning--blue-400);
      }
    }
  }
}

.stacked {
  flex-grow: 0;
}

.lightpink {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--red-200);
  }
}

.lightblue {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-100);
  }
}

.switchOn-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--white);
  }
}

.switchOff-icon {
  color: var(--switch-off-text-color);
}
