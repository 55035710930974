/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
:root {
  --landing-row-gab: var(--24px);
}

.title {
  --landing-title-font-size: var(--36px);
  --landing-title-padding: var(--75px) var(--75px) 0;

  padding: var(--landing-title-padding);
  font-size: var(--landing-title-font-size);
  font-weight: var(--fontWeightBold);
  color: var(--landing-title-font-color);
  text-align: center;

  :where(html[data-theme='lightning']) & {
    --landing-title-font-color: var(--lightning--blue-800);
  }
}

.large {
  --button-font-size: var(--36px);
  --button-min-height: calc(var(--100px) * 1.4);
  --button-top-padding: var(--2px);
  --button-right-padding: var(--10px);
  --button-bottom-padding: var(--4px);
  --button-left-padding: var(--10px);
  --button-border-radius: var(--3px);
  --button-progress-icon-size: var(--40px);

  width: 100%;
}

.contentspace {
  --landing-contentspace-width: calc(var(--100px) * 6);

  width: 90%;
  max-width: var(--landing-contentspace-width);
  margin: 0 auto;
}

.label {
  align-self: center;
  margin-top: var(--24px);
  font-size: var(--18px);
  font-weight: var(--fontWeightBold);
  text-transform: uppercase;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-300);
  }

  &:not(:first-child) {
    margin-top: var(--72px);
  }
}

.core {
  display: flex;
  gap: var(--landing-row-gab);
}

.footer {
  display: flex;
  justify-content: space-between;
  font-size: var(--20px);
  gap: var(--landing-row-gab);
}

.buttonContainer {
  width: 50%;

  :where(html[data-theme='lightning']) & {
    --landing-button-color: var(--lightning--blue-700);
    --landing-button-icon-area-color: var(--lightning--blue-50);
    --landing-button-icon-color: var(--lightning--blue-500);
    --landing-button-border-color: var(--lightning--blue-gray-300);
    --landing-button-background-color: var(--lightning--blue-25);
  }

  &:hover {
    :where(html[data-theme='lightning']) & {
      --landing-button-icon-area-color: var(--lightning--blue-100);
      --landing-button-color: var(--lightning--blue-600);
      --landing-button-border-color: var(--lightning--blue-300);
    }
  }
}

.landing-button {
  &.landing-styled {
    width: 100%;
    height: var(--84px);
    border: var(--1px) solid var(--landing-button-border-color);
    place-content: unset;

    &:hover {
      background-color: var(--landing-button-background-color);
    }

    :where(html[data-theme='lightning']) & {
      --button-delimiter-color: transparent;
    }
  }
}

.buttonContent {
  display: flex;
  align-items: center;
  font-weight: var(--fontWeightBold);
  color: var(--landing-button-color);
}

.buttonIconArea {
  --landing-button-icon-area-size: var(--52px);
  --landing-button-icon-area-border-radius: var(--6px);

  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--landing-button-icon-area-size);
  height: var(--landing-button-icon-area-size);
  margin-right: var(--18px);
  margin-left: var(--4px);
  border-radius: var(--landing-button-icon-area-border-radius);
  background-color: var(--landing-button-icon-area-color);
  transition: background-color 0.12s ease-in-out;
}

.buttonIconWrapper {
  padding: var(--4px);
  line-height: 1;
  border-radius: 50%;
  background-color: var(--landing-button-icon-color);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--white);
  }
}

.pair {
  :where(html[data-theme='lightning']) & {
    --landing-button-background-color: var(--lightning--green-25);
    --landing-button-icon-area-color: var(--lightning--green-50);
    --landing-button-icon-color: var(--lightning--green-500);
    --landing-button-color: var(--lightning--green-600);
  }

  &:hover {
    :where(html[data-theme='lightning']) & {
      --landing-button-icon-area-color: var(--lightning--green-100);
      --landing-button-color: var(--lightning--green-500);
      --landing-button-border-color: var(--lightning--green-300);
    }
  }
}

.demo {
  :where(html[data-theme='lightning']) & {
    --landing-button-background-color: var(--lightning--gray-25);
    --landing-button-icon-area-color: var(--lightning--teal-25);
    --landing-button-icon-color: var(--lightning--teal-500);
    --landing-button-color: var(--lightning--blue-gray-900);
  }

  &:hover {
    :where(html[data-theme='lightning']) & {
      --landing-button-icon-area-color: var(--lightning--teal-100);
      --landing-button-color: var(--lightning--blue-gray-600);
      --landing-button-border-color: var(--lightning--gray-500);
    }
  }
}

.doc {
  :where(html[data-theme='lightning']) & {
    --landing-button-background-color: var(--lightning--gray-25);
    --landing-button-icon-area-color: var(--lightning--purple-50);
    --landing-button-icon-color: var(--lightning--purple-500);
    --landing-button-color: var(--lightning--blue-gray-700);
  }

  &:hover {
    :where(html[data-theme='lightning']) & {
      --landing-button-icon-area-color: var(--lightning--purple-100);
      --landing-button-color: var(--lightning--blue-gray-600);
      --landing-button-border-color: var(--lightning--gray-500);
    }
  }
}
