/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.container {
  flex-grow: 1;
  margin: 0 var(--5px);
}

.gridContainer {
  position: relative;
  flex-grow: 1;
  margin: 0 var(--3px);
}

.gridContainerScrollable {
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.toolBar {
  padding: var(--8px);
}
