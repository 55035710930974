/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

.modal {
  --modal-border-radius: var(--20px);
  --modal-content-v-padding: var(--24px);
  --modal-content-h-padding: var(--34px);

  :where(html[data-theme='lightning']) & {
    --modal-separator-color: var(--lightning--blue-gray-100);
  }
}

.backdrop {
  composes: fixedCurtain from 'utils.css';
  z-index: 9998;
  opacity: 0;
  will-change: opacity;
  /* Disappearance animation */
  transition: opacity 0.1s linear;

  :where(html[data-theme='lightning']) & {
    background-color: rgb(var(--lightning--black-rgb) / 0.4);
  }
}

.backdropShow {
  composes: backdrop;
  opacity: 1;
  /* Appearance animation */
  transition: opacity 0.15s linear;
}

.animator {
  composes: fixedCurtain centeredFlex from 'utils.css';
  z-index: 9998;
  opacity: 0;
  transform: scale(0.96);
  will-change: opacity, transform;
  /* Disappearance animation */
  transition:
    opacity 0.1s linear,
    transform 0.4s linear;
}

.animatorShow {
  composes: animator;
  opacity: 1;
  transform: scale(1);
  /* Appearance animation */
  /* Set 25ms delay for a smoother feeling and give it a chance to calc dimensions if size-watcher exists inside Modal */
  transition:
    opacity 0.1s linear 0.025s,
    transform 0.15s ease-out 0.025s;
}

.modal {
  display: flex;
  flex-direction: column;
  max-width: 92vw;
  max-height: 90vh;
  outline: 0; /* We set focus on modal container (with tabindex: -1) by default */
  border-radius: var(--modal-border-radius);
  box-shadow: var(--shadow-depth-8);

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--white);
  }

  @media (width <= 800px) {
    max-width: 96vw;
  }
}

/* All direct children will stretch by default */
/* It's needed, for example, when ModalContent is wrapped into other element, like Form,
/* and we need it to be able to stretch on resize like ModalContent itself */
.stretchChildren > * {
  flex: 1 1 auto;
}

/* Also to make those custom elements collapsable by default automatically, ModalHeader/Content/Footer will unset this */
.noOverflowChildren > * {
  overflow: hidden;
}

/* But some elements need to be fixed, like Header and Footer, so they can compose this class */
.fixedHeight {
  flex: 0 0 auto;
}

/* On small height screen (like portrait mobile) stick modal to the top, to make it grow only downwards */
@media (height <= 350px) {
  .backdrop {
    align-items: flex-start;
  }

  /* And modal should have 2vh top and 2vh bottom margin */
  .modal {
    max-height: 96vh;
    margin-top: 2vh;
  }
}
