/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.field {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: var(--3px);
}

.fieldLabel {
  white-space: nowrap;
}

.fieldLabelText::after {
  content: ':';
}
