/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.reminder {
  --reminder-popup-border-radius: var(--8px);
  --reminder-popup-counter-font-size: var(--24px);
  --reminder-popup-message-font-size: var(--14px);

  position: relative;
  display: flex;
  flex-direction: row-reverse;
  width: calc(4.5 * var(--100px));
  min-height: var(--90px);
  margin-bottom: var(--10px);
  opacity: 1;
  border-radius: var(--reminder-popup-border-radius);

  :where(html[data-theme='lightning']) & {
    --reminder-popup-border-color: var(--lightning--gray-400);
    --reminder-popup-message-color: var(--lightning--blue-gray-900);
    --reminder-popup-message-block-bg-color: var(--lightning--gray-25);
    --reminder-popup-counter-background-color: var(--lightning--white);
    --reminder-popup-counter-color: var(--lightning--blue-gray-900);
    --reminder-popup-title-color: var(--lightning--blue-gray-800);

    box-shadow: var(--lightning--shadow-600);
  }
}

.counter {
  composes: bold from 'utils.css';
  position: absolute;
  display: flex;
  float: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  width: var(--100px);
  height: 100%;
  font-size: var(--reminder-popup-counter-font-size);
  color: var(--reminder-popup-counter-color);
  border-radius: var(--reminder-popup-border-radius) 0 0 var(--reminder-popup-border-radius);
  background-color: var(--reminder-popup-counter-background-color);

  :where(html[data-theme='lightning']) & {
    border: var(--1px) solid var(--reminder-popup-border-color);
  }
}

.counterTimeUnit {
  font-size: var(--14px);
}

.warning {
  :where(html[data-theme='lightning']) & {
    --reminder-popup-counter-background-color: var(--lightning--orange-600);
    --reminder-popup-border-color: var(--lightning--orange-200);
    --reminder-popup-message-block-bg-color: var(--lightning--orange-25);
    --reminder-popup-message-color: var(--lightning--orange-900);
    --reminder-popup-title-color: var(--lightning--orange-700);
  }
}

.critical {
  :where(html[data-theme='lightning']) & {
    --reminder-popup-counter-background-color: var(--lightning--red-700);
    --reminder-popup-border-color: var(--lightning--red-200);
    --reminder-popup-message-block-bg-color: var(--lightning--red-25);
    --reminder-popup-message-color: var(--lightning--red-900);
    --reminder-popup-title-color: var(--lightning--red-700);
  }
}

.body {
  display: flex;
  height: 100%;
  font-size: var(--reminder-popup-message-font-size);
}

.withCounter {
  --reminder-popup-message-block-border: var(--1px) solid var(--reminder-popup-border-color);

  composes: body;
  position: relative;
  flex-flow: row wrap;
  flex-direction: column;
  top: 0;
  right: 0;
  width: calc(35 * var(--10px));
  height: auto;
  padding: var(--16px);
  text-align: left;
  border: var(--reminder-popup-message-block-border);
  border-radius: 0 var(--reminder-popup-border-radius) var(--reminder-popup-border-radius) 0;
  border-left-width: 0;
  background: var(--reminder-popup-message-block-bg-color);
}

.withoutCounter {
  composes: body;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--20px);
  text-align: center;
  border-radius: var(--reminder-popup-border-radius);
  background: var(--reminder-popup-message-block-bg-color);

  :where(html[data-theme='lightning']) & {
    border: var(--1px) solid var(--reminder-popup-border-color);
  }
}

.title {
  composes: bold from 'utils.css';
  margin-bottom: var(--8px);
  font-size: var(--16px);
  color: var(--reminder-popup-title-color);
}

.withoutCounter .title {
  font-size: var(--18px);
}

.message {
  margin-bottom: var(--8px);
  color: var(--reminder-popup-message-color);
}

.expired-link {
  margin-top: var(--10px);
  text-decoration: underline;
}

.close-icon {
  position: absolute;
  top: var(--10px);
  right: var(--10px);
  font-size: var(--10px);
  color: var(--reminder-popup-message-color);
  cursor: pointer;
  transition: transform 0.1s linear;
}
