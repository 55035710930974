/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
:root {
  --metrics-card-border-width: var(--2px);
  --metrics-card-border-style: solid;
  --metrics-card-border-radius: var(--12px);
  --metrics-card-padding-v: var(--offset-large);
  --metrics-card-padding-h: var(--offset-x-large);
  --metrics-card-header-padding: var(--card-padding);
  --metrics-card-body-padding: var(--0px);
  --metrics-card-body-gap: var(--0px);

  :where(html[data-theme='lightning'])& {
    --metrics-card-bg-color: var(--lightning--white);
    --metrics-card-font-color: initial;
    --metrics-card-box-shadow: none;
    --metrics-card-border-color: transparent;
  }
}

.card {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  padding: var(--metrics-card-padding-v) var(--metrics-card-padding-h);
  color: var(--metrics-card-font-color);
  border: var(--metrics-card-border-width) var(--metrics-card-border-style) var(--metrics-card-border-color);
  border-radius: var(--metrics-card-border-radius);
  box-shadow: var(--metrics-card-box-shadow);
  background-color: var(--metrics-card-bg-color);
  transition-duration: 0.15s;
  transition-property: background-color, border-color, font-color, box-shadow;
  transition-timing-function: ease;

  &.clickable {
    cursor: pointer;
  }

  & .title {
    transition: color 0.15s ease;
  }

  :where(html[data-theme='lightning']) & {
    --metrics-card-border-color: var(--lightning--blue-gray-200);

    &.clickable {
      &:hover {
        --metrics-card-border-color: var(--lightning--blue-100);
        --metrics-card-box-shadow-color-rgb: var(--lightning--blue-gray-700-rgb);

        box-shadow:
          var(--1px) var(--2px) rgb(var(--metrics-card-box-shadow-color-rgb) / 0.02),
          var(--3px) var(--3px) var(--6px) 0 rgb(var(--metrics-card-box-shadow-color-rgb) / 0.02),
          var(--15px) var(--15px) var(--25px) 0 rgb(var(--metrics-card-box-shadow-color-rgb) / 0.04);

        & .title {
          color: rgb(var(--metrics-card-box-shadow-color-rgb));
        }
      }

      &:active {
        --metrics-card-bg-color: var(--lightning--blue-100);
      }

      &:focus {
        --metrics-card-bg-color: var(--lightning--blue-50);
        --metrics-card-border-color: var(--lightning--blue-500);
      }
    }
  }
}

.displayInRow {
  flex-flow: row nowrap;

  & .header {
    align-self: flex-start;
    max-width: 50%;

    & .title {
      white-space: break-spaces;
    }
  }
}

.body {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: var(--metrics-card-body-padding);
  padding-top: 0;
  /* need for the scrollable detection */
  padding-bottom: var(--1px);
  overflow-x: hidden;
  overflow-y: auto;
  gap: var(--metrics-card-body-gap);

  & > .center {
    height: 100%;
    margin: auto 0;
  }
}

.scrollable {
  overflow-y: scroll;
}

.extraBottomMargin {
  margin-bottom: calc(
    2 * var(--form-input-border-width) + 2 * var(--inputAndtextArea-vertical-padding) +
      var(--inputAndtextArea-line-height) + var(--metrics-card-body-padding)
  );
}

.clickableLightBlue {
  :where(html[data-theme='lightning']) & {
    --metrics-card-bg-color: var(--lightning--blue-50);
    --metrics-card-font-color: var(--lightning--blue-900);
    --metrics-card-suffix-font-color: var(--lightning--blue-700);

    cursor: pointer;

    &:hover {
      --metrics-card-border-color: var(--lightning--blue-100);
      --metrics-card-box-shadow-color-rgb: var(--lightning--blue-gray-700-rgb);

      box-shadow:
        var(--1px) var(--2px) rgb(var(--metrics-card-box-shadow-color-rgb) / 0.02),
        var(--3px) var(--3px) var(--6px) 0 rgb(var(--metrics-card-box-shadow-color-rgb) / 0.02),
        var(--15px) var(--15px) var(--25px) 0 rgb(var(--metrics-card-box-shadow-color-rgb) / 0.04);

      & .title {
        color: rgb(var(--metrics-card-box-shadow-color-rgb));
      }
    }

    &:active {
      --metrics-card-bg-color: var(--lightning--blue-100);
    }

    &:focus {
      --metrics-card-bg-color: var(--lightning--blue-50);
      --metrics-card-border-color: var(--lightning--blue-500);
      --metrics-card-border-radius: var(--12px);
    }
  }
}

.blue {
  :where(html[data-theme='lightning']) & {
    --metrics-card-bg-color: var(--lightning--blue-50);
    --metrics-card-font-color: var(--lightning--blue-800);
    --metrics-card-border-color: transparent;

    &.clickable {
      &:hover {
        --metrics-card-border-color: var(--lightning--blue-100);
        --metrics-card-box-shadow-color-rgb: var(--lightning--blue-gray-700-rgb);
      }

      &:active {
        --metrics-card-bg-color: var(--lightning--blue-100);
      }

      &:focus {
        --metrics-card-bg-color: var(--lightning--blue-50);
        --metrics-card-border-color: var(--lightning--blue-500);
      }
    }
  }
}

.green {
  :where(html[data-theme='lightning']) & {
    --metrics-card-bg-color: var(--lightning--green-50);
    --metrics-card-font-color: var(--lightning--green-700);
    --metrics-card-sideNumberTitle-font-color: var(--lightning--green-500);
    --metrics-card-leftSideNumberBox-border-color: var(--lightning--green-200);
    --metrics-card-border-color: transparent;

    &.clickable {
      &:hover {
        --metrics-card-border-color: rgb(var(--lightning--green-500-rgb) / 0.4);
        --metrics-card-box-shadow-color-rgb: var(--lightning--green-700-rgb);
      }

      &:active {
        --metrics-card-bg-color: var(--lightning--green-100);
      }

      &:focus {
        --metrics-card-bg-color: var(--lightning--green-50);
        --metrics-card-border-color: var(--lightning--green-500);
      }
    }
  }
}

.orange {
  :where(html[data-theme='lightning']) & {
    --metrics-card-bg-color: var(--lightning--orange-50);
    --metrics-card-font-color: var(--lightning--orange-700);
    --metrics-card-sideNumberTitle-font-color: var(--lightning--orange-500);
    --metrics-card-leftSideNumberBox-border-color: var(--lightning--orange-200);
    --metrics-card-border-color: transparent;

    &.clickable {
      &:hover {
        --metrics-card-border-color: rgb(var(--lightning--orange-500-rgb) / 0.4);
        --metrics-card-box-shadow-color-rgb: var(--lightning--orange-700-rgb);
      }

      &:active {
        --metrics-card-bg-color: var(--lightning--orange-100);
      }

      &:focus {
        --metrics-card-bg-color: var(--lightning--orange-50);
        --metrics-card-border-color: var(--lightning--orange-500);
      }
    }
  }
}

.red {
  :where(html[data-theme='lightning']) & {
    --metrics-card-bg-color: var(--lightning--red-50);
    --metrics-card-font-color: var(--lightning--red-700);
    --metrics-card-sideNumberTitle-font-color: var(--lightning--red-400);
    --metrics-card-leftSideNumberBox-border-color: var(--lightning--red-200);
    --metrics-card-border-color: transparent;

    &.clickable {
      &:hover {
        --metrics-card-border-color: rgb(var(--lightning--red-500-rgb) / 0.4);
        --metrics-card-box-shadow-color-rgb: var(--lightning--red-700-rgb);
      }

      &:active {
        --metrics-card-bg-color: var(--lightning--red-100);
      }

      &:focus {
        --metrics-card-bg-color: var(--lightning--red-50);
        --metrics-card-border-color: var(--lightning--red-500);
      }
    }
  }
}

.gray {
  :where(html[data-theme='lightning']) & {
    --metrics-card-bg-color: var(--lightning--gray-500);
    --metrics-card-font-color: var(--lightning--white);
    --metrics-card-border-color: transparent;
  }
}

.header {
  --metrics-card-header-padding: var(--0px);

  display: flex;
  flex-flow: row nowrap;
  padding: var(--metrics-card-header-padding);

  & .title {
    font-size: var(--15px);
    font-weight: var(--fontWeightBold);
    text-overflow: ellipsis;
    white-space: break-spaces;
    cursor: default;
  }

  & .clickable {
    cursor: pointer;
  }

  & .hoverable {
    cursor: help;
  }

  & .action {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: auto;
    font-size: var(--12px);

    & > * {
      margin-left: var(--5px);
    }

    & .triggerLabel {
      transform: rotate(90deg);

      :where(html[data-theme='lightning']) & {
        color: var(--lightning--blue-gray-700);
      }
    }
  }
}

.dropdown {
  z-index: var(--shadow-above-all-z);
  right: calc(-1 * var(--7px));
}

.dropdownWithArrow::before {
  right: var(--6px);
}

.scrollDown {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: var(--5px);
  width: 100%;
}

.scrollUp {
  position: absolute;
  display: flex;
  justify-content: center;
  top: var(--55px);
  width: 100%;
  rotate: 180deg;
}

.metrics-icon {
  cursor: pointer;
  border-radius: 100%;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-800);
    border: var(--lightning--gray-500) solid 1px;
    background-color: var(--lightning--white);
  }
}

.metrics-svg {
  width: 1.5em;
  height: 1em;
  padding: var(--2px);
  padding-bottom: 0;
}

.close-icon {
  padding-left: var(--10px);
  cursor: pointer;
}

.timerIconWrapper {
  display: flex;
  justify-content: end;
  align-items: end;
  line-height: var(--16px);

  &:hover {
    cursor: pointer;
  }
}

.timer-icon {
  margin-left: var(--4px);
  font-size: var(--16px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }

  &:hover {
    cursor: pointer;
  }
}

.timer-white-icon {
  composes: timer-icon;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--white);
  }
}

.timer-red-icon {
  composes: timer-icon;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-400);
  }
}

.timer-orange-icon {
  composes: timer-icon;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--orange-400);
  }
}

.timer-green-icon {
  composes: timer-icon;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-400);
  }
}

.buttonMenu-menu-itemContent {
  justify-content: flex-end;
}

.buttonMenu-menu-triggerLabel {
  composes: semiBold from 'utils.css';
}

.timerIconMessage {
  margin-left: var(--2px);
  font-size: var(--12px);
}
