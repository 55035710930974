/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

:root {
  --map-filters-selector-border-color: rgb(247 250 252);
}

html[data-theme='lightning'] {
  --map-filters-background-color: var(--lightning--blue-gray-25);
}

.container {
  background-color: var(--map-filters-background-color);
}

.mapFilterDrawer-shadowDepth4z {
  box-shadow: none;
}
