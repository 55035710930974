/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.header {
  composes: fixedHeight from './Modal.css';
  display: flex;
  z-index: var(--shadow-depth-2z);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: var(--24px) var(--20px) var(--24px) var(--32px);
  overflow: unset;
  border-top-left-radius: var(--modal-border-radius);
  border-top-right-radius: var(--modal-border-radius);
  border-bottom: 1px solid var(--modal-separator-color);
  transition: var(--shadow-depth-transition-out);

  :where(html[data-theme='lightning']) & {
    --header-icon-color: var(--lightning--blue-gray-800);
    --header-icon-color-hover: var(--lightning--blue-gray-600);
    --header-icon-color-active: var(--lightning--blue-gray-600);
    --header-icon-background-color-active: var(--lightning--blue-gray-50);
    --header-icon-border-color-hover: var(--lightning--blue-gray-300);
    --header-icon-border-color-active: var(--lightning--blue-gray-300);
  }
}

.headerShadow {
  box-shadow: var(--shadow-depth-2);
}

.headerContainer {
  & > :not(:last-child) {
    padding-bottom: var(--offset-small);
  }
}

.title {
  composes: bold from 'utils.css';
  display: flex;
  flex: 1 1 auto; /* Make it grow to allocate whole header */
  align-items: center;
  min-width: 0;

  .titleText {
    font-size: var(--20px);
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-900);
    }
  }
}

.subtitle {
  .subtitleText {
    font-size: var(--16px);

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-700);
    }
  }
}

.close {
  display: flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  width: var(--26px);
  height: var(--26px);
  font-size: var(--12px);
  color: var(--header-icon-color);
  cursor: pointer;
  border-radius: var(--6px);

  &:hover {
    color: var(--header-icon-color-hover);
    box-shadow: inset 0 0 0 var(--1px) var(--header-icon-border-color-hover);
  }

  &:active {
    box-shadow: inset 0 0 0 var(--1px) var(--header-icon-border-color-active);
    background-color: var(--header-icon-background-color-active);
  }
}

.icon {
  line-height: 1;
}
