/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.mainAsterisk {
  padding: 0 var(--5px);

  &.asterisk {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--red-600);
    }
  }

  &.asteriskHidden {
    /* hide asterisk but maintain the dimension to prevent horizontal spacing flicker when toggling
       other elements from required to non-required when showing asterisk */
    visibility: hidden;
  }
}

.fieldError {
  position: absolute;
  right: calc(-1 * 1em - (var(--attribute-list-column-gap, var(--20px)) - 1em) / 2);
  margin-top: var(--1px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-500);
  }
}

.fieldValidated {
  position: absolute;
  right: calc(-1 * 1em - (var(--attribute-list-column-gap, var(--20px)) - 1em) / 2);
  margin-top: var(--1px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-700);
  }
}

.label {
}

.labelError {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-600);
    border-color: var(--lightning--red-400);
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: color 0.15s linear;
  }
}

.labelContainer {
  composes: bold from 'utils.css';
  position: relative;
}

.disabled {
  opacity: 0.4;
}
