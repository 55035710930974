/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.before {
  margin-right: 0;
}

:where(html[data-theme='lightning']) {
  .warning {
    color: var(--lightning--orange-600);
  }

  .exclusion {
    color: var(--lightning--purple-600);
  }

  .duplicate {
    color: var(--lightning--yellow-800);
  }

  .total {
    color: var(--lightning--gray-800);
  }

  .error {
    color: var(--lightning--red-600);
  }
}

.main {
  font-size: var(--form-input-text);
}
