/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

.header {
  --header-title-font-size: var(--24px);
  --header-subtitle-font-size: var(--16px);
}

.header {
  display: flex;
  flex: 0 0 var(--header-height); /* Fixed header height (not shrinkable) */
  align-items: stretch;
  height: var(--header-height);
  background-color: var(--header-color);
}

.headerContainer {
  composes: gap from 'utils.css';
  flex-grow: 1;
  max-width: 100%;
  padding-top: var(--20px);
  padding-inline-start: var(--page-horizontal-padding);
  padding-inline-end: var(--page-horizontal-padding);
  border-top-left-radius: var(--app-border-radius);
}

.headerContent {
  composes: gapInline from 'utils.css';
  flex-grow: 1;
}

.buttons {
  composes: gapMedium gapHorizontal centerFlexContent paddingSmallLeft from 'utils.css';
  flex-shrink: 0;
}

.horizontalLine {
  margin-inline-start: calc(-1 / 2 * var(--page-horizontal-padding));
  margin-inline-end: calc(-1 / 2 * var(--page-horizontal-padding));
  opacity: 1;
  transition: opacity 0.2s ease-out;

  :where(html[data-theme='lightning']) & {
    border-top: var(--1px) solid var(--lightning--blue-gray-50);
  }
}

.headline {
  composes: gapMedium from 'utils.css';
  flex-grow: 1;
  min-width: calc(100% / 3);
}

.headlineContent {
  composes: gapLarge gapHorizontal centerFlexAlign paddingLargeRight from 'utils.css';
}

.title {
  composes: bold from 'utils.css';
  display: inline-block;
  font-size: var(--header-title-font-size);
  text-overflow: ellipsis;
  text-indent: 0;
  white-space: nowrap;
  overflow: hidden;
  animation: fadeIn 0.25s linear forwards;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-900);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shadowDepth4 {
  :where(html[data-theme='lightning']) & {
    box-shadow: var(--0px) var(--4px) var(--8px) rgb(var(--lightning--blue-gray-700-rgb) / 0.08);
  }

  .horizontalLine {
    opacity: 0;
  }
}

.large {
  --button-icon-size: var(--24px);
}

.mode-badge {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--purple-400);
    background: var(--lightning--purple-50);
  }
}
