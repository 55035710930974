/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
/** Port Status icon */
.port-inactive-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-300);
  }
}

.port-listening-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--yellow-600);
  }
}

/** Protection Status icon */
.protection-unprotected-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-800);
  }
}

.protection-open-icon,
.protection-closed-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-900);
  }
}

/** Protected Ports info tally */
.info {
  :where(html[data-theme='lightning']) & {
    --tally-text-color: var(--lightning--green-700);
    --tally-border-color: var(--lightning--green-300);
    --tally-background-color: var(--lightning--green-50);
    --tally-background-color-hover: var(--lightning--green-25);
    --tally-background-color-active: var(--lightning--green-100);
    --tally-background-color-focus: var(--lightning--green-50);
    --tally-outline-color-focus: var(--lightning--green-500);
  }
}
