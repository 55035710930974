/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.dropdown {
  min-height: 94px;
  margin: calc(-1 * var(--2px)) 0 0 calc(-1 * var(--1px));
}

.mediumDropdown {
  composes: dropdown;
  min-height: var(--88px);
}

.table {
  width: 100%;

  & th {
    padding: var(--8px);
    text-align: left;
    border-bottom: 1px solid #dddf;
  }

  & td {
    padding: var(--8px);
    text-align: left;
  }
}
