/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.dimensionsContent {
  margin-top: var(--3px);
  padding: 0 var(--10px);
  border-radius: var(--10px);

  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--gray-300);
    background-color: var(--lightning--white);
  }
}

.manualDimensions {
  min-width: calc(2 * var(--100px));
  max-height: 45vh;
  margin: 0 calc(-1 * var(--10px));
  overflow: hidden;
}

.rowHead {
  display: none;
}

.grab {
  padding-left: var(--5px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-800);
  }
}

.rowSelected .grab-icon {
  display: none;
}

.areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--white);
  }
}

.rowBodySelected {
  .areaBody {
    border-top-left-radius: var(--10px);
    border-top-right-radius: var(--10px);

    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--white);
    }
  }
}

.rowBodyClickable:not(.rowBodySelected) {
  .areaBody {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--white);
    }
  }
}

.toolBar {
  height: var(--60px);
}
