/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_800 {
  .status-badge {
    margin: var(--4px) 0;
  }

  .areaBody .cell.status {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}
