/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.scope {
  --expandable-scope-padding-block: var(--0px); /* Vertical padding, can set to positive if add background color */
  --expandable-scope-padding-inline: var(--offset-xsmall); /* Horizontal padding */
  --expandable-scope-dash-width: var(--3px);
  --expandable-scope-border-color: transparent;
  --expandable-scope-background-color: transparent;

  composes: gapXSmall gapHorizontalWrap from 'utils.css';
  position: relative;
  align-items: center;
  margin-inline-start: calc(-1 * var(--expandable-scope-padding-inline));
  padding: var(--expandable-scope-padding-block) var(--expandable-scope-padding-inline);
  background-color: var(--expandable-scope-background-color);

  /* Highlight each scope when showing more than one */
  .multipleGroups & {
    :where(html[data-theme='lightning']) & {
      --expandable-scope-border-color: var(--lightning--blue-400);
    }

    /* The dash on the border to distinguish multiple scopes */
    &::before {
      position: absolute;
      content: '';
      top: var(--expandable-scope-padding-block);
      bottom: var(--expandable-scope-padding-block);
      left: calc(-1 * var(--expandable-scope-dash-width));
      width: var(--expandable-scope-dash-width);
      background-color: var(--expandable-scope-border-color);
    }
  }
}
