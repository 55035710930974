/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.smallContainer {
  min-width: calc(1.2 * var(--100px));
  margin-left: var(--10px);
}

.attributeList {
  margin-bottom: var(--15px);
}

.title {
  max-width: calc(6 * var(--100px));
  padding: var(--15px);
  border-radius: var(--4px);

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-50);
  }

  @media (width >= 900px) {
    margin-top: calc(-1 * var(--15px));
  }

  @media (width <= 899px) {
    margin-top: calc(-1 * var(--5px));
  }
}

.customContainer {
  display: flex;
  width: 40%;
}

.description {
  max-width: calc(6 * var(--100px));

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-900);
  }
}

.bold {
  composes: bold from 'utils.css';
}

.orderedList {
  list-style-type: decimal;
}

.error {
  margin-top: var(--3px);
  font-size: var(--12px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-800);
  }
}
