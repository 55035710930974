/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
.table {
  .cell.cellPadding.modified,
  .cell.cellPadding.error,
  .cell.cellPadding.rowStatus {
    display: inline-flex;
    align-items: center;
    height: calc(var(--optionSelector-height) + 2 * var(--optionSelector-vertical-padding));
    padding-right: 0;
    padding-left: 0;
  }

  .areaBody .checkboxCell-labelBox {
    display: inline-flex;
    align-items: center;
    height: calc(var(--optionSelector-height) + var(--body-area-vertical-padding) + var(--body-cell-vertical-padding));
  }
}
