/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.dropdown {
  :where(html[data-theme='lightning']) & {
    background: var(--lightning--white);
  }
}

.setOptions:hover {
  color: var(--text-color);

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-gray-200);
  }
}

.icon {
  font-size: var(--12px);
}

.groupingContent {
  margin-top: var(--3px);
  padding: 0 var(--10px);
  border-radius: var(--10px);

  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--gray-300);
    background-color: var(--lightning--white);
  }
}

.rowHead {
  display: none;
}

.grab {
  padding-left: var(--5px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-800);
  }
}

.rowSelected .grab-icon {
  display: none;
}

.buttonText {
  padding-right: var(--5px);
  white-space: nowrap;
}

.maxItems {
  composes: gapSmall gapHorizontal from 'utils.css';
  align-items: center;
  padding: var(--10px);
}

.wrapper {
  flex-grow: 1;
}

.setOptions {
  margin: 0 calc(-1 * var(--10px));
  padding: var(--10px);
  padding-right: var(--35px);
}

.setSettings {
  padding-left: var(--35px);

  :where(html[data-theme='lightning']) & {
    border-top: 1px solid var(--lightning--blue-gray-200);
  }
}

.modalContainer {
  padding: var(--10px);
  overflow: auto;
}

.modalOptions {
  padding: var(--10px) var(--20px);
}

.side {
  padding-left: var(--10px);
}

.labelBox {
  padding-left: var(--27px);
}

.grouping {
  composes: gapXSmall gapHorizontalWrap from 'utils.css';
}

.text {
  width: max-content;
  min-width: unset;
  word-break: normal;
}

.manualGrouping {
  min-width: calc(3 * var(--100px));
  max-height: 45vh;
  margin: 0 calc(-1 * var(--10px));
  overflow-x: hidden;
  overflow-y: scroll;
}

.focusedGrouping {
  composes: manualGrouping;
  min-width: calc(2 * var(--100px));
}

.divider {
  margin: 0 calc(-1 * var(--10px));
  padding: var(--10px) var(--10px) var(--10px) 0;

  :where(html[data-theme='lightning']) & {
    border-top: solid var(--1px) var(--lightning--gray-200);
  }
}

.apply {
  display: flex;
  justify-content: flex-end;
  padding: var(--10px) 0;
}

.defaultApply {
  padding: var(--10px) 0 0 var(--21px);
}

.default {
  composes: gapSmall gapHorizontal from 'utils.css';
  justify-content: flex-start;
  padding-right: var(--10px);
}

.restore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--5px) 0 var(--10px) 0;
}

.button {
  border-radius: var(--8px);

  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--blue-gray-200);

    &:hover,
    &.active {
      border: 1px solid var(--lightning--blue-200);
      background-color: var(--lightning--white);
    }
  }
}

.open .button {
  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--blue-500);
    background-color: var(--lightning--white);
  }
}

.toolBar {
  height: var(--60px);

  :where(html[data-theme='lightning']) & {
    border-top: 1px solid var(--lightning--gray-200);
  }
}

.areaBody {
  border-top: none;
}

.rowBodyClickable:not(.rowBodySelected) {
  .areaBody {
    border-top: none;
  }

  .areaBody:nth-child(2) {
    border-left: none;
  }

  .areaBody:last-child {
    border-right: none;
  }
}

.editOption {
  margin-left: var(--10px);
}
