/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
.preview {
  padding: var(--10px) var(--15px);

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-200);
  }
}

.asteriskHidden {
  display: none;
  padding: 0;
}

.customContainer {
  display: flex;
  align-items: flex-start;
}

.time {
  width: calc(2.8 * var(--100px));
  padding-right: var(--20px);
}

.unit {
  width: calc(2.5 * var(--100px));
  padding-right: var(--20px);
}

.content {
  font-size: var(--13px);
  font-weight: var(--fontWeight);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}
