/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.wrapper {
  display: grid;
  grid-template-rows: var(--20px) 1fr;
  grid-row-gap: var(--5px);
  width: 100%;
  text-align: center;

  :where(html[data-theme='lightning']) & {
    --horizontal-train-primary-color: var(--lightning--blue-500);
    --horizontal-train-secondary-color: var(--lightning--green-500);
    --horizontal-train-disabled-color: var(--lightning--blue-gray-200);
  }
}

/* train text styles */
.text {
  --horizontal-train-text-size: var(--13px);

  grid-column-start: span 2;
  grid-row: 2;
  padding: 0 var(--5px);
  font-size: var(--horizontal-train-text-size);
  font-weight: var(--fontWeightBold);
}

.disabledText {
  composes: text;
  font-weight: var(--fontWeightBold);
  color: var(--horizontal-train-disabled-text-color);

  :where(html[data-theme='lightning']) & {
    --horizontal-train-disabled-text-color: var(--lightning--blue-gray-600);
  }
}

.primaryText {
  composes: text;
  color: var(--horizontal-train-primary-text-color);

  :where(html[data-theme='lightning']) & {
    --horizontal-train-primary-text-color: var(--lightning--blue-700);
  }
}

.secondaryText {
  composes: text;
  color: var(--horizontal-train-secondary-text-color);

  :where(html[data-theme='lightning']) & {
    --horizontal-train-secondary-text-color: var(--lightning--green-700);
  }
}

/* grid styles */
.cell {
  /* Applied to non-text cells */
  position: relative;
  display: flex;
  align-items: center; /* centers the line vertically */
  height: 100%;
}

.line {
  width: 100%;
  height: var(--4px);
}

.disabledLine {
  composes: line;
  background-color: var(--horizontal-train-disabled-color);
}

.primaryLine {
  composes: line;
  background-color: var(--horizontal-train-primary-color);
}

.secondaryLine {
  composes: line;
  background-color: var(--horizontal-train-secondary-color);
}

.link {
  &:hover {
    text-decoration: underline;
  }

  &[aria-disabled='true'] {
    color: inherit;
  }
}

.primary-link {
  composes: link;
  color: var(--horizontal-train-primary-color);
}

.secondary-link {
  composes: link;
  color: var(--horizontal-train-secondary-color);
}

/* circle styles */
.circle {
  position: absolute;
  width: var(--20px);
  height: var(--20px);
  border-radius: 50%;
  transform: translateX(-50%);
}

.primaryCircle {
  composes: circle;
  background-color: var(--horizontal-train-primary-color);
}

.secondaryCircle {
  composes: circle;
  background-color: var(--horizontal-train-secondary-color);
}

.disabledCircle {
  composes: circle;
  background-color: var(--horizontal-train-disabled-color);
}

.lastCircle {
  right: 0;
  transform: translateX(50%);
}

.circleWithTooltip {
  cursor: pointer;
}

.train-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  & use {
    width: var(--12px);
    height: var(--9px);
  }

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--white);
  }
}
