/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.container {
  flex-grow: 1;
  padding: var(--10px) var(--10px) 0 var(--10px);
}

.toolbar {
  padding-bottom: var(--10px);
}
