/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.breadcrumbs {
  composes: gapMedium gapHorizontalWrap from 'utils.css';
  row-gap: normal;
}

.crumbs {
  --gap-column: var(--2px);
  --gap-row: normal;

  composes: gapXSmall gapHorizontalWrap from 'utils.css';
}

.crumb-button {
  --button-min-height: unset;
  --button-font-size: inherit;
  --button-left-padding: var(--3px);
  --button-right-padding: var(--3px);

  :where(html[data-theme='lightning']) & {
    --button-color-no-fill-disabled: var(--lightning--gray-600);
  }
}

.path {
  composes: gapXSmall gapHorizontal from 'utils.css';
  align-items: flex-start;
}

.pathSeparator {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}
