/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.list {
  margin-top: 0;
  .value {
    white-space: pre-wrap;
    overflow: auto;
  }
}

.error {
  margin-bottom: var(--5px);
}
