/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
.bottomSpacer {
  padding-bottom: var(--100px);
}

.text {
  padding: var(--5px) var(--6px);
  line-height: var(--20px);
  word-break: break-all;
}

.command {
  display: flex;
  justify-content: space-between;

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-50);
  }
}
