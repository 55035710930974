/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.container {
  position: relative;
}

.helper {
  position: absolute;
  z-index: -1;
  top: var(--1px);
  width: 100%;
  height: 1px;
}

.sticky {
  position: sticky;
}

.animated {
  transition: var(--shadow-depth-transition-out);
}

/* Shadow helper classes. Use them directly or compose */
.shadowDepth2z {
  z-index: var(--shadow-depth-2z);
}

.shadowDepth2 {
  composes: shadowDepth2z;
  box-shadow: var(--shadow-depth-2);
}

.shadowDepth2down {
  composes: shadowDepth2z;
  box-shadow: var(--shadow-depth-2onlyDown);
}

.shadowDepth3z {
  z-index: var(--shadow-depth-3z);
}

.shadowDepth3 {
  composes: shadowDepth3z;
  box-shadow: var(--shadow-depth-3);
}

.shadowDepth3down {
  composes: shadowDepth3z;
  box-shadow: var(--shadow-depth-3);
}

.shadowDepth4z {
  z-index: var(--shadow-depth-4z);
}

.shadowDepth4 {
  composes: shadowDepth4z;
  box-shadow: var(--shadow-depth-4);
}

.shadowDepth4down {
  composes: shadowDepth4z;
  box-shadow: var(--shadow-depth-4);
}

.shadowDepth6z {
  z-index: var(--shadow-depth-6z);
}

.shadowDepth6 {
  composes: shadowDepth6z;
  box-shadow: var(--shadow-depth-6);
}

.shadowDepth6down {
  composes: shadowDepth6z;
  box-shadow: var(--shadow-depth-6);
}

.shadowDepth8z {
  z-index: var(--shadow-depth-8z);
}

.shadowDepth8 {
  composes: shadowDepth8z;
  box-shadow: var(--shadow-depth-8);
}

.shadowDepth8down {
  composes: shadowDepth8z;
  box-shadow: var(--shadow-depth-8);
}

.shadowDepth16z {
  z-index: var(--shadow-depth-16z);
}

.shadowDepth16 {
  composes: shadowDepth16z;
  box-shadow: var(--shadow-depth-16);
}

.shadowDepth16down {
  composes: shadowDepth16z;
  box-shadow: var(--shadow-depth-16);
}

.shadowDepth24z {
  z-index: var(--shadow-depth-16z);
}

.shadowDepth24 {
  composes: shadowDepth24z;
  box-shadow: var(--shadow-depth-24);
}

.shadowDepth24down {
  composes: shadowDepth24z;
  box-shadow: var(--shadow-depth-24);
}
