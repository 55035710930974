/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

@keyframes backgroundMove {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: var(--50px) var(--50px);
  }
}

@keyframes moving-gradient {
  0% {
    background-position: left bottom;
  }
  100% {
    background-position: right bottom;
  }
}

.progressBar {
  --progressbar-height: var(--5px);
  --progressbar-border-radius: var(--2px);
  --progress-barFill-radius: var(--2px);
  --progressbar-overlay-radius: var(--2px);

  display: flex;
  flex: 1 1 auto;
  max-width: calc(6 * var(--100px));
  height: var(--progressbar-height);
  border-radius: var(--progressbar-border-radius);

  :where(html[data-theme='lightning']) & {
    box-shadow: inset 0 0 var(--1px) rgb(var(--lightning--black-rgb) / 0.3);
    background: var(--lightning--gray-50);
  }
}

.barFill {
  border-radius: var(--progress-barFill-radius);

  :where(html[data-theme='lightning']) & {
    background: linear-gradient(to left, rgb(0 91 234) 10%, rgb(0 198 251) 50%, rgb(0 91 234) 100%) repeat;
    background-size: 50% 100%;
    animation: moving-gradient 1s linear infinite;
  }
}

.gradientOverlay {
  width: 100%;
  height: 100%;
  border-radius: var(--progress-barFill-radius);

  :where(html[data-theme='lightning']) & {
    background: linear-gradient(
      to bottom,
      rgb(var(--lightning--white-rgb) / 0.4) 0%,
      rgb(var(--lightning--white-rgb) / 0.2) 30%,

      rgb(var(--lightning--white-rgb) / 0.2) 30%,
      rgb(var(--lightning--white-rgb) / 0) 60%,

      rgb(var(--lightning--white-rgb) / 0) 60%,
      rgb(var(--lightning--white-rgb) / 0.1) 100%
    );
  }
}

.xSmall {
  --progressbar-border-radius: var(--2px);
  --progress-barFill-radius: var(--1px);

  composes: progressBar;
  border-radius: var(--progressbar-border-radius);
  .barFill {
    border-radius: var(--1px);
    background-size: var(--20px) var(--20px);
    animation: backgroundMove 3s linear infinite;
  }
}

.small {
  --progressbar-height: var(--8px);
  --progressbar-border-radius: var(--2px);
  --progress-barFill-radius: var(--1px);

  composes: progressBar;

  .barFill {
    background-size: var(--20px) var(--20px);
    animation: backgroundMove 1.5s linear infinite;
  }
}

.medium {
  --progressbar-height: var(--10px);
  --progressbar-border-radius: var(--3px);

  composes: progressBar;
}

.large {
  --progressbar-height: var(--12px);
  --progress-barFill-radius: var(--3px);
  --progressbar-border-radius: var(--4px);

  composes: progressBar;
}

.xLarge {
  --progressbar-height: var(--12px);
  --progress-barFill-radius: var(--3px);
  --progressbar-border-radius: var(--4px);

  composes: progressBar;
}
