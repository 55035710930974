/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

/* Highlight required by rows with  */
.rowOnMouseOverClick .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--yellow-50);
  }
}

.modal-large {
  min-height: auto;
}
