/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.itemContent {
  font-weight: var(--fontWeightBold);
}

.itemsList {
  min-width: calc(1.5 * var(--100px));
  max-width: calc(3 * var(--100px));
}
