/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

:root {
  --card-padding: var(--offset-large) var(--offset-x-large);
  --card-border-radius: var(--8px);

  :where(html[data-theme='lightning'])& {
    --card-color: var(--lightning--blue-gray-700);
    --card-background-color: var(--lightning--white);
    --card-border-color: transparent;
  }
}

.card {
  position: relative;
  padding: var(--card-padding);
  color: var(--card-color, inherit);
  border: var(--1px) solid var(--card-border-color, transparent);
  border-radius: var(--card-border-radius);
  background-color: var(--card-background-color, inherit);
}

.primary {
  composes: card;

  :where(html[data-theme='lightning']) & {
    --card-background-color: var(--lightning--gray-50);
  }
}

.secondary {
  composes: card;

  :where(html[data-theme='lightning']) & {
    --card-background-color: var(--lightning--white);
  }
}

.withBorder {
  :where(html[data-theme='lightning']) & {
    --card-border-color: var(--lightning--blue-gray-300);
  }
}

.withCloseIcon {
  padding-inline-end: calc(var(--card-padding) + 1em);
}

.close-icon {
  position: absolute;
  top: var(--12px);
  right: var(--12px);
  margin-top: calc(var(--card-padding) / 2);
  padding: calc(var(--card-padding) / 2);
  cursor: pointer;
  user-select: none;

  .close-svg {
    transform: scale(1);
  }

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);

    &:hover {
      color: var(--lightning--blue-gray-500);
    }

    &:active {
      color: var(--lightning--blue-gray-700);
    }
  }
}

/* Utility class to make item 100% of the card width, expanding beyond paddings */
.cardWidth {
  width: calc(100% + 2 * var(--card-padding));
  margin-left: calc(-1 * var(--card-padding));
}

/* Utility class to make item 100% of the card width, plus overlapping borders */
.cardWidthBordersOverlap {
  width: calc(100% + var(--card-padding) * 2 + var(--2px));
  margin-left: calc(-1 * var(--card-padding) - var(--1px));
}
