/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.editModal {
  width: 47rem;
}

.table {
  width: 100%;

  & th {
    padding: var(--8px);
    text-align: left;
    border-bottom: 1px solid #dddf;
  }

  & td {
    padding: var(--8px);
    text-align: left;
  }
}

.userSuccess {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-900);
  }
}

.footer {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--orange-300);
  }
}

.warningWord {
  float: left;
  width: 75%;
}

.warningButton {
  float: right;
}

.dropdown {
  height: 10vh; /* used for dropdown scrolling, it is 10% of viewport height */
}
