/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.reminder {
  width: calc(4 * var(--100px));
}

.withCounter {
  width: calc(3 * var(--100px));
}

.withCounter {
  flex-direction: column;
}

.message {
  --rolling-upgrade-message-font-size: var(--16px);

  display: flex;
  font-size: var(--rolling-upgrade-message-font-size);
  font-weight: var(--fontWeightBold);
}

.title,
.message {
  margin-bottom: var(--5px);
}

.completed {
  margin-right: var(--20px);

  :where(html[data-theme='lightning']) & {
    --rolling-upgrade-completed-count-color: var(--lightning--green-600);

    color: var(--rolling-upgrade-completed-count-color, var(--lightning--green-800));
  }
}

.pending {
  :where(html[data-theme='lightning']) & {
    --rolling-upgrade-pending-count-color: var(--lightning--orange-700);

    color: var(--rolling-upgrade-pending-count-color, var(--lightning--orange-800));
  }
}
