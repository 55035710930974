/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.card {
  padding: var(--20px) var(--20px) var(--20px) var(--30px);
  border-radius: var(--10px);
  transition: background-color 100ms linear;

  &:hover {
    cursor: pointer;

    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--gray-50);
    }
  }

  :where(html[data-theme='lightning']) & {
    border: var(--3px) solid var(--lightning--gray-200);
  }
}

.pillGap {
  composes: gapXSmall gapHorizontalWrap from 'utils.css';
}

.iconStyle {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-700);
  }
}

.title {
  composes: bold from 'utils.css';
  font-size: var(--16px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-500);
  }
}

.description {
  margin: unset;
}

.cardsContainer {
  composes: gapMedium from 'utils.css';
  max-height: calc(var(--100px) * 4);
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

.areaHead .destination,
.areaHead .services {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-900);
    background-color: var(--lightning--purple-200);
  }
}
