/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.dropdownWrapper {
  width: auto;

  .dropdown {
    overflow-y: auto;
  }

  .dropdownLabel {
    white-space: nowrap;
  }
}

.selectMainLabel {
  width: calc(1.1 * var(--100px));
}

.runButton-button {
  width: var(--60px);
}

.toolGroupExpand {
  flex: 1 1 auto;
}

.selectorContainer {
  flex-grow: 1;
}

.ruleAttributeFilterContainer {
  composes: gapMedium gapAlbatross from 'utils.css';
  margin: 0;
}

/* providers and providingServices are the names of the property in Grid config */
.areaHead .providers,
.areaHead .providingServices {
  color: var(--cell-color);
  background-color: var(--cell-bg-color);

  &:hover {
    background-color: var(--cell-bg-color-hover);
  }

  :where(html[data-theme='lightning']) & {
    --cell-bg-color: var(--lightning--purple-100);
    --cell-bg-color-hover: var(--lightning--purple-200);
    --cell-color: var(--lightning--blue-gray-700);
  }
}
