/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.provisioningContent {
  composes: gapMedium from 'utils.css';
  align-items: center;
  margin: var(--20px) auto;
}

.provisionStatus {
  composes: gapMedium from 'utils.css';
  width: 100%;
  max-width: calc(6 * var(--100px));
}

.progressBar {
  margin: var(--6px) 0;
}

.provisioningContentTitle {
  margin-bottom: auto;
  font-size: var(--16px);
}

.buttonLastCommit {
  width: 200px;
}

.buttonVersions {
  width: 200px;
}

.notifications {
  margin: var(--100px) auto;
}
