/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.provisionProgress {
  --provision-progress-border-radius: var(--8px);

  position: fixed;
  display: flex;
  z-index: var(--shadow-depth-3z);
  justify-content: center;
  align-items: center;
  right: var(--20px);
  bottom: var(--20px);
  width: calc(2.6 * var(--100px));
  min-height: calc(6.4 * var(--10px));
  padding: var(--14px);
  color: var(--provision-progress-color);
  opacity: 1;
  cursor: pointer;
  border: var(--provision-progress-border);
  border-radius: var(--provision-progress-border-radius);
  background-color: var(--provision-progress-background-color);
  /* Transition to 'complete' state */
  transition: background-color 0.2s ease;
  /* Animation on first render */
  animation: appear 0.2s ease 1 alternate;

  &:hover {
    border: var(--provision-progress-border-hover);
    background-color: var(--provision-progress-background-color-hover);
  }

  :where(html[data-theme='lightning']) & {
    --provision-progress-color: var(--lightning--gray-800);
    --provision-progress-color-hover: var(--lightning--white);
    --provision-progress-background-color: var(--lightning--gray-25);
    --provision-progress-background-color-hover: var(--lightning--gray-50);
    --provision-progress-border: var(--1px) solid var(--lightning--gray-400);
    --provision-progress-border-hover: var(--1px) solid var(--lightning--gray-500);

    box-shadow: inset 0 0 var(--1px) rgb(var(--lightning--black-rgb) / 0.3);
  }
}

.provisionProgressComplete {
  justify-content: flex-start;

  .provisionProgressContent {
    flex-direction: row;
  }

  :where(html[data-theme='lightning']) & {
    --provision-progress-color: var(--lightning--green-600);
    --provision-progress-color-hover: var(--lightning--green-500);
    --provision-progress-background-color: var(--lightning--green-25);
    --provision-progress-background-color-hover: var(--lightning--green-50);
    --provision-progress-border: var(--1px) solid var(--lightning--green-200);
    --provision-progress-border-hover: var(--1px) solid var(--lightning--green-300);
  }
}

.provisionProgressContent {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  font-size: var(--15px);
  font-weight: 600; /* stylelint-disable-line */
}

.progressBar {
  margin: var(--6px) 0;
  font-size: var(--15px);
}

.provisionProgressContentNoBar {
  padding-right: var(--20px);
  text-align: center;
}

.close-icon {
  position: absolute;
  top: var(--8px);
  right: var(--8px);
  font-size: var(--10px);
}

.close-svg {
  color: var(--provision-progress-color);
  cursor: pointer;
}

.complete-icon {
  margin: 0 var(--16px) 0 var(--2px);
}

.complete-svg {
  width: var(--30px);
  height: var(--20px);
  color: var(--provision-progress-color);
}
