/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

:root {
  --form-border-radius: var(--2px);
  --form-input-text: var(--13px);
  --form-input-border-width: 1px;

  :where(html[data-theme='lightning'])& {
    --form-input-border: var(--form-input-border-width) solid var(--lightning--gray-300);
    --form-focus-border-color: var(--lightning--blue-600);
    --form-error-border-color: var(--lightning--red-500);
  }
}

.form {
}
