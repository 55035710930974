/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.container {
  flex-grow: 1;
  margin: 0 var(--5px);
}

.gridContainer {
  position: relative;
  flex-grow: 1;
  margin: 0 var(--3px);
}

.gridContainerScrollable {
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.usageHeader {
  composes: cell from 'components/Grid/Grid.css';
  font-size: var(--12px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-700);
  }
}

.toolBar {
  padding: var(--8px);
}

.policyDecision {
  padding: 0 0 var(--8px) 0;
  font-weight: 700; /* stylelint-disable-line */
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.blocked,
.blockedByBoundary {
  composes: policyDecision;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-500);
  }
}

.blocked-deficiency,
.blockedByBoundary-deficiency {
  composes: policyDecision;
  color: var(--map-map-link-deficiency-blocked);
}

.allowed,
.allowedAcrossBoundary {
  composes: policyDecision;

  :where(html[data-theme='lightning']) & {
    color: var(--map-map-link-allowed);
  }
}

.allowed-deficiency,
.allowedAcrossBoundary-deficiency {
  composes: policyDecision;
  color: var(--map-map-link-deficiency-allowed);
}

.potentiallyBlocked,
.potentiallyBlockedByBoundary,
.potentiallyBlocked-deficiency,
.potentiallyBlockedByBoundary-deficiency {
  composes: policyDecision;
  line-height: var(--18px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--orange-300);
  }
}

.deficiency-potentiallyBlocked-icon-icon,
.deficiency-potentiallyBlockedByBoundary-icon-icon {
  color: var(--map-map-link-potentially-blocked);
}

.deficiency-allowed-icon-icon,
.deficiency-allowedAcrossBoundary-icon-icon {
  color: var(--map-map-link-deficiency-allowed);
}

.deficiency-blocked-icon-icon,
.deficiency-blockedByBoundary-icon-icon {
  color: var(--map-map-link-deficiency-blocked);
}

.unknown {
  font-weight: 700; /* stylelint-disable-line */

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-400);
  }
}

.policy {
  min-width: calc(1 * var(--100px));
}

.subText {
  font-size: var(--13px);
  line-height: var(--13px);
}

.iconXLarge {
  font-size: var(--28px);
}

.label {
  margin-bottom: var(--1px);
}

.ipCounts {
  white-space: nowrap;
}

.subItem {
  line-height: var(--18px);
}

.ipLists {
  display: flex;
  align-items: center;
  line-height: var(--10px);
}

.ipList {
  width: calc(1.5 * var(--100px));
  padding-right: var(--5px);
}

.ipListLink {
  composes: link;
  align-items: center;
}

.rules {
  padding-left: var(--5px);
  line-height: var(--16px);
}

/* providers and providingServices are the names of the property in Grid config */
.areaHead {
  .target-customCellHeader,
  .targetService-customCellHeader,
  .targetLabels-customCellHeader,
  .targetSummary-customCellHeader {
    flex: 1 1 auto;
    width: unset;

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-900);
      background-color: var(--lightning--purple-200);
    }

    &:hover {
      :where(html[data-theme='lightning']) & {
        background-color: var(--lightning--purple-300);
      }
    }
  }

  .cellSortSelection {
    /* A button with dropdown menu is rendered for sort selection options */
    .sortSelectionButton-menu-trigger {
      padding: var(--2px) var(--11px);
    }
  }

  .areaHead .cell:not(.cellSortSelection) {
    padding: var(--2px) var(--11px);
  }

  :where(html[data-theme='lightning']) & {
    .target-customCellHeader,
    .targetLabels-customCellHeader,
    .targetSummary-customCellHeader,
    .targetService-customCellHeader {
      background-color: var(--lightning--purple-100);

      &:hover {
        background-color: var(--lightning--purple-200);
      }
    }
  }
}

.areaHead .checkboxCell-labelBox {
  padding: var(--2px) var(--16px);
}

.areaBody {
  .cell.cellPadding {
    padding: var(--1px) var(--5px);

    &:first-child {
      padding-top: var(--3px);
    }

    &:last-child {
      padding-bottom: var(--3px);
    }
  }
}
