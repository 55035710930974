/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
.barWithoutHoverHighlight {
  cursor: default;
  outline: none;

  :where(html[data-theme='lightning']) & {
    fill: var(--lightning--blue-700);
  }
}

.bar {
  composes: barWithoutHoverHighlight;

  &:hover {
    opacity: 0.8;
  }
}

.xAxis,
.yAxis {
  font-size: var(--12px);

  :where(html[data-theme='lightning']) & {
    fill: var(--lightning--gray-600);
  }
}
