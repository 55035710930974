/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.listSideBySide {
  > .row {
    > .key {
      text-align: left;
    }
  }
}

.key {
  white-space: nowrap;
}

.time {
  width: calc(1.5 * var(--100px));
}

.duration {
  width: calc(1.7 * var(--100px));
  padding-right: var(--20px);
}

.customContainer {
  display: flex;
  align-items: flex-start;
}
