/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.focused {
  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--blue-800);
  }
}

.item {
  margin: var(--2px);
}

.labelSelector {
  flex-grow: 1;
}

.labelType {
  composes: bold from 'utils.css';
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  width: 120px;
  padding: 0 var(--10px);
}

.editLabelsRow {
  display: flex;
}

.undoButton {
  width: var(--55px);
  padding-left: var(--5px);
}

.content {
  padding-bottom: var(--6px);
  font-size: var(--13px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}

.policyContent {
  padding: var(--12px) var(--12px) var(--6px) var(--12px);
  border-radius: var(--5px);

  :where(html[data-theme='lightning']) & {
    border: var(--1px) solid var(--lightning--blue-gray-300);
  }
}

.spinnerContainer {
  display: flex;
  align-items: center;
  height: var(--80px);
}

.icon-spinner {
  width: var(--25px);
  height: var(--25px);
  border-width: var(--4px);
}
