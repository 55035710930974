/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

.banner {
  --banner-width: auto;
  --banner-width-max: 96%;
  --main-width-min: calc(3 * var(--100px)); /* 300px */
  --main-width: calc(4.6 * var(--100px)); /* 460px */
  --main-width-max: var(--main-width); /* 460px */
  --additional-item-width: var(--92px);
  --text-font-size: var(--24px);
  --text-font-size-with-icon: var(--24px);
  --subtext-font-size: var(--14px);
  --banner-icon-font-size: var(--24px);
  --banner-border-radius: var(--8px);

  :where(html[data-theme='lightning']) & {
    --banner-icon-color: var(--lightning--white);
    --banner-core-font-color: var(--lightning--white);
    --banner-subtext-font-color: var(--lightning--gray-800);
  }
}

.centerer {
  display: flex;
  justify-content: center;
}

.banner {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: var(--banner-width);
  max-width: var(--banner-width-max); /* Banner can't be more than 96% of container (or smaller screen) */
  border-radius: var(--banner-border-radius);

  &.withIcon {
    .subText {
      padding-left: var(--34px);
    }
  }

  &:not(.withIcon) {
    .main {
      text-align: left;
    }
  }
}

.success {
  --banner-icon-font-size: var(--24px);

  composes: banner;
  outline-width: var(--2px);
  outline-style: solid;

  :where(html[data-theme='lightning']) & {
    --banner-icon-color: var(--lightning--green-600);
    --banner-core-font-color: var(--lightning--green-600);
    --banner-subtext-font-color: var(--lightning--green-800);

    outline-color: var(--lightning--green-200);
  }

  .main {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--green-25);
    }
  }

  .core {
    font-size: var(--text-font-size-with-icon);
  }

  .annexes,
  .annex {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--white);
    }
  }

  .annex {
    :where(html[data-theme='lightning']) & {
      outline: var(--2px) solid var(--lightning--green-200);
    }
  }
}

.warning {
  --banner-icon-font-size: var(--24px);

  composes: banner;
  outline-width: var(--2px);
  outline-style: solid;

  :where(html[data-theme='lightning']) & {
    --banner-icon-color: var(--lightning--orange-700);
    --banner-core-font-color: var(--lightning--orange-700);
    --banner-subtext-font-color: var(--lightning--orange-900);

    outline-color: var(--lightning--orange-200);
  }

  .main {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--orange-25);
    }
  }

  .core {
    font-size: var(--text-font-size-with-icon);
  }

  .annexes,
  .annex {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--white);
    }
  }

  .annex {
    :where(html[data-theme='lightning']) & {
      outline: var(--2px) solid var(--lightning--orange-200);
    }
  }
}

.error {
  --banner-icon-font-size: var(--24px);

  composes: banner;
  outline-width: var(--2px);
  outline-style: solid;

  :where(html[data-theme='lightning']) & {
    --banner-icon-color: var(--lightning--red-700);
    --banner-core-font-color: var(--lightning--red-700);
    --banner-subtext-font-color: var(--lightning--red-900);

    outline-color: var(--lightning--red-200);
  }

  .main {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--red-25);
    }
  }

  .core {
    font-size: var(--text-font-size-with-icon);
  }

  .annexes,
  .annex {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--white);
    }
  }

  .annex {
    :where(html[data-theme='lightning']) & {
      outline: var(--2px) solid var(--lightning--red-200);
    }
  }
}

.progress {
  --banner-icon-font-size: var(--24px);

  composes: banner;
  outline-width: var(--2px);
  outline-style: solid;

  :where(html[data-theme='lightning']) & {
    --banner-icon-color: var(--lightning--blue-900);
    --banner-core-font-color: var(--lightning--blue-900);
    --banner-subtext-font-color: var(--lightning--blue-gray-700);

    outline-color: var(--lightning--blue-gray-200);
  }

  .main {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--blue-gray-25);
    }
  }

  .core {
    font-size: var(--text-font-size-with-icon);

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-900);
    }
  }

  .annexes,
  .annex {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--white);
    }
  }

  .annex {
    :where(html[data-theme='lightning']) & {
      outline: var(--2px) solid var(--lightning--blue-gray-200);
    }
  }
}

.info {
  --banner-icon-font-size: var(--24px);

  composes: banner;
  outline-width: var(--2px);
  outline-style: solid;

  :where(html[data-theme='lightning']) & {
    --banner-icon-color: var(--lightning--blue-800);
    --banner-core-font-color: var(--lightning--blue-600);
    --banner-subtext-font-color: var(--lightning--blue-800);

    outline-color: var(--lightning--blue-200);
  }

  .main {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--blue-75);
    }
  }

  .core {
    font-size: var(--text-font-size);
  }

  .annexes,
  .annex {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--white);
    }
  }

  .annex {
    :where(html[data-theme='lightning']) & {
      outline: var(--2px) solid var(--lightning--blue-200);
    }
  }
}

.plain {
  --banner-icon-font-size: var(--24px);

  composes: banner;
  outline-width: var(--2px);
  outline-style: solid;

  :where(html[data-theme='lightning']) & {
    --banner-icon-color: var(--lightning--blue-gray-500);
    --banner-core-font-color: var(--lightning--blue-gray-500);
    --banner-subtext-font-color: var(--lightning--blue-gray-600);

    outline-color: var(--lightning--blue-gray-200);
  }

  .main {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--blue-gray-25);
    }
  }

  .core {
    justify-content: center;
    font-size: var(--text-font-size);
  }

  .annexes,
  .annex {
    :where(html[data-theme='lightning']) & {
      border-color: var(--lightning--gray-100);
    }
  }

  :where(html[data-theme='lightning']) & {
  }
}

.plainNoFill {
  composes: banner;

  .core {
    justify-content: center;
    font-size: var(--text-font-size);
  }

  :where(html[data-theme='lightning']) & {
    --banner-core-font-color: var(--lightning--blue-gray-500);
  }
}

.main {
  flex: 1 1 auto;
  width: var(--main-width);
  min-width: var(--main-width-min);
  max-width: var(--main-width-max);
  padding: var(--16px) var(--32px);
  border-radius: var(--banner-border-radius);
}

.core {
  display: flex;
  align-items: center;
  color: var(--banner-core-font-color);
}

.icon {
  display: flex;
  flex: 0 0 var(--34px);
  justify-content: flex-start;
  align-items: center;
}

.icon-icon {
  font-size: var(--banner-icon-font-size);
  color: var(--banner-icon-color);
}

.icon-spinner {
  width: var(--22px);
  height: var(--22px);
  border-width: var(--4px);
}

.text {
  composes: semiBold from 'utils.css';
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  line-height: 1.2;

  &.textCentered {
    justify-content: center;
  }
}

.subText {
  padding-top: var(--8px);
  font-size: var(--subtext-font-size);
  font-weight: var(--fontWeightSemiBold);
  color: var(--banner-subtext-font-color);
  word-break: break-word;

  &.subtextCentered {
    text-align: center;
  }
}

.annexes {
  display: flex;
  flex: 1 0 auto;
  align-items: stretch;
  max-width: var(--main-width-max);
}

.annex {
  display: flex;
  flex: 1 0 auto;
  /* Wrap and center so items are centered in vertical and horizontal axes when wrapped/not wrapped */
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: var(--additional-item-width);
  padding: var(--14px) 0;
  text-align: center;

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--gray-50);
  }
}

.annexTitle {
  max-width: 100%;
  padding: var(--4px) var(--10px);
  font-size: var(--16px);
  overflow-wrap: break-word;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-700);
  }
}

.annexCount {
  composes: bold from 'utils.css';
  padding: var(--4px);
  font-size: var(--32px);
  line-height: 1;
  overflow-wrap: break-word;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-900);
  }
}
