/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.style-labelText {
  composes: gapSmall gapHorizontalWrap centerFlexAlign from 'utils.css';
  display: flex;
}

.stylePills {
  composes: gapXSmall gapHorizontal from 'utils.css';
}

.usageHeader {
  padding: var(--head-cell-vertical-padding) var(--cell-horizontal-padding);
  font-size: var(--12px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}
