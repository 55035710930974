/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.added {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-900);
  }
}

.removed {
  text-decoration: line-through;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-900);
  }
}

.iconItem {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--yellow-900);
  }
}

.iconItemRemove {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-700);
  }
}

.visibility {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-900);
  }
}

.error {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-700);
  }
}

.areaBody {
  border-bottom: unset;
}

/* providers and providingServices are the names of the property in Grid config */
.areaHead {
  .providers-customCellHeader,
  .providingServices-customCellHeader {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-900);
      background-color: var(--lightning--purple-200);
    }

    &:hover {
      :where(html[data-theme='lightning']) & {
        background-color: var(--lightning--purple-300);
      }
    }
  }
}

.areaBody .cell.arrow {
  /* Align with the Pill */
  line-height: var(--24px);
}

.content {
  font-size: var(--13px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}

.warning {
  font-size: var(--13px);
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-600);
  }
}

.iconSpinner {
  width: var(--24px);
  height: var(--24px);
  border-width: var(--4px);
}
