/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.exclusion {
  composes: exclusion from '../IP.css';
}

.warning {
  composes: warning from '../IP.css';
}

.duplicate {
  composes: duplicate from '../IP.css';
}

.total {
  composes: total from '../IP.css';
}

.error {
  composes: error from '../IP.css';
}

.main {
  padding-left: var(--5px);
  font-size: var(--12px);
  line-height: var(--20px);
  overflow: hidden;

  > div {
    display: inline-block;

    &:not(:last-child) {
      margin-right: var(--offset-x-large);
    }
  }

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--gray-50);
  }
}
