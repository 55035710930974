/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.container {
  position: absolute;
  display: flex;
  z-index: var(--shadow-depth-3z);
  flex-flow: column nowrap;
  align-items: flex-end;
  top: calc(var(--header-height) + var(--offset-xsmall));
  right: var(--5px);
}
