/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.tabPanel {
  height: var(--40px);
  padding-top: var(--10px);
}

.container {
  display: inline-flex;
  width: 80%;
  min-width: 0;
}

.title {
  display: flex;
  align-items: center;
  max-width: 100%;
  cursor: pointer;
}

.titleName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.separator {
  margin: 0 calc(-1 * var(--10px));
}

.icon {
  margin-left: calc(-1 * var(--15px));
  font-size: 0.8em;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--white);
  }
}

.selector {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: var(--20px);
}

.appGroupSelector-toggle-button {
  display: none;
}

.appGroupSelector-selectorContainer {
  width: 100%;
}

.appGroupSelector-optionPanel {
  width: 40vw;
}

.options {
  display: flex;
}

.name {
  width: 80%;
  padding-right: var(--20px);
}

.graphBar {
  width: 40%;
}
