/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.page {
  composes: gapMedium from 'utils.css';
  flex: 1 1 100%;
}

.parent {
  composes: containerWidth from 'utils.css';
  position: relative;
  display: flex;
  min-height: 0;

  &.grow {
    flex: 1 1 100%;
  }
}

.gridContainer {
  flex: 1 1 auto;
}

.landing {
  composes: gapXLarge from 'utils.css';
  align-items: center;
}

.filterPresets {
  composes: gapMedium from 'utils.css';
  composes: gapHorizontal from 'utils.css';
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-content: center;
  width: min(calc(5 * var(--100px)), 80%);
}

.filterPreset-button {
  justify-content: stretch;

  &.filterPreset-large {
    --button-left-padding: var(--offset-x-large);
    --button-right-padding: var(--offset-x-large);
  }
}

.filterPreset-noOverflowContainer {
  composes: gapMedium from 'utils.css';
  composes: gapHorizontal from 'utils.css';
}

.landing-centerer {
  width: 100%;
}

.landing-banner {
  --main-width: max-content;

  .landing-text {
    line-height: 1.6;
  }
}
