/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_1150 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.button + .button {
  margin-bottom: var(--10px);
  margin-left: var(--8px);
}

.item {
  margin: var(--2px);
}

.selector {
  padding-bottom: var(--10px);
}

.error {
  font-size: var(--12px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-800);
  }
}

.warning-icon {
  margin-right: var(--20px);
  font-size: var(--16px);
}

.info-icon {
  margin-right: var(--5px);
  font-size: var(--16px);
}

.allOnlyWarning {
  margin-top: var(--10px);
}

.alignStart {
  align-self: flex-start;
}

.buttons {
  composes: gapXSmall gapHorizontal from 'utils.css';
  display: flex;
  justify-content: flex-end;
}
