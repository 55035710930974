/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.baseColumnOrderStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--25px);
  border-radius: var(--2px);
}

.provider {
  composes: baseColumnOrderStyles;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-900);
    background-color: var(--lightning--purple-200);
  }
}

.consumer {
  composes: baseColumnOrderStyles;
  padding-top: var(--3px);
  padding-bottom: var(--3px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
    background-color: var(--lightning--blue-gray-200);
  }
}

.icon {
  font-size: 1.6em;
  line-height: initial;
}

.before {
  margin-right: var(--20px);
}

.after {
  margin-left: var(--20px);
}
