/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

:root {
  /* must be higher than the Grid's sticky header z-indices */
  --map-toolbar-z-index: 111;
}

.toolBar {
  --gap-row: var(--gap-row-xsmall);

  position: relative;
  display: flex;
  z-index: var(--map-toolbar-z-index);
  flex-wrap: wrap;
  align-items: flex-start;
  box-shadow: var(--shadow-depth-2onlyDown);
}

.toolBar {
  padding: var(--8px) var(--5px);
  box-shadow: none;
}

.toolGroup {
  align-self: stretch;
  flex-wrap: nowrap;
  align-items: flex-start;
  min-height: var(--36px);
  gap: 0;
}

.toolGroup {
  column-gap: var(--5px);
}

.toolGroupItem {
  display: flex;
  align-self: stretch;
}

.toolBarDrawer {
  position: relative;
}

.dropdown {
  width: calc(1.6 * var(--100px));
  margin-top: var(--11px);
}

.connectionDropdown {
  width: calc(3.8 * var(--100px));
}

.policyDropdown {
  right: 0;
  width: calc(2.7 * var(--100px));
  max-height: calc(4 * var(--100px));

  & li:last-child {
    :where(html[data-theme='lightning']) & {
      border-top: var(--lightning--blue-gray-300) var(--1px) solid;
    }
  }
}

.selectorMainWrapper {
  display: flex;
  align-self: stretch;
}

.selectorMain {
  --form-focus-border-color: transparent;
  --optionSelector-vertical-padding: var(--6px);

  align-self: stretch;
  border: 1px solid transparent;
  border-radius: var(--form-border-radius);

  &.focused {
    :where(html[data-theme='lightning']) & {
      background: var(--lightning--blue-gray-200);
    }
  }

  &:focus,
  &.selectorActive {
    border: 1px solid transparent;
  }
}

.menu-dropdownWithArrow {
  margin-top: 0;

  &::before {
    display: none;
  }
}

.formatMenu-icon {
  font-size: var(--22px);
}

.displaySwitcher {
  --gap-row: normal;

  composes: gapSmall gapHorizontalWrap centerFlexContent from 'utils.css';
  padding-right: var(--5px);
}
