/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.filters {
  composes: gapMedium gapAlbatross from 'utils.css';
  margin: 0;
}

.runButton-button {
  width: var(--60px);
}

.rulesetContainer {
  &:not(:last-child) {
    margin-bottom: var(--40px);
  }
}

.gridContainer {
  margin: var(--offset-xsmall) 0;
}
