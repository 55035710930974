/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.globalRoleDetail {
  padding-top: var(--20px);
}

.redText {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-700);
  }
}
