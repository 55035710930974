/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.numberWidget {
  --number-widget-number-font-size: var(--64px);
  --number-widget-suffix-font-size: inherit;
  --number-widget-suffix-margin-l: var(--10px);
  --number-widget-number-block-padding-v: var(--offset-small);
  --number-widget-number-block-padding-h: var(--offset-large);
}

.container {
  composes: gapXLarge gapHorizontal from 'utils.css';
}

.sideNumberContainer {
  margin: 0 auto auto;
  overflow-y: hidden;
}

.numberBlock {
  --number-widget-number-block-padding-v: var(--0px);
  --number-widget-number-block-padding-h: var(--0px);

  composes: gapSmall gap from 'utils.css';
  padding: var(--number-widget-number-block-padding-v) var(--number-widget-number-block-padding-h);
  cursor: default;
  border-radius: var(--offset-small);
}

.numberBlockHoverable {
  composes: numberBlock;
  cursor: pointer;
}

.numberBlockClickable {
  composes: numberBlock;
  cursor: pointer;
}

.sideNumberBlock {
  display: flex;
  flex-direction: row;
}

.numberBox {
  --number-widget-number-font-size: var(--40px);

  vertical-align: baseline;
  line-height: 1;

  .number {
    font-size: var(--number-widget-number-font-size);
    font-weight: var(--fontWeightBold);
  }
}

.centerAligned {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftSideNumberBox {
  padding-right: var(--36px);

  :where(html[data-theme='lightning']) & {
    border-right: var(--1px) solid var(--metrics-card-leftSideNumberBox-border-color);
  }
}

.rightSideNumberBox {
  padding-left: var(--36px);
}

.suffix {
  --number-widget-suffix-font-size: var(--18px);
  --number-widget-suffix-margin-l: var(--offset-small);

  margin-left: var(--number-widget-suffix-margin-l);
  font-size: var(--number-widget-suffix-font-size);
  font-weight: var(--fontWeightBold);

  :where(html[data-theme='lightning']) & {
    color: var(--metrics-card-suffix-font-color);
  }
}

.suffixAligned {
  display: flex;
  flex-direction: row;
  align-items: end;
}

.sideNumberSuffix {
  display: table-cell;
  height: var(--number-widget-number-font-size);
  vertical-align: middle;
}

.sideNumberTitle {
  --number-widget-sideNumberTitle-margin-l: var(--offset-small);

  margin-bottom: var(--4px);
  font-weight: var(--fontWeightBold);

  :where(html[data-theme='lightning']) & {
    color: var(--metrics-card-sideNumberTitle-font-color);
  }
}
