/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.selector {
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  align-items: center;
  min-height: var(--35px);
  margin-left: var(--10px);
  padding: var(--2px);
  overflow: hidden;

  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--gray-100);
    background-color: var(--lightning--white);
  }
}

.focused {
  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--blue-600);
  }
}

.item {
  flex-grow: 1;
  margin: var(--2px);
}

.dropdown {
  position: absolute;
  z-index: 130;
  align-self: flex-start;
  width: 220px;
  margin-top: var(--34px);
  margin-left: var(--45px);
  list-style: none;

  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--gray-300);
    background: var(--lightning--white);
  }
}

.dropdownItem {
  padding: var(--8px) var(--10px);
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
}

.dropdownItemHovered {
  composes: dropdownItem;
  outline: none;

  :where(html[data-theme='lightning']) & {
    background-color: 1px solid var(--lightning--blue-gray-200);
  }
}

.dropdownArrow {
  display: flex;
  align-items: center;
  width: var(--25px);
  padding: 0 var(--5px);
  cursor: pointer;

  .icon {
    margin-left: var(--2px);
    font-size: 0.7em;
  }
}

.input {
  max-width: 100%; /* If placeholder is longer than input's parent */
  height: var(--24px);
  margin: var(--2px) var(--2px) var(--2px) var(--5px);
  outline: none;
  border: none;
}
