/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
.name {
  padding-left: var(--modal-content-h-padding);
}

.retention {
  align-items: center;
}
