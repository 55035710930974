/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.status-badge {
  padding: var(--4px) var(--8px);
  line-height: 1.2;
}

.status-badge {
  text-transform: none;
}

.table-minWidth_800 {
  .status-badge {
    margin: var(--4px) 0;
  }

  .areaBody .cell.status {
    padding-top: var(--8px);
    padding-bottom: 0;
  }
}

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}
