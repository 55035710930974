/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.error {
  :where(html[data-theme='lightning']) & {
    text-decoration: underline dashed var(--lightning--red-800);
  }
}

.duplicate {
  composes: duplicate from '../IP.css';
}

.total {
  composes: total from '../IP.css';
}

.exclusion {
  composes: exclusion from '../IP.css';
}

.warning {
  composes: warning from '../IP.css';

  :where(html[data-theme='lightning']) & {
    text-decoration: underline dashed var(--lightning--orange-900);
  }
}
