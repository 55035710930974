/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

:root {
  :where(html[data-theme='lightning'])& {
    --link-color: var(--lightning--blue-600);
    --link-color-active: var(--lightning--blue-800);
    --link-color-disabled: var(--lightning--blue-gray-500);
    --link-outline: var(--lightning--blue-200) solid var(--2px);
    --link-outline-offset: var(--1px);
  }
}

.link {
  color: var(--link-color);
  text-decoration: none;
  border-radius: var(--2px);

  &:hover:not(:active, .active, :focus-visible) {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }

  &:focus-visible {
    outline: var(--link-outline);
    outline-offset: var(--link-outline-offset);
  }

  &:is(:active, .active) {
    color: var(--link-color-active);
  }

  &[aria-disabled='true'] {
    color: var(--link-color-disabled);
    pointer-events: none;
  }
}

.active {
}
