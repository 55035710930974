/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.retentionInput {
  display: flex;
  align-items: center;
}

.days {
  padding-left: var(--5px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-900);
  }
}

.edit {
  margin-left: var(--5px);
}

.dropdown {
  min-height: 94px;
  margin: calc(-1 * var(--2px)) 0 0 calc(-1 * var(--1px));
}

.largeDropdown {
  composes: dropdown;
  min-height: var(--88px);
}

.mediumDropdown {
  composes: dropdown;
  min-height: var(--63px);
}

.shortDropdown {
  composes: dropdown;
  min-height: var(--30px);
}

.modalAttribute {
  margin-right: var(--28px);
}

.label {
  composes: bold from 'utils.css';
}
