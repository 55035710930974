/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.infoCard {
  overflow-y: hidden;
}

.card {
  position: relative;
  border-radius: var(--6px);

  &::before {
    position: absolute;
    content: '';
    bottom: calc(-1 * var(--1px));
    left: calc(-1 * var(--1px));
    width: var(--4px);
    height: calc(100% + var(--2px));
    border-top-left-radius: var(--6px);
    border-bottom-left-radius: var(--6px);

    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--blue-500);
    }
  }

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-900);
    border: 1px solid var(--lightning--blue-gray-100);
    background-color: var(--lightning--blue-gray-25);
  }
}

.title {
  composes: bold from 'utils.css';
  font-size: var(--16px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
  }
}

.message {
  font-size: var(--13px);
}

.header {
  composes: bold from 'utils.css';
  font-size: var(--14px);
}

.helpIcon-icon {
  cursor: pointer;
  user-select: none;
}

.helpIconInput-icon {
  composes: helpIcon-icon;
  composes: inputAligned from 'components/Form/Input/Input.css';
}

.helpIconSelector-icon {
  composes: helpIcon-icon;
  composes: selectorAligned from 'components/Form/OptionSelector/OptionSelector.css';
}
