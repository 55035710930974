/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.status-badge {
  min-width: var(--60px);
}

.table-minWidth_2100 {
  .areaHead.cell {
    white-space: nowrap;
  }

  .cell.workloads,
  .cell.rulesets,
  .cell.ipLists,
  .cell.services,
  .cell.labelGroups,
  .cell.settings,
  .cell.virtualServers,
  .cell.secureConnectGateways,
  .cell.virtualServices {
    text-align: right;
  }
}
