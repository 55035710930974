/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.listSideBySide {
  > .row {
    > .key {
      text-align: left;
    }
  }
}

.key {
  white-space: nowrap;
}
