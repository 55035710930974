/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.navigationAlertContainer {
  position: fixed;
  display: flex;
  z-index: var(--shadow-depth-4z);
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  margin-top: var(--offset-medium);
  pointer-events: none;
}

.navigationAlert {
  --navigationAlert-padding: var(--offset-medium);
  --navigationAlert-closeIcon-active-color: transparent;

  display: grid;
  grid-template-areas: 'statusIcon text buttons closeIcon';
  grid-column-gap: var(--offset-medium);
  max-width: 90%;
  min-height: var(--54px);
  padding-right: var(--navigationAlert-padding);
  padding-left: var(--navigationAlert-padding);
  pointer-events: auto;
  border: var(--1px) solid transparent;
  border-radius: var(--8px);
  border-top: none;
  box-shadow: var(--shadow-depth-4);

  > * {
    display: flex;
    align-items: center;

    &:not(.buttons) {
      padding-top: var(--navigationAlert-padding);
      padding-bottom: var(--navigationAlert-padding);
    }
  }

  :where(html[data-theme='lightning']) & {
    --navigationAlert-closeIcon-color: var(--lightning--gray-300);
    --navigationAlert-closeIcon-hover-color: var(--lightning--white);
    /* warning */
    --navigationAlert-warning-border-color: transparent;
    --navigationAlert-warning-bg-color: var(--lightning--orange-25);
    --navigationAlert-warning-icon-color: var(--lightning--orange-600);
    /* error */
    --navigationAlert-error-color: var(--lightning--red-900);
    --navigationAlert-error-border-color: transparent;
    --navigationAlert-error-bg-color: var(--lightning--red-25);
    --navigationAlert-error-icon-color: var(--lightning--red-600);

    box-shadow: var(--lightning--box-shadow-700);
  }
}

.warning,
.warning-filled {
  composes: navigationAlert;
  border-color: var(--navigationAlert-warning-border-color);
  background-color: var(--navigationAlert-warning-bg-color);

  .status-icon {
    color: var(--navigationAlert-warning-icon-color);
  }

  :where(html[data-theme='lightning']) & {
    --navigationAlert-closeIcon-hover-color: var(--lightning--orange-700);
    --navigationAlert-closeIcon-color: var(--lightning--orange-800);
    --navigationAlert-closeIcon-active-color: var(--lightning--orange-900);
  }
}

.error,
.error-filled {
  composes: navigationAlert;
  color: var(--navigationAlert-error-color);
  border-color: var(--navigationAlert-error-border-color);
  background-color: var(--navigationAlert-error-bg-color);

  .status-icon {
    color: var(--navigationAlert-error-icon-color);
  }

  :where(html[data-theme='lightning']) & {
    --navigationAlert-closeIcon-hover-color: var(--lightning--red-700);
    --navigationAlert-closeIcon-color: var(--lightning--red-800);
    --navigationAlert-closeIcon-active-color: var(--lightning--red-900);
  }
}

.status-icon {
  grid-area: statusIcon;
  font-size: var(--17px);
  user-select: none;
}

.text {
  grid-area: text;
}

.buttons {
  composes: gapSmall gapHorizontal from 'utils.css';
  grid-area: buttons;
  justify-content: center;
  user-select: none;
}

.close-icon {
  grid-area: closeIcon;
  margin-right: calc(-1 * var(--navigationAlert-padding));
  margin-left: calc(-1 * var(--navigationAlert-padding) / 2);
  padding-right: calc(var(--navigationAlert-padding));
  padding-left: calc(var(--navigationAlert-padding) / 2);
  font-size: 0.8em;
  color: var(--navigationAlert-closeIcon-color);
  cursor: pointer;
  user-select: none;

  &:hover {
    color: var(--navigationAlert-closeIcon-hover-color);
  }
}
