/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.wrapper {
  margin-top: var(--10px);
}

.diffWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* we need a bit of margin between block of diff and label diffs - NOT gap */
.labelsDiffMargin {
  margin-top: var(--offset-small);

  /* we don't want margin-top for label diffs when no diffed elements precedes it */
  &:first-child {
    margin-top: unset;
  }
}

.labelsDiffGap {
  composes: gapXSmall gapAlignStart gapHorizontal from 'utils.css';
}

.labelsDiffWrapper {
  composes: labelsDiffGap labelsDiffMargin;
}

.value {
  margin-right: auto;
}
