/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.container {
  flex-grow: 1;
  padding-right: var(--20px);
  padding-left: var(--20px);

  :where(html[data-theme='lightning']) & {
    padding-right: var(--0px);
    padding-left: var(--0px);
  }
}
