/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.table {
  .cell.cellPadding.status {
    padding-right: 0;
    padding-left: var(--5px);
  }
}
