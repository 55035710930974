/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.username {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-500);
  }
}

.inviteMessage {
  padding-top: var(--10px);
}

.inviteLinkBlock {
  display: flex;
  justify-content: space-between;
  margin-top: var(--10px);
}

.inviteLink {
  padding: var(--6px);

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-50);
  }
}

.success {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-800);
  }
}

.iconSvgArrowDown {
  width: 0.85em;
  height: 0.65em;
  transform: translateX(25%);
}
