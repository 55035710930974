/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
.title {
  composes: bold from 'utils.css';
  padding-bottom: var(--10px);
  font-size: var(--16px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-500);
  }
}
