/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.enforceGroup {
  composes: gapSmall gapHorizontal from 'utils.css';
}

.enforceGroupCategory {
  composes: enforceGroup;

  &.groupColumn {
    flex-direction: column;
  }
}

.enforceCategoryLineHeight {
  line-height: 1.7;
}

.enforceProvidingServices {
  flex: 1 1 0; /* Keep 'Providing Services' title on one line */
  flex-wrap: wrap;
}

.enforceBody {
  padding: 0.5em;
  font-weight: var(--fontWeightSemiBold);
  border-radius: 0 var(--8px) var(--8px) 0;
  background-color: var(--groupbar-background-color);

  :where(html[data-theme='lightning']) & {
    --groupbar-background-color: var(--lightning--gray-25);

    color: var(--lightning--blue-gray-600);
    border-left: var(--2px) solid var(--lightning--blue-400);
  }
}

.enforceMain {
  composes: enforceGroup;
  composes: enforceBody;
  display: grid;

  &.providerServicesStart {
    grid-template-columns: repeat(2, minmax(100px, max-content)) max-content minmax(100px, max-content);
  }

  &.consumerStart {
    grid-template-columns: minmax(100px, max-content) max-content repeat(2, minmax(100px, max-content));
  }
}

.iconContainer {
  padding-top: 0.2em;
  padding-right: 1em;
  padding-left: 1em;
}

.groupBar-pill {
  :where(html[data-theme='lightning']) & {
    --pill-content-border-color: var(--lightning--blue-gray-400);
  }
}

.groupBarServiceTitle {
  @media (width > 934px) {
    position: relative;
    margin-left: var(--offset-large);

    &::before {
      position: absolute;
      content: '';
      top: var(--4px);
      left: calc(-1 * var(--offset-large));
      width: var(--2px);
      height: var(--16px);
      background-color: var(--lightning--blue-gray-500);
    }
  }
}
