/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.container {
  composes: gap from 'utils.css';
  flex-grow: 1;
}

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.toolBar {
  padding: var(--8px) var(--0px);
}
