/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.customPicker {
  composes: dateTimePicker from 'components/DateTimePicker/DateTimePicker.css';
  width: initial;
  max-height: unset;
}

.dropdownWrapper {
  width: auto;
}
