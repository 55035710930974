/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.table-minWidth_801 {
  .cell.downloadUrl {
    text-align: center;
  }
}

.icon-spinner {
  width: var(--25px);
  height: var(--25px);
  border-width: var(--4px);
}

.downloadText {
  margin-left: var(--10px);
}

.dropdown {
  height: calc(15vh);
  min-height: 123px;
  min-height: var(--63px);
  margin: calc(-1 * var(--2px)) 0 0 calc(-1 * var(--1px));
}

/* To align summary attribute key with tallies */
.attributeKey {
  line-height: 2.2;
}

/* Highlight required by rows with  */
.rowOnMouseOverClick .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--yellow-50);
  }
}

.dropdown {
  min-height: 94px;
  margin: calc(-1 * var(--2px)) 0 0 calc(-1 * var(--1px));
}

.mediumDropdown {
  composes: dropdown;
  min-height: var(--88px);
}

.UsersSuccessMessage {
  text-overflow: ellipsis;
  overflow: hidden;
}

.table {
  width: 100%;

  & th {
    padding: var(--8px);
    text-align: left;
    border-bottom: 1px solid #dddf;
  }

  & td {
    padding: var(--8px);
    text-align: left;
  }
}
