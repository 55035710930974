/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.alert {
  position: absolute;
  top: calc(-1 * var(--5px));
  right: calc(-1 * var(--2px));
  border-radius: 100%;
}
