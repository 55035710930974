/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.borderMain {
  overflow: hidden;
  border: var(--form-input-border);
  border-radius: var(--form-border-radius);

  &:focus-within {
    outline: 0;
    border-color: var(--form-focus-border-color);

    /* Use to hide the placeholder on focus
       resource url: https://draftjs.org/docs/api-reference-editor/#placeholder
       refer to example rich editor example (e.g rich.html, richEditor.css)
       Note: Some reason unable to overwrite public-DraftEditorPlaceholder-root
       e.g. .hidePlaceholder div[class='public-DraftEditorPlaceholder-root'] - DraftJS doesn't overwrite this css thus
       using this declaration instead .hidePlaceholder div[class='public-DraftEditorPlaceholder-inner'] when focus is on element
    */
    & div[class='public-DraftEditorPlaceholder-inner'] {
      display: none;
    }
  }

  &.borderMainError {
    border-color: var(--form-error-border-color);
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: border-color 0.15s linear;
  }
}
