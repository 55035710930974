/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.whatsnew {
  --whatsnew-padding: var(--18px) var(--24px);
  --whatsnew-border-radius: var(--8px);
  --whatsnew-title-font-size: var(--16px);
  --whatsnew-content-font-size: var(--13px);

  composes: gapSmall from 'utils.css';
  position: relative;
  float: right;
  min-width: calc(var(--100px) * 2);
  max-width: calc(3.2 * var(--100px));
  padding: var(--whatsnew-padding);
  border-radius: var(--whatsnew-border-radius);
  box-shadow: var(--shadow-depth-2);
  background: var(--whatsnew-bg-color);

  .content {
    font-size: var(--whatsnew-content-font-size, var(--16px));

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--white);
    }
  }

  .secondary {
    padding-top: var(--offset-x-large);
    font-size: var(--13px);

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--white);
      border-top: var(--1px) solid var(--lightning--blue-gray-600);
    }
  }

  .link {
    color: var(--whatsnew-link-color);
  }

  .title {
    font-size: var(--whatsnew-title-font-size, var(--18px));
    font-weight: var(--fontWeightSemiBold);
    color: var(--whatsnew-title-color);
  }

  :where(html[data-theme='lightning']) & {
    --whatsnew-bg-color: rgba(var(--lightning--blue-950-rgb) / 0.95);
    --whatsnew-title-color: var(--lightning--orange-300);
    --whatsnew-link-color: var(--lightning--blue-200);

    box-shadow: var(--lightning--shadow-400);
  }
}

.close-icon {
  --whatsnew-close-icon-top: var(--10px);
  --whatsnew-close-icon-right: var(--10px);

  position: absolute;
  top: var(--whatsnew-close-icon-top, var(--5px));
  right: var(--whatsnew-close-icon-top, var(--5px));
  font-size: var(--10px);
  cursor: pointer;

  :where(html[data-theme='lightning']) & {
    --whatsnew-close-icon-color: var(--lightning--blue-gray-400);

    color: var(--whatsnew-close-icon-color, var(--lightning--gray-500));

    &:hover {
      color: var(--lightning--blue-gray-200);
    }

    &:active {
      color: var(--lightning--blue-gray-500);
    }
  }
}
