/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.drawer {
  display: flex;
  flex-direction: column;
}

.text {
}

.secondaryText {
  composes: light from 'utils.css';
  font-style: italic;
  text-align: right;
}

.handle {
  display: flex;
  align-items: center;
  padding-block: var(--6px);
  padding-inline: var(--2px) var(--4px);
  color: var(--text-color);
  cursor: pointer;
  user-select: none;
  border-radius: var(--4px);

  &.condensed {
    align-self: flex-start;
  }

  :where(html[data-theme='lightning']) & {
    &:hover {
      color: var(--lightning--blue-gray-900);
      background: var(--lightning--blue-gray-50);
    }

    &:focus-visible {
      outline: var(--lightning--blue-200) solid var(--2px);

      /* The outline is invisible on press */
      &:active {
        outline-color: transparent;
      }

      &:active {
        color: var(--lightning--blue-gray-900);
      }
    }
  }
}

.innerHandle {
  composes: gapLarge gapHorizontal from 'utils.css';
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.icon {
  font-size: var(--11px);
}
