/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.wrapper {
  --breadcrumbs-height: var(--16px);

  composes: gapSmall gapHorizontal centerFlexAlign from 'utils.css';
  width: 100%;
  line-height: var(--breadcrumbs-height);

  :where(html[data-theme='lightning']) &:not(.link) {
    color: var(--lightning--blue-gray-600);
  }
}

.item {
  display: flex;
}

.itemContent {
  white-space: nowrap;
}

.name {
  font-weight: var(--fontWeight);
}

.separator-icon {
  font-size: 0.7em;
}

.breadcrumbCollapsed-button {
  &.breadcrumbCollapsed-styled {
    height: var(--breadcrumbs-height);
    min-height: auto;
    padding-inline: var(--offset-small);
  }
}
