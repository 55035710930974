/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.container {
  display: flex;
  flex-wrap: wrap;
}

.side {
  flex-basis: 50%;
}

.colHeader {
  font-weight: var(--fontWeightBold);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-700);
  }
}

.notifications {
  margin: 0 var(--2px) var(--14px);
}

.suggestedLabelsCheckbox-checkbox {
  margin-top: var(--15px);
  margin-bottom: var(--15px);
}
