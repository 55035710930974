/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.label {
  font-weight: var(--fontWeightBold);
  text-anchor: middle;
  dominant-baseline: central;
}

.outerCircle {
  fill: transparent;
  stroke-opacity: 0.2;
}

.progressIndicator {
  fill: transparent;
}

.green {
  :where(html[data-theme='lightning']) & {
    stroke: var(--lightning--green-400);
  }
}

.greenLabel {
  :where(html[data-theme='lightning']) & {
    fill: var(--lightning--green-700);
  }
}

.green-gradient-shallow {
  stop-color: rgb(0 150 32);
}

.green-gradient-deep {
  stop-color: rgb(93 218 49);
}

.orange {
  :where(html[data-theme='lightning']) & {
    stroke: var(--lightning--orange-400);
  }
}

.orange-gradient-shallow {
  stop-color: rgb(242 104 0);
}

.orange-gradient-deep {
  stop-color: rgb(255 168 0);
}

.orangeLabel {
  :where(html[data-theme='lightning']) & {
    fill: var(--lightning--orange-700);
  }
}

.red {
  :where(html[data-theme='lightning']) & {
    stroke: var(--lightning--red-400);
  }
}

.red-gradient-shallow {
  stop-color: rgb(202 4 0);
}

.red-gradient-deep {
  stop-color: rgb(255 27 68);
}

.redLabel {
  :where(html[data-theme='lightning']) & {
    fill: var(--lightning--red-700);
  }
}
