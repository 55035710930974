/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.container {
  display: flex;
  align-items: center;
  height: var(--40px);
  column-gap: var(--5px);
}

.label {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-800);
  }
}

.selectorContainer {
  position: relative;
}

.footer {
  display: flex;
  justify-content: end;
}

.beforeIcon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--orange-400);
  }
}

.filterSelected {
  .text {
    :where(html[data-theme='lightning']) & {
      border-bottom: var(--2px) solid var(--lightning--blue-500);
    }
  }
}

.optionsContainer {
  padding-left: var(--24px);
}

.title {
  composes: semiBold from 'utils.css';
  padding-bottom: var(--5px);
  text-transform: uppercase;
}

.divider {
  margin: var(--10px) 0;

  :where(html[data-theme='lightning']) & {
    border-top: 1px solid var(--lightning--blue-gray-200);
  }
}

.gap {
  height: var(--20px);
}

.dropdownContainer {
  min-width: calc(3 * var(--100px));
  padding: var(--10px);
  box-shadow: var(--shadow-depth-3);

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--white);
  }
}

.selectAll-checkboxContent,
.filterOption-checkboxContent {
  padding: var(--4px);
}

.selectAll-labelText,
.selectAll-labelText:hover {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-700);
  }
}

.filterPill-pill {
  margin-right: var(--4px);
}

.downIcon-icon {
  justify-self: flex-end;
}

.pillsContainer {
  display: flex;
  flex-grow: 1;
  column-gap: var(--5px);
}

.placeholderText {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
  }
}
