/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

/* If content should get out of default page's padding */
.containerWidth {
  width: calc(100% + var(--page-horizontal-padding) * 2);
  margin-left: calc(-1 * var(--page-horizontal-padding));
}

/* Fix jumping width, for example counter, using https://twitter.com/wesbos/status/932644812582522880 */
.monoSpace {
  /* Enable tabular (monospaced) figures */
  font-feature-settings: 'tnum';
  /* Activate the set of figures where numbers are all of the same size, */
  /* allowing them to be easily aligned like in tables. It corresponds to the tnum. */
  font-variant-numeric: tabular-nums;
}

/* In browsers if font-weight: 300, bolder is equal to 400, but we want 500 and also have ability to set 400 */
/* So introduce custom composition helpers to override default browser heuristic on resolving 'bolder' values: */
/* When font-weight: 400, .bold equals to 600, .semiBold equals to 500, .light equals to 300 */
/* When font-weight: 300, .bold equals to 500, .semiBold equals to 400, .light equals to 200 */
/* To use in css-modules, add 'composes: bold from 'utils.css';' into your class declaration */
.bold {
  font-weight: var(--fontWeightBold);
}
/* To use in css-modules, add 'composes: semiBold from 'utils.css';' into your class declaration */
.semiBold {
  font-weight: var(--fontWeightSemiBold);
}
/* To use in css-modules, add 'composes: light from 'utils.css';' into your class declaration */
.light {
  font-weight: var(--fontWeightLight);
}
/* Needed only if you want to override previous one set by parent */
.normal {
  font-weight: var(--fontWeight);
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inlineBlock {
  display: inline-block;
}

.inlineFlex {
  display: inline-flex;
}

/* Different types of white-space */
.breakSpaces {
  white-space: break-spaces;
}

.pre {
  white-space: pre;
}

.preWrap {
  white-space: pre-wrap;
}

.noWrap {
  white-space: nowrap;
}

/* Old/new helper classes for red strikethrough or green text */
.old {
  text-decoration: line-through;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-600);
  }
}

.new {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-600);
  }
}

/* Fixed element that covers a whole screen */
.fixedCurtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* show ... when text overflow */
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ellipsisLines {
  --ellipsis-lines: 2;

  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  white-space: initial;
  overflow-wrap: break-word;
  overflow: hidden;
  -webkit-line-clamp: var(--ellipsis-lines, 2);
  -webkit-box-orient: vertical;
}

/* Two helper classes to control pointer sensitivity */
.insensitive {
  pointer-events: none;
}

.sensitive {
  pointer-events: auto;
}

/* TODO: clean up when we can use @layer  */
.globalInsensitive {
  user-select: none;
  pointer-events: none;
  * {
    user-select: none;
    pointer-events: none;
  }
}

/* fake :not to increase the specificity */
.globalSensitive:not(.non-existing-ghost-selector) {
  user-select: auto;
  pointer-events: auto;
  * {
    user-select: auto;
    pointer-events: auto;
  }
}

/* Two helper classes to control visibility */
.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

/* Helper classes and global variables to control gap between children elements */
:root {
  --gap-row-xsmall: var(--offset-xsmall);
  --gap-column-xsmall: var(--offset-xsmall);
  --gap-row-small: var(--offset-small);
  --gap-column-small: var(--offset-small);
  --gap-row-medium: var(--offset-medium);
  --gap-column-medium: var(--offset-medium);
  --gap-row-large: var(--offset-large);
  --gap-column-large: var(--offset-large);
  --gap-row-x-large: var(--offset-x-large);
  --gap-column-x-large: var(--offset-x-large);
  --gap-child-flex: initial; /* This is equivalent to setting '0 1 auto' */
  --albatrossBreakpoint: calc(var(--100px) * 11);
}

.gap {
  display: flex;
  flex-direction: column;
  gap: var(--gap-row, 0) var(--gap-column, 0);

  /* Flex property is set by variable to be able to easily change flex configuration on consumer side by just changing the variable */
  > * {
    flex: var(--gap-child-flex);
  }
}

.gapXLarge {
  --gap-row: var(--gap-row-x-large);

  composes: gap;

  &.gapHorizontal {
    --gap-column: var(--gap-column-x-large);
  }
}

.gapLarge {
  --gap-row: var(--gap-row-large);

  composes: gap;

  &.gapHorizontal {
    --gap-column: var(--gap-column-large);
  }
}

.gapMedium {
  --gap-row: var(--gap-row-medium);

  composes: gap;

  &.gapHorizontal {
    --gap-column: var(--gap-column-medium);
  }
}

.gapSmall {
  --gap-row: var(--gap-row-small);

  composes: gap;

  &.gapHorizontal {
    --gap-column: var(--gap-column-small);
  }
}

.gapXSmall {
  --gap-row: var(--gap-row-xsmall);

  composes: gap;

  &.gapHorizontal {
    --gap-column: var(--gap-column-xsmall);
  }
}

.gapInline {
  display: inline-flex;
}

.gapContents {
  display: contents;
}

.gapWrappable {
  flex-wrap: wrap;
}

.gapHorizontal {
  flex-direction: row;
  align-items: stretch;
}

.gapHorizontalWrap {
  composes: gapHorizontal gapWrappable;
}

.gapAlbatross {
  --gap-child-flex: 0 0 auto;

  composes: gapHorizontalWrap;

  /*
    Flexbox Albatross
    http://www.heydonworks.com/article/the-flexbox-holy-albatross
    https://snook.ca/archives/html_and_css/understanding-the-flexbox-albatross
    The breakpoint is around ~1100px, the modifier is 9999, which should stay high

    We can probably replace Albatross with Container Queries starting with Firefox 110+, Safari 16+
   */
  > * {
    flex-grow: 1;
    flex-basis: calc(var(--albatrossBreakpoint) * 9999 - 100% * 9999);
    max-width: 100%;
  }
}

.gapAlignStart {
  align-items: flex-start;
}

.gapAlignBaseline {
  align-items: baseline;
}

/* Center content of a box vertically and horizontally */
.centerFlexContent {
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centerFlexAlign {
  align-items: center;
}

.centerMarginBlock {
  margin-block: auto;
}

.centeredFlex {
  composes: centerFlexContent;
  display: flex;
}

.centeredInlineFlex {
  composes: centerFlexContent inlineFlex;
}

/* Empty item to break flex line */
.flexLineBreak,
.gap > .flexLineBreak {
  flex: 1 1 100%;
}

/* Helper classes to control elements padding */
.paddingXSmallTop {
  padding-top: var(--offset-xsmall);
}

.paddingXSmallBottom {
  padding-bottom: var(--offset-xsmall);
}

.paddingSmallTop {
  padding-top: var(--offset-small);
}

.paddingSmallBottom {
  padding-bottom: var(--offset-small);
}

.paddingSmallLeft {
  padding-left: var(--offset-small);
}

.paddingSmallRight {
  padding-right: var(--offset-small);
}

.paddingMediumTop {
  padding-top: var(--offset-medium);
}

.paddingMediumBottom {
  padding-bottom: var(--offset-medium);
}

.paddingMediumLeft {
  padding-left: var(--offset-medium);
}

.paddingMediumRight {
  padding-right: var(--offset-medium);
}

.paddingLargeTop {
  padding-top: var(--offset-large);
}

.paddingLargeBottom {
  padding-bottom: var(--offset-large);
}

.paddingLargeLeft {
  padding-left: var(--offset-large);
}

.paddingLargeRight {
  padding-right: var(--offset-large);
}

.scrollLock {
  /* stylelint-disable-next-line declaration-no-important -- We are using !important, because we want to override any possible, overflow, inline styles whenever this class is used. */
  overflow: hidden !important;
  scrollbar-gutter: stable;
}

.secondary {
  composes: light;
  font-size: var(--12px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}
