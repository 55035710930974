/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

:root {
  :where(html[data-theme='lightning']) & {
    --map-pagination-page-current-button-background-color: var(--lightning--blue-gray-400);
    --map-pagination-page-current-button-color: var(--lightning--white);
    --map-pagination-container-border-color: var(--lightning--gray-200);
  }
}

.container {
  display: flex;
  align-items: center;
  padding: 0 var(--6px);

  :where(html[data-theme='lightning']) & {
    --map-pagination-page-current-button-background-color: var(--lightning--blue-400);
  }
}

.blocks {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  align-items: stretch;
  gap: var(--2px);
}

.block {
  /* for blocks without a button, style the block */
  display: flex;
  border-radius: var(--2px);

  /* for blocks with a button, style the button inside the block */
  .button {
    display: flex;
    padding: var(--2px) var(--8px);
    color: var(--map-toolbar-button-color);
    border-radius: var(--2px);
  }

  &.current {
    background-color: var(--map-pagination-page-current-button-background-color);

    .button {
      cursor: default;
      border-radius: var(--2px);

      :where(html[data-theme='lightning']) & {
        color: var(--lightning--white);
      }

      &:hover {
        border: none;
        background-color: var(--map-pagination-page-current-button-background-color);
      }
    }
  }

  &.disabled:not(.current) {
    .button:hover {
      background-color: transparent;
    }
  }
}

.pageBlock .button {
  position: relative;
}

.pageBlock.current .pageNumber {
  position: relative;
  z-index: 1; /* currentPage needs z-index to sit on top of the button's progress indicator */
}

.nextPrevBlock .icon {
  font-size: var(--8px);
}

.ellipsisBlock {
  padding: 0 var(--4px);
}

.asteriskBlock {
  width: auto;
  padding: 0 var(--6px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-700);
  }

  &:hover {
    cursor: pointer;

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-900);
    }
  }
}
