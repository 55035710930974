/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.menu {
  --menu-width: calc(4 * var(--70px));
  --menu-width-collapsed: var(--30px);
  --item-height: var(--32px);
  --item-padding-left: var(--8px);
  --item-border-left: var(--4px);

  position: relative;
  z-index: 103;
  width: var(--menu-width);
  min-width: var(--menu-width);
  min-height: 100%;
  box-shadow: var(--shadow-depth-6);
  transition: none 300ms ease;
  transition-delay: -100ms;
  transition-property: width, min-width;

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-200);

    &:focus {
      outline: none;
    }

    &:focus-visible:not(:active) {
      /* Add custom focus outline */
      box-shadow: 0 0 3px 3px rgb(var(--lightning--blue-400-rgb) / 0.5);
    }
  }
}

.collapsed {
  width: var(--menu-width-collapsed);
  min-width: var(--menu-width-collapsed);
  box-shadow: none;
}

.hideContent {
  display: none;
}

.header-toolBar {
  composes: bold from 'utils.css';
  position: relative;
  min-width: max-content;
  height: var(--item-height);
  padding-left: calc(var(--item-padding-left) + var(--item-border-left));

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-700);
  }
}

.caret {
  .icon {
    display: inline-block;
    margin-right: var(--10px);
    font-size: var(--12px);
    cursor: pointer;
  }

  .icon:hover {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-600);
    }
  }
}

.openCaret {
  composes: caret;
  .icon {
    transform: rotate(90deg);
  }
}

.closedCaret {
  composes: caret;
  .icon {
    transform: rotate(-90deg);
  }
}

.container {
  :where(html[data-theme='lightning']) & {
    border-top: 1px solid var(--lightning--blue-gray-400);
  }
}

.container:last-child {
  :where(html[data-theme='lightning']) & {
    border-bottom: 1px solid var(--lightning--blue-gray-400);
  }
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: var(--item-height);
  cursor: pointer;

  &:focus {
    :where(html[data-theme='lightning']) & {
      outline: none;
      background-color: var(--lightning--blue-gray-300);
    }
  }
}

.itemText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.selected {
  pointer-events: none;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--white);
    background-color: var(--lightning--blue-gray-600);
  }
}

.category {
  composes: item;
  padding-left: var(--item-padding-left);
  border-left: var(--item-border-left) solid transparent;

  .icon {
    margin-right: var(--10px);
    font-size: var(--12px);
  }
}

.active {
  :where(html[data-theme='lightning']) & {
    border-left-color: var(--lightning--blue-400);
  }
}

.subcategory {
  composes: item;
  padding-left: var(--20px);
}

.number {
  padding-right: var(--10px);
}

.stickyMenu {
  position: sticky;
  top: var(--header-height);
}

.menuScroller {
  max-height: calc(100vh - var(--header-height) - 2 * var(--grid-manager-height));
  overflow-y: auto;
}
