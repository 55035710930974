/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.more {
  composes: link from 'components/Link/Link.css';
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

/* Items are rendered horizontally */
.list {
  composes: gapXSmall gapHorizontalWrap from 'utils.css';
}

/* Groups are rendered vertically */
.listOfGroups {
  composes: gapSmall from 'utils.css';
}

.multipleGroups {
}
