/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

.icon {
  font-size: 1em;
}

.before {
  margin-right: var(--6px);
}

.after {
  margin-left: var(--6px);
}

.inbetween {
  composes: before after;
}

.hidden {
  visibility: hidden;
}

.svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
  pointer-events: none;
  fill: currentcolor;
}
