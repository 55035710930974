/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.container {
  flex-grow: 1;
  padding-top: var(--8px);
}

.toolBar {
  padding: 0 var(--6px) var(--6px) var(--6px);
}

.picker {
  width: 180px;
}

.optionDropdown {
  overflow: scroll;
}

.icon {
  width: 2em;
  height: 2em;
  font-size: 1.6em;
}

/* ProviderOrConsumerPortProcess are the column name in the grid config */

.areaHead {
  .providerIP-customCellHeader,
  .providerPortProcess-customCellHeader,
  .ToProviderPortProcess-customCellHeader {
    flex: 1 1 auto;

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-900);
      background-color: var(--lightning--purple-200);
    }

    &:hover {
      :where(html[data-theme='lightning']) & {
        background-color: var(--lightning--purple-300);
      }
    }
  }
}

.notifications {
  margin: 0 var(--6px) var(--8px) var(--6px);
}
