/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
.displayFlex {
  display: flex;
}

.keyWithPadding {
  min-width: var(--96px); /* minimum for labels containing short data e.g. "eth0" to appear aligned right */
}
