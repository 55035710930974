/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.installed {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-800);
  }
}

.missing {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-800);
  }
}
