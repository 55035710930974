/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
.selectedHeader {
  composes: semiBold from 'utils.css';
  display: flex;
  padding: var(--3px) 0;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
    border-bottom: 1px solid var(--lightning--blue-gray-200);
  }
}

.selectedRow {
  display: flex;
  padding: var(--3px) 0;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-900);
  }

  + .selectedRow {
    :where(html[data-theme='lightning']) & {
      border-top: 1px solid var(--lightning--blue-gray-200);
    }
  }
}

.selectedNumber {
  composes: bold from 'utils.css';
  min-width: var(--60px);
  text-align: center;
}

.selectedDes {
  width: calc(5 * var(--100px));
  padding: 0 var(--20px);
}

.selectedType {
  min-width: calc(2.25 * var(--100px));
  max-width: calc(5 * var(--100px));
  padding: 0 var(--20px);
}

.selectedAction {
  padding-bottom: var(--5px);
}

.radioGroupTitle {
  composes: bold from 'utils.css';
}

.formAttributes-list {
  margin-top: 0;
}

.form-radio {
  padding: var(--10px) var(--15px);

  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--lightning--blue-gray-200);
    background: var(--lightning--blue-gray-50);
  }

  .formAttributes-key {
    padding-left: var(--1px);
  }
}

.close-icon {
  margin: 0 var(--10px);
  padding: var(--5px);
  font-size: var(--8px);
  cursor: pointer;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }

  &:hover {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--black);
    }
  }
}
