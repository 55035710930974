/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.displayFlex {
  display: flex;
}

.enabledContainerItems {
  /* composes: displayFlex; */
  flex-grow: 1;
  > input {
    width: 100%;
  }
}

.enabledContainer {
  composes: displayFlex;
  flex-grow: 1;
}

.complexRadioSubInputDivider {
  margin-right: var(--10px);
}

.complexRadioContainer {
  composes: displayFlex;
  width: 70%; /* need to adjust with width for the AttributeList value container */
}

.formUL {
  width: 70%;
  padding: 0;
  list-style-type: none;
}

.inputContainer {
  flex-basis: 57%;
}

.valueContainer {
  display: flex;
  width: 70%;
}

.enableMainItems {
  flex-grow: 1;
}

.mainContainer {
  display: flex;

  &.normal {
    width: 70%;
  }

  &.split {
    width: 50%;
  }

  .container {
    flex-grow: 1;
    min-width: 0; /* need for ellipsis overflow */
  }
}

.spacer {
  margin-right: var(--10px);
}

.inputContainerComplex {
  margin-bottom: var(--30px);
}

.fieldTextarea {
  :where(html[data-theme='lightning']) & {
    border: 3px solid var(--lightning--green-300);
  }
}

/* e.g. themePrefix="base-" */
.base-error {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--orange-300);
  }
}

.asterisk {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--teal-300);
  }
}

.baseUrl-formUL {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--gray-300);
  }
}

.options {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--red-200);
  }

  &:focus {
    background: #00acc1ff;
  }
}

.dropdown {
  width: 100%;
}

.checkboxes-list {
  margin-top: calc(-1 * var(--20px));
}

.checkboxHeader {
  display: flex;
  align-items: flex-end;
  margin-bottom: calc(-1 * var(--12px));
  user-select: none;

  > div {
    width: var(--14px);
    margin-right: var(--14px);
    padding-left: var(--2px);
    font-size: var(--10px);
    white-space: nowrap;
    transform: scale(-1, -1) translate(calc(-1 * var(--14px)), -100%) rotate(45deg);
    transform-origin: top left;
    writing-mode: vertical-lr;
    text-orientation: mixed;

    &::after {
      position: absolute;
      content: '';
      top: 4%;
      left: calc(-1 * var(--1px));
      width: var(--1px);
      height: 96%;
      box-shadow: 0.5px 0 0 0 var(--lightning--blue-gray-300);
    }
  }
}
