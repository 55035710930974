/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.page {
  --versionmismatch-padding: var(--40px);

  @media screen and (width < 960px) {
    --versionmismatch-padding: 0;
  }
}

.page {
  padding: var(--30px) var(--versionmismatch-padding);
}

.notifications-iconItem {
  padding: 0 var(--20px);
  font-size: var(--40px);
}

.notifications-mainItem {
  padding-top: var(--16px);
  padding-bottom: var(--16px);
}

.notifications-titleWithMessage {
  composes: normal from 'utils.css';
  font-size: var(--16px);
}

.notifications-message {
  margin-top: var(--10px);
}

.versions {
  composes: bold from 'utils.css';
  margin-top: var(--10px);
  font-size: var(--16px);

  > div {
    display: inline-block;

    &:not(:last-child) {
      margin-right: var(--10px);
    }
  }
}

.version {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-700);
  }
}

.upgradeInfo {
  font-size: var(--16px);
  text-align: center;
}

.upgradeText {
  margin: var(--40px) 0 var(--10px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-900);
  }
}
