/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
}

.row p:first-child {
  flex: 1 0 90%;
  margin: 0;
}

.row p:last-child {
  flex-basis: 10%;
  margin: 0;
}

.row + .row {
  margin-top: var(--10px);
}

.divider {
  margin: var(--10px) 0;
  border: none;
  border-top: 1px solid var(--gray-100);
}

.clickable {
  color: var(--blue-500);
  cursor: pointer;
}
