/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_980 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.status-badge {
  padding: var(--2px) var(--10px);
  line-height: 1.2;
}

.service-badge {
  height: var(--15px);
  margin-top: var(--5px);
}

.table-minWidth_800 {
  .status-badge {
    margin: var(--4px) 0;
  }

  .areaBody .cell.status {
    padding-top: var(--8px);
    padding-bottom: 0;
  }
}

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.button-textIsHideable {
  @media (width <= 860px) {
    display: none;
  }
}

/* providers and providingServices are the names of the property in Grid config */
.areaHead {
  :where(html[data-theme='lightning']) & {
    .providers-customCellHeader,
    .providingServices-customCellHeader {
      color: var(--lightning--blue-gray-900);
      background-color: var(--lightning--purple-100);

      &:hover {
        background-color: var(--lightning--purple-200);
      }
    }
  }
}

.areaBody .cell.arrow {
  /* Align with the Pill */
  line-height: var(--24px);
}

.svg {
  --enfB-icon-transform: scale(2);

  color: var(--enfB-icon-color);
  transform: var(--enfB-icon-transform);

  &.svgArrow {
    --enfB-icon-transform: scale(1);
  }

  &.svgDown {
    --enfB-icon-transform: rotate(90deg) scale(1.5);
  }

  &.svgUp {
    --enfB-icon-transform: rotate(270deg) scale(1.5);
  }

  &.svgLeft {
    --enfB-icon-transform: rotate(180deg) scale(2);
  }

  :where(html[data-theme='lightning']) & {
    --enfB-icon-color: var(--lightning--blue-gray-700);
  }
}

.rowToEnable .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-200);
  }
}
