/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.menuHeading {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}

.menuOptionText {
  font-weight: var(--fontWeightBold);
}
