/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.serviceListPorts {
  font-size: var(--12px);
}
