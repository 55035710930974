/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.container {
  display: flex;
  flex-direction: column;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: var(--200px);
  font-size: var(--13px);
  border: 1px solid rgb(222 224 227 / 1);
  border-radius: var(--3px);
  box-shadow: 0 1px 6px 1px rgb(0 0 0 / 0.3);

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--white);
  }
}

.group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: var(--7px) var(--6px);
  list-style: none;
}

.group label {
  margin-left: var(--6px);
  padding: 10 var(--0px);
  font-weight: var(--fontWeightBold);
  line-height: var(--16px);
  color: rgb(44 60 77);
}

.groupItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--5px) var(--16px) var(--6px) var(--16px);
  text-align: start;
  cursor: pointer;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-900);
  }
}

.groupItem:hover {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-200);
  }
}

.groupItem:hover label {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-700);
  }
}

.groupItem:hover .filterIcon {
  width: 14px;
  height: 14px;
  font-size: var(--13px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-700);
  }
}

.groupItem .filterIcon {
  display: flex;
  align-items: center;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-200);
  }
}

.groupItem button {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  padding: 0;
  border: 0;
  background: transparent;
}

.groupItem label {
  flex: 1 0 auto;
  width: 100%;
  text-align: start;
  white-space: nowrap;
  cursor: pointer;
  pointer-events: inherit;
}

.line {
  width: 100%;
  height: var(--1px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-300);
  }
}
