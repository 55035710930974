/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.list {
  margin-top: 0;
  .value {
    white-space: pre-wrap;
    overflow: auto;
  }
}

.card {
  padding: 0 var(--10px);
}

.on {
  composes: bold from 'utils.css';

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-900);
  }
}

.off {
  composes: bold from 'utils.css';

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-800);
  }
}
