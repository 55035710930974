/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.formFields {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: var(--8px);
  column-gap: var(--8px);
  row-gap: var(--8px);
}

.fieldValidRange,
.labelContainer {
  display: flex;
  align-items: center;
  font-size: var(--12px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-500);
  }
}

.labelContainer {
  justify-content: flex-end;
}

.modalActions {
  display: flex;
  column-gap: var(--10px);
}

.maxConnectionsTitle {
  font-weight: var(--fontWeightBold);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}
