/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.content {
  font-size: var(--13px);
  font-weight: var(--fontWeight);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}
