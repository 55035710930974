/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.PCEHealthMessages {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}

.list > .row {
  > .key {
    min-width: calc(var(--98px) * 2);
  }

  > .value {
    display: flex;
  }
}

.HealthDetailLink {
  cursor: pointer;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-900);
  }
}

.NodeHealthHeader {
  padding-bottom: var(--5px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-400);
  }
}

.NodeHealthHeader,
.NodeHealthBody {
  width: calc(var(--100px) * 3);
  margin-bottom: calc(var(--5px) * -1);
  padding-right: var(--10px);

  :where(html[data-theme='lightning']) & {
    border-bottom: var(--1px) solid var(--lightning--blue-gray-200);
  }
}

.NodeHealthBodyItems {
  overflow-x: hidden;

  > span {
    display: block;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  :not(:last-child) {
    margin-bottom: var(--4px);
  }
}

.NodeHealthBodyRed {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-600);
  }
}

.NodeHealthHeaderHostname {
  composes: bold from 'utils.css';

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-800);
  }
}

.stackedSection {
  margin-top: var(--10px);

  .list > .row {
    margin-top: 0;

    > .key {
      float: left;
    }
  }

  .NodeHealthHeader {
    width: 100%;
    margin-top: var(--10px);
    padding: var(--14px);
    border-bottom: none;

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-600);
      background-color: var(--lightning--blue-gray-50);
    }

    .NodeHealthHeaderHostname {
      :where(html[data-theme='lightning']) & {
        color: var(--lightning--gray-900);
      }
    }
  }

  .list > .row > .key:not(:empty),
  .NodeHealthBody {
    width: 100%;
    min-height: 3.5rem;
    padding: var(--14px);

    /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
    :where(html[data-theme='lightning']) & {
      border-bottom: var(--1px) solid var(--lightning--blue-gray-200);
    }
  }

  .verticalEntries {
    width: 100%;
  }

  .value > .horizontalValues {
    padding: var(--14px);
  }

  .value > div:not(.verticalEntries),
  .value > span {
    padding: var(--14px);
  }
}

.HealthDetailOne {
  .NodeHealthHeader:not(.metricHeader) {
    display: none;
  }

  .NodeHealthBody {
    padding: 0;
    border-bottom-color: transparent;
  }

  /* Show it under PCE Service Alerts section */
  .NodeHealthServicesDetails .NodeHealthHeader {
    display: inline;
  }
}

.NodeHealthServicesDetails {
  margin-bottom: var(--10px);
  opacity: 1;
  transform-origin: top;
  transition:
    opacity 250ms ease-in,
    transform 250ms ease-in;

  /* The title "PCE Service", although a key of AttributeList, is formatted differently here */
  .list > .row:first-child > .key {
    padding-top: var(--40px);

    /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--gray-400);
    }
  }
}

.NodeHealthServicesDetailsHide {
  height: calc(var(--100px) * 1.5);
  opacity: 0;
  transform: scaleY(0);
}

.PCEHealthMessages,
.HealthDetailSuperclusterReplicationLagItem {
  padding-bottom: var(--10px);
}

.HealthMultiDiskNodeUsage {
  .NodeHealthBody:not(:last-child) {
    border-bottom-color: transparent;
  }
}

.noTextColor {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-900);
  }
}

.metricHeader {
  composes: NodeHealthHeader NodeHealthHeaderHostname;
}

.metricBody {
  composes: NodeHealthBody;
  display: inline-block;
}

.messages {
  word-break: break-word;
  overflow-wrap: break-word;
}

.hideKey {
  display: none;
}

.verticalEntries {
  display: flex;
  flex-flow: column;

  > div:not(.verticalEntries) {
    padding-bottom: var(--offset-small);
  }
}

.tabIcon {
  margin-left: var(--5px);
}

.list {
  --l-row-gap: var(--10px);
}

.key {
  .help-icon {
    display: none;
    cursor: pointer;
  }

  &:hover > .help-icon {
    display: inline;
  }
}
