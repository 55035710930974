/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

:root {
  /* Variable --input-line-height is used by FormLabel and input and textArea */
  --inputAndtextArea-line-height: var(--22px);
  --inputAndtextArea-vertical-padding: var(--4px);
  --input-height: calc(
    2 * var(--form-input-border-width) + 2 * var(--inputAndtextArea-vertical-padding) +
      var(--inputAndtextArea-line-height)
  );
  --icon-padding-left: var(--10px);
  --textRightOfIcon: var(--25px);

  :where(html[data-theme='lightning'])& {
    --input-placeholder-color: var(--lightning--blue-gray-500);
    --input-placeholder-hover-color: unset;
  }
}

.wrapper {
  position: relative;
}

.input {
  display: block;
  width: 100%;
  padding: var(--inputAndtextArea-vertical-padding) var(--12px);
  font-size: var(--form-input-text);
  line-height: var(--inputAndtextArea-line-height);
  border: var(--form-input-border);
  border-radius: var(--8px);

  &:focus {
    outline: none;
  }

  &:focus-visible {
    border-color: var(--form-focus-border-color);

    :where(html[data-theme='lightning']) & {
      background: var(--lightning--white);
    }
  }

  &:disabled {
    :where(html[data-theme='lightning']) & {
      background: var(--lightning--blue-gray-50);
    }
  }

  &.withIcon {
    padding-top: var(--8px);
    padding-bottom: var(--8px);
    padding-left: calc(var(--icon-padding-left) + var(--textRightOfIcon));
  }

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-900);
    border-color: var(--lightning--gray-300);

    &::placeholder {
      color: var(--input-placeholder-color);
    }

    &:hover {
      border-color: var(--lightning--blue-200);

      &::placeholder {
        color: var(--input-placeholder-hover-color);
      }
    }

    &:active {
      border-color: var(--lightning--blue-400);
    }

    &:focus {
      border-color: var(--lightning--blue-400);
      box-shadow: 0 0 0 1px var(--lightning--blue-50);
    }

    &.error:not(:disabled) {
      border-color: var(--lightning--red-400);

      &:hover {
        border-color: var(--lightning--red-300);
      }

      &:active {
        border-color: var(--lightning--red-500);
      }

      &:focus {
        box-shadow: 0 0 0 1px var(--lightning--red-50);
      }
    }

    &.success:not(:disabled) {
      border-color: var(--lightning--green-500);

      &:hover {
        border-color: var(--lightning--green-300);
      }

      &:active {
        border-color: var(--lightning--green-500);
      }

      &:focus {
        box-shadow: 0 0 0 1px var(--lightning--green-50);
      }
    }

    &.warn:not(:disabled) {
      border-color: var(--lightning--orange-500);

      &:hover {
        border-color: var(--lightning--orange-300);
      }

      &:active {
        border-color: var(--lightning--orange-500);
      }

      &:focus {
        box-shadow: 0 0 0 1px var(--lightning--orange-50);
      }
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: border-color 0.15s linear;
  }
}

.icon-icon {
  composes: centeredFlex from 'utils.css';
  position: absolute;
  top: 0;
  left: var(--6px);
  height: var(--input-height);
  padding-block-start: var(--6px);
  padding-inline-start: var(--icon-padding-left);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-700);
  }
}

/* Utility class to align content vertically with the middle of the input */
.inputAligned {
  display: inline-flex;
  align-items: center;
  height: calc(var(--input-height) - var(--1px));
}

.message {
  margin-top: var(--offset-xsmall);
}
