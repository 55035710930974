/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.expanded {
  .svg {
    width: 45%;
    height: var(--28px);
    padding-left: var(--offset-small);
  }
}

.collapsed {
  .svg {
    width: 100%;
    height: var(--24px);
  }
}

.logosContainer {
  margin-top: var(--offset-medium);
  margin-bottom: var(--offset-small);
  padding: 0 var(--offset-medium);
}

.logosContainerCollapsed {
  margin-top: var(--offset-large);
}
