/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.radio {
  position: relative;
  display: inline-flex;
  align-items: baseline; /* To align radio with the first line of an optional label */
}

.hidden {
  .box {
    visibility: hidden;
  }
}

.noText::before {
  /* In case of lonely radio without a text add unicode zero width space character to keep inline height */
  content: '\200b';
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:focus-visible ~ .box {
    outline-offset: var(--1px);

    :where(html[data-theme='lightning']) & {
      outline: var(--radio-outline-color) solid var(--2px);
    }
  }
}

.labelBox,
.labelText,
.labelSubText {
  cursor: pointer;
  outline: none;
  user-select: none;
}

.labelBox {
  position: relative;
}

.labelText,
.labelSubText {
  display: block;
  font-size: var(--13px);
}

.side {
  flex: 1 1 auto;
}

.nestedElements {
  composes: gapLarge paddingMediumTop from 'utils.css';
}

.labelText,
.labelSubText,
.nestedElements {
  padding-right: var(--14px);
  padding-left: var(--5px);
}

/* Circular box */
.box {
  --box-size: var(--15px);
  --box-border-width: var(--1px);
  --box-fill-size: 0;
  --box-fill-full-size: calc(var(--box-size) / 2);
  --box-filled-border-width: var(--5px);

  composes: centeredFlex from 'utils.css';
  position: relative;
  display: inline-flex;
  width: var(--box-size);
  height: var(--box-size);
  vertical-align: -0.175em;
  border-radius: 50%;
  box-shadow:
    inset 0 0 0 var(--box-border-width) var(--box-border-color),
    inset 0 0 0 var(--box-fill-size) var(--box-fill-color);
  background-color: var(--box-background-color);

  :where(html[data-theme='lightning']) & {
    --box-border-color: var(--lightning--blue-gray-300);
    --box-border-color-hover: var(--lightning--blue-500);
    --box-border-color-active: var(--lightning--blue-700);
    --box-border-color-error: var(--lightning--red-500);
    --box-border-color-disabled: var(--lightning--gray-400);
    --box-fill-color: var(--lightning--blue-600);
    --box-fill-color-disabled: var(--lightning--gray-100);
    --box-filled-border-color: var(--lightning--blue-500);
    --box-filled-border-color-hover: var(--lightning--blue-300);
    --box-filled-border-color-active: var(--lightning--blue-400);
    --box-filled-border-color-disabled: var(--lightning--gray-100);
    --box-filled-fill-color-disabled: var(--lightning--gray-500);
    --box-background-color: var(--lightning--white);
  }

  /* When radio selector doesnt' have checked, insensitive and disabled classes during a hover.
     It is possible that radio is disabled, not checked but allow mouse event.
     .insensitive uses 'pointer-events: none' to disallow mouse event but in some cases
     mouse event is needed even though radio is disabled and not checked */
  .radio:not(.checked, .insensitive, .disabled):hover & {
    --box-border-color: var(--box-border-color-hover);
  }

  .radio:not(.checked, .insensitive, .disabled):active & {
    --box-border-color: var(--box-border-color-active);
  }

  .radio &.boxFilled {
    --box-border-width: var(--box-filled-border-width);
    --box-border-color: var(--box-filled-border-color);

    &:hover {
      --box-border-color: var(--box-filled-border-color-hover);
    }

    &:active {
      --box-border-color: var(--box-filled-border-color-active);
    }
  }

  .radio.error:not(:hover) & {
    --box-border-width: var(--1px);
    --box-border-color: var(--box-border-color-error);
  }

  .radio.disabled & {
    --box-border-color: var(--box-border-color-disabled);
    --box-fill-color: var(--box-fill-color-disabled);
    --box-fill-size: var(--box-fill-full-size);
    --box-border-width: var(--1px);
  }

  .radio.disabled &.boxFilled {
    --box-fill-color: var(--box-filled-fill-color-disabled);
    --box-fill-size: var(--box-fill-full-size);
    --box-border-width: var(--4px);
    --box-border-color: var(--box-filled-border-color-disabled);

    border: var(--1px) solid;
    border-color: var(--box-border-color-disabled);
  }

  &.boxFilled {
    opacity: 1;
  }

  &.boxHidden {
    visibility: hidden;
  }

  /* Align box slightly differently in Safari */
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    vertical-align: -0.125em;
  }
}

/* Always animate focus glow */
.box {
  transition: filter 0.2s ease-out;
}

/* Animate status change only if user clicks on this radio with mouse (by hovering) or with keyboard (by focusing) */
/* To prevent animating many other controlled radios that can be affected by this radio change or other events */
.radio:hover,
.radio:focus-within {
  .box {
    transition:
      filter 0.2s ease-out,
      box-shadow 0.2s ease-out;
  }

  /* On check start size change animation after box-shadow animatiion is almost done, don't animate opacity */
  &.checked {
    .box {
      transition:
        filter 0.2s ease-out,
        box-shadow 0.15s linear;
    }
  }
}
