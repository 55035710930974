/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.iconsSort {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1em;
  vertical-align: middle;
  font-size: var(--7px);
  line-height: 1;
}

.iconSort {
  transition: opacity 0.1s linear;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
    opacity: 0.4;
  }
}

.iconSortSelected {
  opacity: 1;
}
