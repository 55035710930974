/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
:root {
  --tabpanel-height-primary: var(--34px);
  --tabpanel-height-secondary: var(--32px);
  --tabpanel-height-tertiary: var(--25px);

  :where(html[data-theme='lightning'])& {
    --tab-focus-color: var(--lightning--blue-200);
    --tabpanel-background-color: var(--lightning--white);
    --tabpanel-border-color: var(--lightning--blue-gray-100);
  }
}

.tabPanel {
  --tabpanel-link-background-color-hover: unset;
  --tabpanel-link-background-color-active: unset;
  --tabpanel-link-active-background-color: unset;
  --tabpanel-link-active-background-color-hover: unset;
  --tabpanel-link-active-background-color-active: unset;
  --tabpanel-link-disabled-color: unset;
  --tabpanel-link-disabled-background-color: unset;
  --tabpanel-link-padding: var(--offset-small) var(--offset-medium);
  --tabpanel-link-padding-active: var(--tabpanel-link-padding);
  --tabpanel-link-padding-focus: var(--tabpanel-link-padding);
  --tabpanel-link-border-color: unset;
  --tabpanel-link-border: unset;
  --tabpanel-link-border-bottom: unset;
  --tabpanel-link-border-focus: unset;
  --tabpanel-link-active-border: unset;
  --tabpanel-link-active-border-bottom: unset;
  --tabpanel-link-border-radius: unset;
  --tabpanel-link-font-size: var(--13px);
  --tabpanel-link-line-height: var(--20px);
  --tabpanel-link-box-shadow: inset 0 calc(-1 * var(--1px)) 0 0 var(--tabpanel-border-color);

  display: flex;
  flex-shrink: 0;
  height: var(--tabpanel-height, auto);
  overflow: hidden;
  background-color: var(--tabpanel-background-color);

  :where(html[data-theme='lightning']) & {
    --tabpanel-link-color: var(--lightning--blue-gray-600);
    --tabpanel-link-color-hover: var(--lightning--blue-400);
    --tabpanel-link-color-active: var(--lightning--blue-700);
    --tabpanel-link-color-focus: var(--lightning--blue-gray-600);
    --tabpanel-link-background-color-disabled: unset;
    --tabpanel-link-color-disabled: var(--lightning--gray-400);
    --tabpanel-link-active-color: var(--lightning--blue-600);
    --tabpanel-link-active-color-hover: var(--lightning--blue-400);
    --tabpanel-link-active-color-active: var(--lightning--blue-700);
    --tabpanel-link-active-color-focus: var(--lightning--blue-600);
    --tabpanel-link-box-shadow-focus: inset 0 0 0 var(--1px) var(--tab-focus-color);
  }
}

.primary {
  --tabpanel-height: var(--tabpanel-height-primary);

  composes: tabPanel;
}

.secondary {
  --tabpanel-height: var(--tabpanel-height-secondary);

  composes: tabPanel;
}

.tertiary {
  --tabpanel-height: var(--tabpanel-height-tertiary);

  composes: tabPanel;
}

.pill {
  composes: tabPanel;
  height: var(--28px);
}

.outerTabPanel {
  position: relative; /* To calculate offset of children */
  display: flex;
  flex: 1 1 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none; /* Standard property to remove scrollbar. Only Firefox supports it at the time of writing this */
  scroll-behavior: smooth; /* To animate scroll on click */
  /* Prevent page navigation once a scroller has reached its full extent */
  overscroll-behavior-x: none;
  background-color: var(--tabpanel-background-color);

  &.hidden {
    visibility: hidden;
    scroll-behavior: auto; /* To make scroll on first appearance instantly, without animation */

    .underline {
      transition: none; /* To prevent underline animation on first appearance */
    }
  }

  /* Webkit specific property to remove scrollbar. Remove after browser starts supporting scrollbar-width */
  &::-webkit-scrollbar {
    display: none;
  }
}

.extraBottomBorder {
  flex: 1 1 auto;
  border-bottom: var(--1px) solid var(--tabpanel-border-color);
}

.extraBottomBorderLeft {
  width: var(--10px);
  height: var(--33px);
  border-bottom: var(--1px) solid var(--tabpanel-border-color);
}

.arrow {
  position: relative;
  display: flex;
  z-index: 1;
  align-self: stretch;
  width: var(--24px);
  cursor: pointer;
  transition: color 0.1s linear;

  &:hover {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-400);
    }
  }

  &:active {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-gray-700);
    }
  }

  &::before {
    position: absolute;
    z-index: -1;
    content: '';
    top: 15%;
    width: 50%;
    height: 70%;
    border-radius: 100%;
    transition: box-shadow 0.1s linear;
  }
}

.arrowLeft {
  composes: arrow;

  &:not(.disabled)::before {
    right: 0;
    box-shadow:
      1px 0 7px rgb(var(--lightning--black-rgb) / 0.5),
      0 0 20px rgb(var(--lightning--black-rgb) / 0.2);
  }
}

.arrowRight {
  composes: arrow;

  &:not(.disabled)::before {
    left: 0;
    box-shadow:
      -1px 0 7px rgb(var(--lightning--black-rgb) / 0.5),
      0 0 20px rgb(var(--lightning--black-rgb) / 0.2);
  }
}

.arrow-icon {
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--tabpanel-background-color);
}

.innerTabPanel {
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--tabpanel-background-color);
}

.pillInnerTabPanel {
  composes: gapXSmall gapHorizontal from 'utils.css';

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--white);
  }
}

/* Each Tab is a Link with custom style */
.link {
  composes: semiBold from 'utils.css';
  position: relative;
  padding: var(--tabpanel-link-padding);
  font-size: var(--tabpanel-link-font-size);
  font-weight: var(--fontWeightBold);
  line-height: var(--tabpanel-link-line-height);
  color: var(--tabpanel-link-color);
  white-space: nowrap;
  user-select: none; /* To make not selectable on click/doubleclick on windows */
  border: var(--tabpanel-link-border);
  border-radius: var(--tabpanel-link-border-radius);
  border-bottom: var(--tabpanel-link-border-bottom);
  box-shadow: var(--tabpanel-link-box-shadow);
  background-color: var(--tabpanel-link-background-color);
  /* Add touch-action: manipulation for a fast reacting touch experience */
  /* https://twitter.com/argyleink/status/1405881231695302659 */
  touch-action: manipulation;

  &:focus {
    outline: none;
  }

  &:hover:not(:active) {
    color: var(--tabpanel-link-color-hover);
    background-color: var(--tabpanel-link-background-color-hover);
  }

  &:active {
    color: var(--tabpanel-link-color-active);
    background-color: var(--tabpanel-link-background-color-active);
  }

  &:focus-visible {
    padding: var(--tabpanel-link-padding-focus);
    color: var(--tabpanel-link-color-focus);
    border: var(--tabpanel-link-border-focus);
    box-shadow: var(--tabpanel-link-box-shadow-focus);
  }

  &.active {
    padding: var(--tabpanel-link-padding-active);
    color: var(--tabpanel-link-active-color);
    border: var(--tabpanel-link-active-border);
    border-bottom: var(--tabpanel-link-active-border-bottom);
    background-color: var(--tabpanel-link-active-background-color);

    &:hover:not(:active) {
      color: var(--tabpanel-link-active-color-hover);
      background-color: var(--tabpanel-link-active-background-color-hover);
    }

    &:active {
      color: var(--tabpanel-link-active-color-active);
      background-color: var(--tabpanel-link-active-background-color-active);
    }

    &:focus-visible {
      color: var(--tabpanel-link-active-color-focus);
      border: var(--tabpanel-link-border-focus);
    }
  }
}

.disabled {
  color: var(--tabpanel-link-color-disabled);
  pointer-events: none;
  background-color: var(--tabpanel-link-background-color-disabled);
}

.primary .link {
  border-radius: var(--8px) var(--8px) 0 0;
}

.secondary .link {
  --tabpanel-link-padding: var(--6px) var(--offset-medium);
  --tabpanel-link-padding-active: var(--tabpanel-link-padding);
  --tabpanel-link-padding-focus: var(--tabpanel-link-padding);
  --tabpanel-link-border-radius: var(--8px) var(--8px) 0 0;
  --tabpanel-link-box-shadow: none;
  --tabpanel-link-box-shadow-focus: none;

  :where(html[data-theme='lightning']) & {
    --tabpanel-link-color-active: var(--lightning--blue-600);
    --tabpanel-link-active-color: var(--lightning--blue-800);
    --tabpanel-link-active-color-hover: var(--lightning--blue-600);
    --tabpanel-link-active-color-active: var(--lightning--blue-800);
    --tabpanel-link-active-color-focus: var(--lightning--blue-800);
    --tabpanel-link-active-background-color: var(--lightning--blue-50);
    --tabpanel-link-active-background-color-hover: var(--lightning--blue-75);
    --tabpanel-link-active-background-color-active: var(--lightning--blue-100);
    --tabpanel-link-border: var(--1px) solid transparent;
    --tabpanel-link-border-bottom: var(--1px) solid var(--tabpanel-border-color);
    --tabpanel-link-border-focus: var(--1px) solid var(--tab-focus-color);
    --tabpanel-link-active-border: var(--1px) solid transparent;
    --tabpanel-link-active-border-bottom: var(--1px) solid transparent;
  }
}

.tertiary .innerTabPanel {
  .link {
    --tabpanel-link-padding: var(--offset-xsmall) calc(var(--offset-medium) - var(--1px)) var(--offset-xsmall)
      var(--offset-medium);
    --tabpanel-link-padding-active: var(--tabpanel-link-padding);
    --tabpanel-link-padding-focus: var(--tabpanel-link-padding);
    --tabpanel-link-line-height: var(--16px);

    :where(html[data-theme='lightning']) & {
      --tabpanel-link-color-hover: var(--lightning--blue-400);
      --tabpanel-link-color-active: var(--lightning--blue-700);
      --tabpanel-link-color-focus: var(--lightning--blue-700);
      --tabpanel-link-color-disabled: var(--lightning--gray-500);
      --tabpanel-link-background-color-hover: var(--lightning--blue-gray-50);
      --tabpanel-link-background-color-active: var(--lightning--blue-gray-100);
      --tabpanel-link-background-color-disabled: var(--lightning--gray-50);
      --tabpanel-link-active-color: var(--lightning--blue-800);
      --tabpanel-link-active-color-hover: var(--lightning--blue-900);
      --tabpanel-link-active-color-active: var(--lightning--blue-900);
      --tabpanel-link-active-color-focus: var(--lightning--blue-600);
      --tabpanel-link-active-background-color: var(--lightning--blue-50);
      --tabpanel-link-active-background-color-hover: var(--lightning--blue-100);
      --tabpanel-link-active-background-color-active: var(--lightning--blue-200);
      --tabpanel-link-border: var(--1px) solid var(--lightning--blue-gray-300);
      --tabpanel-link-border-bottom: var(--tabpanel-link-border);
      --tabpanel-link-border-focus: var(--1px) solid var(--tab-focus-color);
      --tabpanel-link-active-border: var(--tabpanel-link-border);
      --tabpanel-link-active-border-bottom: var(--tabpanel-link-border);
      --tabpanel-link-box-shadow: none;
      --tabpanel-link-box-shadow-focus: none;
    }
  }

  & > :first-child {
    border-radius: var(--8px) 0 0 var(--8px);
  }

  & > :last-child {
    border-radius: 0 var(--8px) var(--8px) 0;
  }

  & > :not(:last-child) {
    border-right: var(--1px) solid transparent;
  }
}

.pill .link {
  --tabpanel-link-border-radius: var(--15px);
  --tabpanel-link-padding: 0 var(--15px);
  --tabpanel-link-padding-active: var(--tabpanel-link-padding);
  --tabpanel-link-padding-focus: var(--tabpanel-link-padding);

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--fontWeightLight);

  :where(html[data-theme='lightning']) & {
    --tabpanel-link-color-hover: var(--lightning--blue-900);
    --tabpanel-link-color-active: var(--lightning--blue-900);
    --tabpanel-link-background-color: var(--lightning--blue-gray-50);
    --tabpanel-link-background-color-hover: var(--lightning--blue-200);
    --tabpanel-link-background-color-active: var(--lightning--blue-100);
    --tabpanel-link-background-color-disabled: var(--lightning--blue-gray-50);
    --tabpanel-link-active-color: var(--lightning--white);
    --tabpanel-link-active-color-hover: var(--lightning--white);
    --tabpanel-link-active-color-active: var(--lightning--white);
    --tabpanel-link-active-color-focus: var(--lightning--white);
    --tabpanel-link-active-background-color: var(--lightning--blue-700);
    --tabpanel-link-active-background-color-hover: var(--lightning--blue-700);
    --tabpanel-link-active-background-color-active: var(--lightning--blue-700);
    --tabpanel-link-border: var(--1px) solid var(--lightning--blue-gray-200);
    --tabpanel-link-border-bottom: var(--tabpanel-link-border);
    --tabpanel-link-border-focus: var(--tabpanel-link-border);
    --tabpanel-link-box-shadow: none;
    --tabpanel-link-box-shadow-focus: inset 0 0 0 var(--2px) var(--tab-focus-color);
  }

  /* same hover effect as labels */
  &:hover:not(.active) {
    :where(html[data-theme='lightning']) & {
      border-color: var(--lightning--blue-600);
    }
  }

  &.active {
    border-color: transparent;

    :where(html[data-theme='lightning']) & {
      --tab-focus-color: var(--lightning--blue-300);
    }
  }
}

.icon {
  position: absolute;
  top: calc(-1 * var(--4px));
  margin-left: var(--2px);
  font-size: var(--10px);
}

.error {
  composes: icon;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-800);
  }
}

.warning {
  composes: icon;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--orange-800);
  }
}

.counter {
  position: absolute;
  top: 0;
  right: unset;
  left: calc(100% - var(--16px));
  font-size: var(--10px);
}

/* Sliding underline */
.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: var(--1px);
  height: var(--3px);
  border-radius: var(--2px);
  background-color: var(--tabpanel-underline-color);
  transform: translateX(0) scaleX(0);
  will-change: transform, width;
  transition:
    transform 0.25s ease-out,
    width 0.25s ease-out;

  :where(html[data-theme='lightning']) & {
    --tabpanel-underline-color: rgb(0 197 251);

    background: linear-gradient(178.72deg, rgb(0 198 251) 37.63%, rgb(0 91 234) 92.65%);
  }
}

.outerTabPanel:has(> .innerTabPanel > .active:hover:not(:active)) > .underline {
  &::before {
    position: relative;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0.2;

    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--white);
    }
  }
}

.outerTabPanel:has(> .innerTabPanel > .active:active) > .underline {
  &::before {
    position: relative;
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0.2;

    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--blue-700);
    }
  }
}

/* To sync color change with underline animation */
.primary .link {
  will-change: color;
  transition: color 0.15s ease-out;
}
