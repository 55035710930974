/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.dropdown {
  height: calc(100vh - 900px);
  min-height: 300px;
}

.validHint {
  padding-top: var(--5px);
  padding-bottom: var(--10px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-500);
  }
}
