/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.fileElement {
  position: absolute;
  left: var(--100px);
}

.uploadContainer {
  position: relative;
}

.input {
  &:focus {
    outline: none;
  }

  &:focus-visible {
    :where(html[data-theme='lightning']) & {
      outline: var(--lightning--blue-200) solid var(--2px);
    }
  }
}

.hideFileInputText {
  color: transparent;
}
