/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.container {
  --albatrossBreakpoint: calc(var(--100px) * 8);

  composes: gapLarge gapAlbatross from 'utils.css';
  flex-direction: row-reverse;
  align-items: flex-start;
}

.editor-list {
  max-height: calc(100vh - var(--header-height));
  padding: var(--5px);
  overflow: auto;

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--purple-50);
  }

  @media (width >= 785px) {
    position: sticky;
    top: var(--header-height);
  }
}

.keyBlock {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.note {
  font-size: 90%;
  text-align: left;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}
