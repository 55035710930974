/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.secondsInput {
  width: calc(2.2 * var(--100px));
}

.formUlHorizontal {
  margin-bottom: 0;
}

.bindPasswordInput {
  & input::placeholder {
    font-size: var(--15px);

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--gray-900);
    }
  }
}
