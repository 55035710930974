/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.notifications {
  position: fixed;
  right: 0;
  bottom: var(--20px);
  left: 0;
}

.warning {
  box-shadow: var(--shadow-depth-4);
}
