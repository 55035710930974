/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
.name {
  padding-left: var(--modal-content-h-padding);
}

.dropdown {
  max-height: 50vh;
}

.actions {
  justify-content: space-between;
}

.actionsRight {
  justify-content: flex-end;
}

.emailCheckbox-checkbox {
  margin-top: var(--5px);
  margin-bottom: var(--5px);
  margin-left: calc(2 * var(--58px));
}

.list {
  > .row {
    > .value {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.options {
  white-space: nowrap;

  > div {
    width: 100%;
  }

  > .checkHide,
  > .checkShow {
    width: auto;
    padding-right: var(--5px);
    padding-left: var(--5px);
  }

  &.notSelectable {
    > div {
      width: auto;
    }
  }
}

.dropdownLabel {
  padding-right: var(--16px);
  padding-left: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.optionText {
  padding-right: var(--0px);
  padding-left: var(--0px);
}

.categoryText {
  font-weight: var(--fontWeightBold);
}

.selectMainLabel {
  .optionText {
    padding-left: var(--5px);
  }
}

.tooltipInline {
  display: inline;
}

.dropdownWrapper {
  max-height: none;
}
