/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.footer {
  composes: fixedHeight from './Modal.css';
  display: flex;
  flex-direction: column;
  min-height: var(--51px); /* 50px + 1px border */
  padding: var(--20px) var(--32px);
  overflow: unset;
  border-top: 1px solid var(--modal-separator-color);
}

.actions {
  composes: gapSmall gapHorizontalWrap from 'utils.css';
  flex: 1 1 auto;
  justify-content: flex-end; /* Stick buttons to right side */
  align-items: center;
}
