/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
:root {
  --selector-items-padding-h: var(--3px);
  --searchBar-border-width: var(--1px);
  --search-bar-container-padding-h: var(--12px);

  :where(html[data-theme='lightning']) & {
    --input-placeholder-color: var(--lightning--blue-gray-500);
  }
}

.standard.styled:not(.disabled) {
  --button-color-no-fill: var(--searchBar-button-color);
  --button-color-no-fill-hover: var(--searchBar-button-color-hover);
  --button-color-no-fill-active: var(--searchBar-button-color-active);
}

.small {
  --button-min-height: var(--optionSelector-line-height);
}

.formatOption {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
}

.hintTextStyle {
  composes: light from 'utils.css';
  min-width: var(--90px);
  padding: 0 var(--5px);
  color: var(--hintText-color);
  text-align: right;

  :where(html[data-theme='lightning']) & {
    --hintText-color: var(--lightning--blue-gray-600);
  }
}

.categoryInfo {
  composes: hintTextStyle;
  margin-block-start: auto;
  padding: var(--offset-small);
  text-align: left;
}

.selectorContainer {
  position: relative;
}

.label {
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-right: var(--4px);
  cursor: pointer;
}

.selector {
  composes: gapSmall gapHorizontal from 'utils.css';
  flex-grow: 1;
  align-items: start;
  height: 100%;
}

.selectorAlignedIcon-icon {
  composes: selectorAligned from 'components/Form/OptionSelector/OptionSelector.css';
  font-size: 1.2em;
}

.searchBarContainer {
  --search-bar-container-padding-h: var(--12px);

  position: relative;
  display: flex;
  flex: 1 1 auto;
  min-height: calc(var(--2px) + 2 * var(--optionSelector-vertical-padding) + var(--optionSelector-line-height));
  padding: var(--optionSelector-vertical-padding) var(--search-bar-container-padding-h);

  &:focus-within:not(.error, .warning, .expander) {
    --searchBar-border-color: var(--searchBar-border-color-focused);
    --searchBar-background-color: var(--searchBar-background-color-focused);
  }

  &.expander {
    .inputPanel {
      max-height: 0;
    }
  }

  &.error {
    :where(html[data-theme='lightning']) & {
      --searchBar-border-color: var(--lightning--red-500);
      --searchBar-button-color: var(--lightning--red-500);
      --searchBar-button-color-hover: var(--lightning--red-400);
      --searchBar-button-color-active: var(--lightning--red-600);
    }
  }

  &.warning {
    :where(html[data-theme='lightning']) & {
      --searchBar-border-color: var(--lightning--orange-500);
      --searchBar-button-color: var(--lightning--orange-500);
      --searchBar-button-color-hover: var(--lightning--orange-400);
      --searchBar-button-color-active: var(--lightning--orange-600);
    }
  }

  &.disabled {
    --searchBar-background-color: var(--searchBar-background-color-disabled);
    --searchBar-button-color: var(--lightning--blue-gray-300);
  }

  :where(html[data-theme='lightning']) & {
    --searchBar-border-color: var(--lightning--blue-gray-400);
    --searchBar-border-color-disabled: var(--lightning--blue-gray-200);
    --searchBar-border-color-focused: var(--lightning--blue-500);
    --searchBar-border-color-error: var(--lightning--red-500);
    --searchBar-border-color-warning: var(--lightning--orange-500);
    --searchBar-background-color: var(--lightning--white);
    --searchBar-background-color-focused: var(--lightning--white);
    --searchBar-background-color-disabled: var(--lightning--gray-25);
    --searchBar-button-color: var(--lightning--blue-gray-500);
    --searchBar-button-color-hover: var(--lightning--blue-gray-400);
    --searchBar-button-color-active: var(--lightning--blue-gray-600);
    --input-suggestion-color: var(--lightning--gray-500);
  }
}

.searchBar {
  position: absolute;
  inset: calc(-1 * var(--10px)) 0 0 0;
  border: var(--searchBar-border-width) solid var(--searchBar-border-color);
  border-radius: var(--searchBar-border-radius, var(--8px));
  background-color: var(--searchBar-background-color);

  &.focused {
    border: var(--1px) solid var(--searchBar-border-color-focused);
  }
}

.legend {
  display: block;
  visibility: hidden;
  width: auto;
  max-width: 0;
  height: var(--20px);
  padding: 0;
  font-weight: var(--fontWeightBold);
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  color: var(--searchBar-legend-color, var(--searchBar-border-color-focused));
  mix-blend-mode: darken;
  transition: margin-left 0.2s linear;

  &.show {
    visibility: visible;
    max-width: unset;
  }

  &.expander {
    visibility: hidden;
  }

  > span {
    display: inline-block;
    padding: 0 var(--2px);
  }

  :where(html[data-theme='lightning']) & {
    --searchBar-legend-color: var(--lightning--blue-600);

    margin-left: calc(var(--search-bar-container-padding-h) + var(--selector-items-padding-h));
  }
}

.searchBar:not(.focused) .legend.title {
  color: var(--text-color);
}

.icon {
  --button-icon-size: 0.8em;
}

.items {
  composes: gapXSmall gapHorizontalWrap from 'utils.css';
  position: relative;
  flex: 1 1 auto;
  padding: 0 var(--3px);

  &.expander {
    flex-flow: row-reverse wrap-reverse;
  }
}

.inputPanel {
  composes: gapXSmall gapHorizontal from 'utils.css';
  position: relative;
  flex: 1 1 auto;
  max-height: 0;
  text-overflow: ellipsis;
  overflow: hidden;

  &.show {
    max-height: var(--optionSelector-line-height);
  }
}

.input {
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--text-color);
  outline: none;
  border: none;
  background-color: transparent;
}

input::placeholder {
  font-weight: var(--fontWeightLight);
  color: var(--input-placeholder-color);
  user-select: none;
}

.suggestion {
  composes: input;
  position: absolute;
  color: var(--input-suggestion-color);
}

.highlightedText {
  composes: bold from 'utils.css';

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-800);
  }
}

.valuePanel {
  --valuePanel-maxHeight: 100vh;

  max-height: min(var(--valuePanel-maxHeight), calc(30vh - var(--header-height)));
  padding: var(--1px);
  overflow-y: auto;
  overscroll-behavior-y: auto;
}

.valuePanel,
.selectedResource {
  composes: gapXSmall gapHorizontalWrap from 'utils.css';
  position: relative;
  max-width: 100%;

  > * {
    max-width: 100%;
  }
}

.selectedValue {
  align-self: baseline;
}

.joiner {
  align-self: center;
  word-break: normal;
  user-select: none;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
  }
}

.pillDisabled-pill {
  --pill-content-text-decoration: none;

  :where(html[data-theme='lightning']) & {
    --pill-content-border-color: var(--lightning--gray-900);
  }
}

.joinerPill-pill {
  --pill-content-border-width: var(--0px);
  --pill-text-horizontal-padding: var(--0px);
  --pill-content-vertical-padding: calc(0.5 * var(--1px));
  --pill-content-horizontal-padding: var(--2px);
}

/* useful when value inside joiner pill is a pill */
.joinerValuePill-pill {
  --pill-content-border-width: var(--1px);
  --pill-content-border-color: transparent;
  --pill-content-outline-width: var(--0px);
  --pill-text-horizontal-padding: var(--4px);
  --pill-content-vertical-padding: calc(2.5 * var(--1px));
}

.isNotPill-pill {
  composes: joinerPill-pill;

  .joinerValuePill-pill {
    --pill-symbol-group-box-shadow: unset;
    --pill-content-horizontal-padding: var(--offset-xsmall);

    :where(html[data-theme='lightning']) & {
      --pill-content-color-hover: var(--lightning--blue-700);
      --pill-content-color-active: var(--lightning--blue-800);
      --pill-content-background-color: var(--lightning--gradient-400);
      --pill-content-background-color-hover: var(--lightning--blue-50);
      --pill-content-background-color-active: var(--lightning--blue-100);
      --pill-content-border-color-hover: var(--lightning--blue-200);
    }
  }

  :where(html[data-theme='lightning']) & {
    --pill-exclusion-color: var(--lightning--purple-900);
    --pill-exclusion-background-color: var(--lightning--purple-300);
    --pill-content-background-color: var(--lightning--purple-100);
  }
}

.close-icon {
  display: inline-block;
  align-self: flex-start;
}

.clickable {
  .close-icon {
    cursor: pointer;

    &:hover {
      :where(html[data-theme='lightning']) & {
        color: var(--lightning--blue-gray-900);
      }
    }
  }
}

/* Dropdown */
.dropdown-tooltip .dropdown-content {
  --dropdown-grid-template: 'categoryPanel optionPanel' 1fr 'footer footer' minmax(32px, min-content) / 180px
    minmax(180px, auto);
  --dropdown-grid-template-no-category-panel: 'optionPanel' auto 'footer' minmax(32px, min-content);
  --dropdown-border-radius: var(--0px) var(--0px) var(--8px) var(--8px);
  --searchBar-border-radius: var(--8px) var(--8px) var(--0px) var(--0px);

  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: inherit;
  max-height: inherit;
  padding: 0;

  .searchBarContainer {
    flex: 0 0 auto;
  }
}

.dropdown-top {
  .dropdown-content {
    --dropdown-border-radius: var(--8px) var(--8px) var(--0px) var(--0px);
    --searchBar-border-radius: var(--0px) var(--0px) var(--8px) var(--8px);

    flex-direction: column-reverse;
  }
}

.dropdown-light {
  --tooltip-bg-color: transparent;

  :where(html[data-theme='lightning']) & {
    box-shadow: var(--lightning--box-shadow-700);
  }
}

.drawer-text {
  composes: normal from 'utils.css';
  font-size: var(--14px);
}

.drawer-icon {
  font-size: 0.7em;
}

.infoPanel {
  position: sticky;
  z-index: var(--shadow-depth-3z);
  top: 0;
  padding: var(--offset-xsmall);

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--white);
  }
}

.infoPanelContent {
  padding: var(--5px);
}

.dropdown-bottom {
  margin-top: calc(-1 * var(--offset-xsmall));
}

.dropdown {
  display: grid;
  flex: 1 1 100%;
  grid-template: var(--dropdown-grid-template);
  width: 100%;
  list-style: none;
  overflow-y: auto;
  pointer-events: auto;
  border-radius: var(--dropdown-border-radius);

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--white);
  }

  &.noCategoryPanel {
    grid-template: var(--dropdown-grid-template-no-category-panel);

    &.noFooter {
      grid-template: 'optionPanel' auto;
    }

    .optionPanel {
      max-width: 96vw;
    }
  }
}

.categoryPanel {
  --categoryPanel-padding: var(--offset-small) var(--offset-small) var(--offset-small) 0;

  grid-area: categoryPanel;
  flex: 1 1 auto;
  flex-direction: column;
  padding: var(--categoryPanel-padding);
  overflow-y: auto;
  user-select: none;
  background-color: var(--categoryPanel-background-color);

  &.hide {
    display: none;
  }

  .optionPanel {
    padding: 0;
    background-color: var(--categoryPanel-background-color);
  }

  .infoPanel {
    background-color: var(--categoryPanel-background-color);
  }

  .resources {
    grid-template: unset;
  }

  .option {
    padding-left: var(--8px);
    border: var(--2px) solid transparent;
    border-radius: var(--categoryPanel-option-border-radius, 0);
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-image: var(--categoryPanel-option-border-image, unset);

    &.highlighted,
    &.active {
      --categoryPanel-option-border-radius: 0 var(--8px) var(--8px) 0;
    }

    &.highlighted {
      --categoryPanel-option-border-image: linear-gradient(rgba(0 221 251 /0.15), rgba(0 178 255 /0.15)) 1;
    }

    &.active {
      --categoryPanel-option-border-image: linear-gradient(rgba(0 198 251 /1), rgba(0 91 234 /1)) 1;
    }

    .pill {
      :where(html[data-theme='lightning']) & {
        --categoryPanel-background-color-active: var(--lightning--white);
        --pill-content-border-color: var(--lightning--blue-gray-400);
      }
    }
  }

  :where(html[data-theme='lightning']) & {
    --categoryPanel-background-color: var(--lightning--blue-gray-50);
  }
}

.divider {
  :where(html[data-theme='lightning']) & {
    border-top: var(--1px) solid var(--lightning--blue-gray-300);
  }

  &.shadowUp {
    box-shadow: var(--shadow-depth-2onlyUp);
  }

  &.shadowDown {
    box-shadow: var(--shadow-depth-2onlyDown);
  }

  &.margin {
    margin: var(--offset-xsmall);
  }
}

.option {
  composes: ellipsisLines from 'utils.css';
  padding: var(--5px) var(--5px) var(--5px) var(--10px);
  outline: none;

  &.disabled {
    :where(html[data-theme='lightning']) & {
      background-color: var(--lightning--blue-gray-50);
    }
  }

  &.active {
    /* Use stroke-width instead of bold font-weight to prevent active category name from resizing */
    -webkit-text-stroke-width: var(--1px);

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-800);
      background-color: var(--lightning--white);
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.highlighted {
    border-radius: var(--4px);

    :where(html[data-theme='lightning']) & {
      color: var(--lightning--blue-900);
      background-color: var(--lightning--blue-200);
    }
  }
}

.option-checkbox {
  align-items: center;
}

.optionPanel {
  display: flex;
  grid-area: optionPanel;
  flex-direction: column;
  max-width: calc(96vw - 180px);
  padding: var(--offset-medium);
  overflow-y: auto;
}

.resources {
  --resources-template-wide-screen: auto / repeat(auto-fit, minmax(180px, 1fr));
  --resources-template-small-screen: auto / minmax(180px, 1fr);

  display: grid;
  grid-template: var(--resources-template-wide-screen);
  max-width: inherit;
  height: 100%;
  overflow-y: auto;

  > * {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    &:empty {
      display: none;
    }
  }

  @media (width <= 600px) {
    grid-template: var(--resources-template-small-screen);
  }
}

.stickyResources {
  &.paddingBottom {
    padding-bottom: var(--offset-xsmall);
  }

  &.paddingTop {
    padding-top: var(--offset-xsmall);
  }
}

.listResource {
  overflow-y: auto;
  overscroll-behavior-y: none;
}

.resourceSubtitle {
  composes: light from 'utils.css';
  margin: var(--5px);
  font-style: italic;
}

.containerResource {
  composes: paddingSmallRight paddingSmallLeft from 'utils.css';
  height: 100%;
}

.createOption {
  composes: gapXSmall gapHorizontal from 'utils.css';

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-700);
  }
}

.createOption-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-800);
  }
}

.partialOption {
  font-style: italic;
}

.footer {
  display: flex;
  grid-area: footer;

  :where(html[data-theme='lightning']) & {
    border-top: var(--1px) solid var(--lightning--blue-gray-300);
  }
}

.footerContent {
  composes: gapSmall gapHorizontalWrap paddingSmallRight paddingSmallLeft from 'utils.css';
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;

  &.footerCheckbox {
    justify-content: space-between;
  }
}

.shortcut {
  padding: var(--2px) var(--4px);
  border-radius: var(--2px);

  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-50);
  }
}

.shortcutText {
  composes: semiBold from 'utils.css';
  line-height: 1.8;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-800);
  }
}

.shortcut-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-800);
  }
}

.advancedOptions-labelText {
  padding-right: 0;
}

.filteringTips {
  composes: bold gapXSmall gapHorizontalWrap from 'utils.css';

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-900);
  }
}

.highlightTextUnderline {
  composes: highlightedText;
  text-decoration: underline;
}
/* Loading skeleton */
.loadingLine {
  flex: 1 0 auto;
  height: var(--18px);
  margin: var(--10px) var(--42px) var(--offset-x-large) var(--offset-small);
  opacity: 0.6;
  border-radius: var(--10px);

  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-gray-300);
  }
}

.loadingLineLong {
  composes: loadingLine;
  margin-right: var(--10px);
}

.emptyMessage-centerer .emptyMessage-main {
  width: 100%;
  min-width: unset;
}

.emptyMessage-banner {
  --banner-width-max: 100%;

  margin: var(--10px);
}

.emptyMessage-plain {
  .emptyMessage-core {
    min-height: var(--60px);
    font-size: var(--16px);
  }
}

.key {
  composes: paddingSmallLeft from 'utils.css';
}

.showAll {
  composes: centerFlexContent from 'utils.css';
  composes: link from 'components/Link/Link.css';
  cursor: pointer;
}

.message {
  margin-top: var(--offset-xsmall);
}
