/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.content {
  display: flex;
  width: calc(3.3 * (var(--100px)));
  max-height: calc(3.3 * (var(--100px)));

  :where(html[data-theme='lightning']) & {
    box-shadow: var(--lightning--shadow-400);
  }
}

.buttonsWrapper {
  width: min-content;
  margin-left: var(--9px);
}

.viewMode {
  composes: content;
  margin: 0;
  word-break: break-word;
  overflow-y: auto;
}

.textarea {
  resize: none;
}

.button-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-300);
  }
}
