/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.linkAssetsNumber {
  text-decoration: underline;
}

.button {
  margin-left: var(--8px);
}

.red-progressValue {
  :where(html[data-theme='lightning']) & {
    background: linear-gradient(136.72deg, rgb(255 27 68) 7.63%, rgb(202 4 0) 92.65%);
  }
}

.red-labels {
  :where(html[data-theme='lightning']) & {
    color: rgb(var(--lightning--red-400-rgb) / 0.4);
  }
}

.green-progressValue {
  :where(html[data-theme='lightning']) & {
    background: linear-gradient(136.72deg, rgb(93 218 49) 7.63%, rgb(0 150 32) 92.65%);
  }
}

.green-labels {
  :where(html[data-theme='lightning']) & {
    color: rgb(var(--lightning--green-500-rgb) / 0.4);
  }
}

.orange-progressValue {
  :where(html[data-theme='lightning']) & {
    background: linear-gradient(136.72deg, rgb(255 168 0) 7.63%, rgb(242 104 0) 92.65%);
  }
}

.orange-labels {
  :where(html[data-theme='lightning']) & {
    color: rgb(var(--lightning--orange-500-rgb) / 0.4);
  }
}

.red-link {
  &:hover {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--red-500);
    }
  }
}

.green-link {
  &:hover {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--green-500);
    }
  }
}

.orange-link {
  &:hover {
    :where(html[data-theme='lightning']) & {
      color: var(--lightning--orange-500);
    }
  }
}
