/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.content {
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
}
