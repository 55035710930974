/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.svg {
  transform: scale(0.75);
}

.add-svg {
  composes: svg;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-900);
  }
}

.remove-svg {
  composes: svg;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-800);
  }
}
