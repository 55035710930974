/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.active {
  position: relative;
  pointer-events: none;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--purple-300);
  }

  &::after {
    position: absolute;
    content: '▲';
    bottom: -100%;
    left: 47%;
  }
}
