/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.cell.default,
.cell.download {
  text-align: center;
}

.cell.osVersion {
  text-align: right;
}

.table-maxWidth_800 {
  .cell.osVersion,
  .cell.default,
  .cell.download {
    text-align: left;
  }
}
