/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.keyValue {
  display: block;
  width: 100%;
  max-width: 600px;
  margin-bottom: var(--5px);
}

.bottomSpacer {
  padding-bottom: var(--100px);
}

.content {
  font-size: var(--13px);
  font-weight: var(--fontWeight);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-600);
  }
}

.allowlist-icon {
  font-size: var(--13px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-700);
  }
}

.radioGridCell {
  composes: centerFlexAlign from 'utils.css';
  display: flex;
  margin-bottom: var(--5px);
  > div {
    width: 100%;
  }
}

.noLabelsAllowed {
  line-height: var(--32px);
}

.gridCellContainer {
  display: flex;
  align-items: center;
  gap: var(--20px);
}

.flexGrow {
  flex-grow: 1;
}

/*
(*) Radio Button Label
13px to bottom of round button, 16px to baseline of label text
[ FormLabelSelectorOld ] (31.6719px by default. "No labels" needs same height to avoid "jumping".)
20px to top of next radio button circle
 */
