/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.controlButtonGroup {
  composes: gapSmall gapHorizontal from 'utils.css';
  display: flex;
}

.controlButtonGroup > * {
  align-self: center;
}

.goButton-button {
  width: var(--60px);
}

.clearFiltersButton-button .clearFiltersButton-text {
  white-space: nowrap;
}

/* OVERWRITE: Save button */
.saveButton-textIsHideable {
  white-space: nowrap;

  @media screen and (width <= 1250px) {
    display: none;
  }
}

.favoriteFiltersButton-menu-menuMedium {
  max-width: 100%;
}

.favoriteFiltersButton-button {
  max-width: calc(var(--100px) * 2 + var(--50px));
  white-space: nowrap;
}

.favoriteFiltersButton-menu-triggerLabel {
  overflow: hidden;
}

.favoriteFiltersButton-text {
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.favoriteFiltersButton-menu-itemsContainer {
  max-height: calc(var(--100px) + var(--50px));
  overflow-y: scroll;

  @media screen and (height >= 400px) {
    max-height: calc(var(--100px) * 2 + var(--50px));
  }

  @media screen and (height >= 500px) {
    max-height: calc(var(--100px) * 3 + var(--50px));
  }

  @media screen and (height >= 600px) {
    max-height: calc(var(--100px) * 4 + var(--50px));
  }

  @media screen and (height >= 800px) {
    max-height: calc(var(--100px) * 6 + var(--50px));
  }
}

.favoriteFiltersTextSection {
  composes: gapXSmall gapHorizontal from 'utils.css';
}

.favoriteFiltersText {
  text-overflow: ellipsis;
  overflow: hidden;
}

/* OVERWRITE Favorite Filter Selector Menu Item */
.favoriteMenuItem_Menu-item {
  max-width: inherit;
}

.favoriteMenuItem_Menu-itemContent {
  max-width: 100%;
}

.favoriteMenuItem_Menu-text {
  width: 100%;
  max-width: inherit;
}

.favoriteMenuItem_Menu-text > div {
  display: flex;
}

.favoriteMenuItem_Tooltip-inline {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  max-width: inherit;
}

.favoriteMenuItem_Checkbox-checkbox {
  flex-grow: 1;
  width: 90%;
  max-width: inherit;
}

.favoriteMenuItem_Checkbox-checkboxContent {
  width: 100%;
}

.favoriteMenuItem_Checkbox-side {
  max-width: inherit;
  white-space: nowrap;
  overflow: hidden;
}

.favoriteMenuItem_Checkbox-labelText {
  max-width: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
}

.saveButton-menu-dropdownWithArrow::before,
.favoriteFiltersButton-menu-dropdownWithArrow::before {
  display: none;
  margin-top: var(--2px);
}
