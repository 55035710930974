/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
/* providers and providingServices are the names of the property in Grid config */
.areaHead .providers,
.areaHead .providingServices {
  color: var(--cell-color);
  background-color: var(--cell-bg-color);

  &:hover {
    background-color: var(--cell-bg-color-hover);
  }

  :where(html[data-theme='lightning']) & {
    --cell-bg-color: var(--lightning--purple-100);
    --cell-bg-color-hover: var(--lightning--purple-200);
    --cell-color: var(--lightning--blue-gray-700);
  }
}

.diffRow {
  composes: row from 'components/Diff/Diff.css';
  /* To vertically align the +/- icons with the pills */
  line-height: var(--pill-normal-total-height);
}

.joinerPill-pill {
  --pill-content-border-width: var(--0px);
  --pill-text-horizontal-padding: var(--0px);
  --pill-content-vertical-padding: calc(0.5 * var(--1px));
  --pill-content-horizontal-padding: var(--2px);
}

.valuePill-pill {
  --pill-content-border-width: var(--1px);
  --pill-text-horizontal-padding: var(--2px);
  --pill-content-outline-width: var(--0px);
  --pill-content-border-color: transparent;
  --pill-content-vertical-padding: calc(2.5 * var(--1px));
}

.filteringTips {
  composes: bold gapXSmall gapHorizontalWrap from 'utils.css';

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-900);
  }
}

.formatOption {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
}

.buttons {
  composes: gapXSmall gapHorizontal from 'utils.css';
  display: flex;
  justify-content: flex-end;
}

.divider {
  margin-top: calc(-1 * var(--5px));

  :where(html[data-theme='lightning']) & {
    border-top: var(--1px) solid var(--lightning--blue-gray-300);
  }
}

.inspectIcon {
  font-size: 1.3em;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-800);
  }
}

.edit,
.more {
  font-size: 1em;
  border: none;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
  }
}

.edit-icon,
.more-icon {
  font-size: 1.2em;
  cursor: pointer;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
  }
}

.noIconMenuItem-text {
  margin-left: 1.4em;
}

.headerIcon-icon {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--yellow-900);
  }
}

.rowToEnable .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-200);
  }
}

.widget {
  composes: gapHorizontal gapSmall centeredFlex from 'utils.css';
  justify-content: space-between;
}

.scopeWidget-pill {
  :where(html[data-theme='lightning']) & {
    --pill-content-border-color: var(--lightning--blue-gray-400);
  }
}

.addNew-primary {
  --card-padding: var(--offset-xsmall);

  border-radius: 0 var(--8px) var(--8px) 0;

  :where(html[data-theme='lightning']) & {
    --card-background-color: var(--lightning--green-50);

    border-left: var(--2px) solid var(--lightning--green-300);
  }
}

.view-primary {
  --card-padding: var(--offset-small);

  border-radius: 0 var(--8px) var(--8px) 0;

  :where(html[data-theme='lightning']) & {
    --card-background-color: var(--lightning--gray-25);

    border-left: var(--2px) solid var(--lightning--blue-400);
  }
}

.scopeSubtitle {
  composes: light from 'utils.css';
  font-style: italic;
}

.addNewServiceText {
  user-select: none;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-900);
  }
}

.svg {
  transform: scale(0.75);
}

.add-svg {
  composes: svg;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--green-700);
  }
}

.remove-svg {
  composes: svg;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--red-700);
  }
}

.tooltipMessageTitle {
  composes: semiBold from 'utils.css';
  text-decoration: underline;
}

.key,
.value {
  color: unset;
}

.key {
  composes: normal from 'utils.css';

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-300);
  }
}

.value {
  composes: light from 'utils.css';

  :where(html[data-theme='lightning']) & {
    color: var(--lighting--white);
  }
}

.tooltip .content {
  min-width: calc(3 * var(--100px));
  padding: var(--20px);
}

.tooltipContentRowStatus {
  max-height: var(--100px);
  overflow-y: auto;
}

.note-icon {
  font-size: 1.2em;
  cursor: pointer;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-600);
  }
}

.selectorMain,
.statementsEditor {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--white);
  }
}

.link {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-400);
  }
}

.disabledRules .areaBody .cell {
  &:not(.state, .buttons) {
    opacity: 0.5;
  }
}

.added,
.modified,
.removed {
  .svg {
    transform: scale(0.8);
  }
}

.menuItemsList {
  max-width: calc(4.8 * var(--100px));
}

.menuItem-disabled {
  cursor: initial;
  pointer-events: auto;
}

.menuItem-text {
  composes: noIconMenuItem-text;
}

.actions {
  display: flex;
}

.status-badge {
  padding: var(--4px) var(--8px);
  text-transform: none;
}

.status-created {
  :where(html[data-theme='lightning']) & {
    background: var(--lightning--green-50);
  }
}

.status-updated {
  :where(html[data-theme='lightning']) & {
    background: var(--lightning--blue-50);
  }
}

.status-deleted {
  :where(html[data-theme='lightning']) & {
    background: var(--lightning--red-50);
  }
}
