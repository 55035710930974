/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.actionsWrapper {
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--4px);
}

.numOfMatchExceedsConfMax {
  padding: var(--5px);
  font-size: var(--12px);
  font-weight: var(--fontWeightLight);
  line-height: var(--16px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-400);
  }
}

.editBtn {
  padding-left: var(--7px);
  cursor: pointer;

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-900);
  }
}

.fieldRange {
  font-size: var(--12px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-500);
  }
}

.footerActions {
  justify-content: space-between;
}
