/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.tallyAlignment {
  display: flex;
}

.left {
  composes: tallyAlignment;
  justify-content: flex-start;
}

.right {
  composes: tallyAlignment;
  justify-content: flex-end;
}

.center {
  composes: tallyAlignment;
  justify-content: center;
}

.tallies {
  --gap-row: 0;
  --gap-column: 0;
  --tallies-border-radius: var(--6px);
  --tally-vertical-padding: var(--offset-xsmall);
  --tally-horizontal-padding: var(--offset-small);
  --tally-underline-size: var(--1px);

  composes: gapLarge gapHorizontalWrap centerFlexAlign from 'utils.css';
  max-width: 100%;
  font-size: var(--15px);
  color: var(--tally-text-color);
  overflow: hidden;
  border-radius: var(--tallies-border-radius);
  background-color: var(--tally-background-color);

  :where(html[data-theme='lightning']) & {
    border: 1px solid var(--tally-border-color);
  }
}

.info {
  composes: tallies;

  :where(html[data-theme='lightning']) & {
    --tally-text-color: var(--lightning--blue-700);
    --tally-border-color: var(--lightning--blue-200);
    --tally-background-color: var(--lightning--blue-50);
    --tally-background-color-hover: var(--lightning--blue-75);
    --tally-background-color-active: var(--lightning--blue-100);
    --tally-background-color-focus: var(--lightning--blue-75);
    --tally-outline-color-focus: var(--lightning--blue-500);

    &:hover {
      --tally-background-color: var(--lightning--blue-200);
    }
  }
}

.warning {
  composes: tallies;

  :where(html[data-theme='lightning']) & {
    --tally-text-color: var(--lightning--orange-700);
    --tally-border-color: var(--lightning--orange-200);
    --tally-background-color: var(--lightning--orange-50);
    --tally-background-color-hover: var(--lightning--orange-25);
    --tally-background-color-active: var(--lightning--orange-100);
    --tally-background-color-focus: var(--lightning--orange-25);
    --tally-outline-color-focus: var(--lightning--orange-500);

    &:hover {
      --tally-background-color: var(--lightning--yellow-200);
    }
  }
}

.error {
  composes: tallies;

  :where(html[data-theme='lightning']) & {
    --tally-text-color: var(--lightning--red-600);
    --tally-border-color: var(--lightning--red-200);
    --tally-background-color: var(--lightning--red-50);
    --tally-background-color-hover: var(--lightning--red-25);
    --tally-background-color-active: var(--lightning--red-100);
    --tally-background-color-focus: var(--lightning--red-25);
    --tally-outline-color-focus: var(--lightning--red-500);

    &:hover {
      --tally-background-color: var(--lightning--red-200);
    }
  }
}

.tally {
  padding: var(--offset-xsmall) var(--offset-small);
  color: inherit; /* To make sure link will inherit info/warn/error colors */

  &.tallyUnderline {
    padding-bottom: calc(var(--tally-vertical-padding) - var(--tally-underline-size));
  }

  :where(html[data-theme='lightning']) & {
    &:hover:not(:active) {
      background-color: var(--tally-background-color-hover);
    }

    &:active {
      background-color: var(--tally-background-color-active);
    }
  }
}

.underline {
  line-height: var(--16px);
  border-bottom: var(--tally-underline-size) solid transparent;
}

/* A special underline behavior to align underlines of .count and .label even thought the are not aligned */
.tallyUnderline {
  &:hover:not(:active),
  &:focus-visible:not(:active) {
    text-decoration: none; /* Turn off the underline on Tally itself */

    .underline {
      /* Turn on individual underlines on .count and .label */
      border-color: currentcolor;
    }
  }

  &:focus-visible:not(:active) {
    outline-offset: calc(-1 * var(--1px));
    border-bottom: none;
    box-shadow: unset;

    :where(html[data-theme='lightning']) & {
      outline: 1px solid var(--tally-outline-color-focus);
      background-color: var(--tally-background-color-focus);

      .underline {
        border-color: transparent;
      }
    }
  }
}

/* Tally that has 'onClick' prop (but not a link 'prop'), should still look like Link */
.tallyClickable {
  composes: tallyUnderline;
  composes: link from 'components/Link/Link.css';
  cursor: pointer;
}

/* Tally that has a 'link' prop */
.tallyLink-link {
  composes: tally tallyUnderline;
}

.count {
  composes: bold monoSpace from 'utils.css';
  font-size: var(--16px);
  line-height: 1;
}

.label {
  composes: semiBold from 'utils.css';
  padding-left: var(--offset-xsmall);
  word-break: break-word;
  overflow-wrap: break-word;
}
