/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.pillSymbol {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: bottom; /* To align it with inline text */
  font-size: 0.9em;
  line-height: 1;
}

.symbol {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--14px);
  height: var(--14px);
  padding: 0;
  border-radius: 100%;

  :where(html[data-theme='lightning']) & {
    color: var(--pill-symbol-color, var(--lightning--white));
    background-color: var(--pill-symbol-background-color, var(--lightning--blue-gray-400));
  }

  &.small {
    font-size: 0.65em;
  }
}

.svg {
  /* Since icon span has the same size, need to stick svg to its top */
  vertical-align: top;
}

/* Icon sizes by type, use scale to keep it in the center */
.workload .svg {
  transform: scale(0.6);
}

.allowlist,
.allWorkloads,
.arrowLeft,
.arrowRight,
.calendar,
.capslock,
.close,
.collapseRole,
.comment,
.copy,
.critical,
.dashboard,
.decrease,
.delete,
.denylist,
.disabledStatus,
.duplicate,
.edit,
.email,
.enforce,
.enforced,
.expandRole,
.export,
.externalLink,
.filter,
.grid,
.group,
.id,
.illumination,
.import,
.increase,
.key,
.list,
.machineAuth,
.manage,
.menu,
.online,
.overflow,
.pair,
.pause,
.pceHealth,
.pending,
.pin,
.play,
.popIn,
.popOut,
.provision,
.save,
.search,
.secureConnect,
.selectionTool,
.server,
.service,
.sortDown,
.sortUp,
.stop,
.stopped,
.suspended,
.thirdParty,
.unlock,
.unlocked,
.unpair,
.unmanaged,
.up,
.user,
.version,
.ven,
.video,
.warning,
.zoomToFit {
  .svg {
    transform: scale(0.75);
  }
}

.app,
.loc,
.role,
.addUser,
.add,
.back,
.cancel,
.check,
.clear,
.containerWorkload,
.database,
.down,
.download,
.error,
.expand,
.flipHorizontal,
.flipVertical,
.grabHandle,
.helpMenu,
.help,
.illuminate,
.info,
.internet,
.inuse,
.left,
.link,
.local,
.lock,
.map,
.moveTool,
.navigateup,
.next,
.refresh,
.remove,
.revert,
.right,
.settings,
.staged,
.star,
.syncing,
.timeCount,
.time,
.unlink,
.virtualServer,
.org {
  .svg {
    transform: scale(0.8);
  }
}

.env .svg {
  transform: scale(0.85);
}

.acrossEnfBoundaryRtl,
.acrossEnfBoundary,
.enfBoundaryRtl,
.appGroupMap,
.arrowDown,
.arrowLeftLong,
.arrowRightLong,
.arrowUp,
.disabled,
.enabled,
.enfBoundary,
.nonBrn,
.scope,
.virtualService,
.deny,
.service {
  .svg {
    transform: scale(0.9);
  }
}

/* Icon colors by type */
.acrossEnfBoundaryRtl,
.acrossEnfBoundary,
.enfBoundaryRtl,
.addUser,
.add,
.allWorkloads,
.allowlist,
.appGroupMap,
.arrowDown,
.arrowLeftLong,
.arrowLeft,
.arrowRightLong,
.arrowRight,
.arrowUp,
.back,
.calendar,
.cancel,
.capslock,
.check,
.clear,
.close,
.collapseRole,
.comment,
.containerWorkload,
.copy,
.critical,
.dashboard,
.database,
.decrease,
.delete,
.deny,
.denylist,
.disabledStatus,
.disabled,
.down,
.download,
.duplicate,
.edit,
.email,
.enabled,
.enfBoundary,
.enforce,
.enforced,
.error,
.expandRole,
.expand,
.export,
.externalLink,
.filter,
.flipHorizontal,
.flipVertical,
.grabHandle,
.grid,
.group,
.helpMenu,
.help,
.id,
.illuminate,
.illumination,
.import,
.increase,
.info,
.internet,
.inuse,
.key,
.left,
.link,
.list,
.local,
.lock,
.machineAuth,
.manage,
.map,
.menu,
.moveTool,
.navigateup,
.next,
.nonBrn,
.online,
.org,
.overflow,
.pair,
.pause,
.pceHealth,
.pending,
.pin,
.play,
.popIn,
.popOut,
.provision,
.refresh,
.remove,
.revert,
.right,
.save,
.scope,
.search,
.secureConnect,
.selectionTool,
.server,
.service,
.settings,
.sortDown,
.sortUp,
.staged,
.star,
.stop,
.stopped,
.suspended,
.syncing,
.thirdParty,
.timeCount,
.time,
.unlink,
.unlock,
.unlocked,
.unmanaged,
.unpair,
.up,
.user,
.version,
.ven,
.video,
.virtualServer,
.virtualService,
.warning,
.workload,
.zoomToFit {
  :where(html[data-theme='lightning']) & {
    background-color: var(--pill-symbol-background-color, var(--lightning--blue-gray-500));
  }
}

.admin {
  :where(html[data-theme='lightning']) & {
    background-color: var(--pill-symbol-background-color, var(--lightning--purple-600));
  }
}

.isGroup {
  --group-offset: var(--4px);
  --group-box-shadow: 0 0 0 var(--1px) var(--pill-symbol-color);

  z-index: 1;

  /* Icon group adds circle with the same color to the background with offset */
  .symbol {
    position: relative;
    margin-right: calc(2 * var(--group-offset));
    box-shadow: var(--pill-symbol-group-box-shadow, var(--group-box-shadow));

    &::after,
    &::before {
      position: absolute;
      z-index: -1;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: inherit;
    }

    &::after {
      box-shadow: var(--pill-symbol-group-box-shadow, var(--group-box-shadow));
      transform: scale(0.96) translateX(var(--group-offset));
    }

    &::before {
      box-shadow: var(--pill-symbol-group-box-shadow, var(--group-box-shadow));
      transform: scale(0.85) translateX(calc(var(--group-offset) * 2 + var(--1px)));
    }
  }

  .before,
  .inbetween {
    /* Increase right gap by group offset */
    margin-right: calc(var(--6px) + 2 * var(--group-offset));
  }
}
