/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.rolesAndScopes {
  composes: centerFlexAlign from 'utils.css';
}

.rolesAndScopes,
.scope {
  composes: gapXSmall gapHorizontalWrap from 'utils.css';
}

.role {
  line-height: 1.7;
}
