/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
.title {
  composes: gapXSmall gapHorizontal semiBold from 'utils.css';
  display: flex;
  align-items: center;
  font-size: var(--12px);

  :where(html[data-theme='lightning']) & {
    color: var(--lightning--blue-gray-900);
  }
}

.nodeWrapper {
  display: flex;
  justify-content: space-between;
}

.contentWrapper {
  composes: gapSmall from 'utils.css';
  flex-direction: column-reverse;
}

.legendWrapper {
  composes: gapLarge gapHorizontal from 'utils.css';
  display: flex;
  gap: var(--offset-small);
}

.disabled {
  :where(html[data-theme='lightning']) & {
    color: var(--lightning--gray-400);
  }
}

.legend {
  --legend-padding-right: 0;

  padding-right: var(--legend-padding-right, var(--5px));
}

.legendColor {
  --legend-color-height: var(--8px);
  --legend-color-width: var(--8px);

  width: var(--legend-color-height, var(--12px));
  height: var(--legend-color-width, var(--12px));
  border-radius: var(--2px);
}
