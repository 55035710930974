/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_1100 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.rowToEnable .areaBody {
  :where(html[data-theme='lightning']) & {
    background-color: var(--lightning--blue-gray-200);
  }
}
